import { downloadHandleStatement, handleStatement, inforStatement, viewStatement } from "../constants";
import { authAxios } from "./axiosClient";

export const GetInforStatementApi = (params = {}) => {
    const url = inforStatement;
    return authAxios().get(url, { params });
};


export const GetViewStatementApi = (params = {}) => {
    const url = viewStatement;
    return authAxios().get(url, { params });
};


export const HandleStatementApi = (params = {}) => {
    const url = handleStatement;
    return authAxios().post(url, params);
};

export const DownloadHandleStatementApi = (params = {}) => {
    const url = downloadHandleStatement;
    return authAxios().post(url, params, { responseType: "blob" });
};
