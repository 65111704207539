import { changeTaxDeclare, exportListDocument, inforInvoiceDone } from "../constants";
import { authAxios } from "./axiosClient";

export const GetListInvoiceDone = (params = {}) => {
    const url = inforInvoiceDone;
    return authAxios().get(url, { params });
};

export const ExportListDocumentApi = (params = {}) => {
    const url = exportListDocument;

    return authAxios().post(url, params, { responseType: "blob" });
};
export const ChangeTaxDeclareApi = (params = {}) => {
    const url = changeTaxDeclare;

    return authAxios().put(url, params);
};


