import { Carousel } from 'antd'
import React from 'react'
import Logo from "../../../images/LogoLogin.svg"
import LogoRS from "../../../images/LogoRainScaleLogin.svg"

const contentStyle = {
    // height: '160px',
    color: 'black',
    lineHeight: '160px',
    textAlign: 'center',
};

function CarouselAds() {
    return (
        <>
            <div className='custom-center' style={{ width: "50%", height: "100vh", background: "rgba(118, 128, 157, 0.10)", backgroundImage: `url(${Logo})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                <Carousel style={{ display: "grid" }} autoplay={true} autoplaySpeed={2000}>
                    <div>
                        <h3 className='custom-center'>
                            <img src={LogoRS} alt=''></img>
                        </h3>
                        <div className='text-box'>
                            <p className='title-homepage'>Process Automation, commit to excellence and innovation. </p>
                            <p className='sub-text-logo'>Process Automation - AI Powered Automation - Managed Services</p>
                        </div>
                    </div>
                    <div>
                        <h3 style={contentStyle}></h3>
                    </div>
                    <div>
                        <h3 style={contentStyle}></h3>
                    </div>
                </Carousel>
            </div>
        </>
    )
}

export default CarouselAds