import { Button, Checkbox, Col, Form, Input, Row } from 'antd'
import React from 'react'
import Arrow from "../../../images/ArrowIconLogin.svg"
import UserIcon from "../../../images/UserIcon.svg"
import LockIcon from "../../../images/LockIcon.svg"
import CallCenterIcon from "../../../images/CallCenter.svg"

import "./Login.css"

import ModalContact from './ModalContact'
import { authLogin } from '../../../app/Actions/auth'
import { Redirect } from "react-router-dom";
import { connect } from 'react-redux'
import CarouselAds from './CarouselAds'
import Cookies from 'universal-cookie'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

import LogoRSmobile from "../../../images_mobile/LogoRSmobile.svg"
import ContactIconMB from "../../../images_mobile/IconContactMB.svg"
import WarningImage from "../../../images/WarningNotiIcon.svg"
import { screenMobile } from '../../../Function'

const MySwal = withReactContent(Swal);
const cookies = new Cookies();

function setCookieRemember(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();

    // Mã hóa dữ liệu trước khi lưu vào cookie
    const encodedValue = window.btoa(cvalue); // Mã hóa dữ liệu bằng Base64
    document.cookie = cname + "=" + encodedValue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
        let c = cookieArray[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            const encodedValue = c.substring(name.length, c.length);
            // Giải mã dữ liệu khi lấy từ cookie
            return window.atob(encodedValue); // Giải mã dữ liệu từ Base64
        }
    }
    return "";
}

class Login extends React.Component {
    state = {
        username: "",
        password: "",
        loadings: false,
        modalVisibleRestPass: false,
        isFocusedUser: false,
        isFocusedPass: false,
        checked: "",
        openModalContact: false,
        openForgetPassword: false,
        openCreateNewPassword: false,
    };

    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    handleModalForgotPass = () => {
        this.setState({
            modalVisibleRestPass: true,
        });
    };

    handleModalCancel = () => {
        this.setState({ modalVisibleRestPass: false });
    };

    handleFocus = () => {
        this.setState({ isFocusedUser: true });
    };

    handleBlur = () => {
        this.setState({ isFocusedUser: false });
    };

    handleFocus1 = () => {
        this.setState({ isFocusedPass: true });
    };

    handleBlur1 = () => {
        this.setState({ isFocusedPass: false });
    };

    onChangeRemember = (e) => {
        localStorage.setItem("remember_me", e.target.checked);
        this.setState({ checked: e.target.checked });
    };

    handleOpenModalContact = () => {
        this.setState({ openModalContact: true });
    };

    handleCloseModalContact = () => {
        this.setState({ openModalContact: false });
    };

    handleLoading = (value) => {
        this.setState({ loadings: value });
    };

    handleOpenForgerPassword = () => {
        window.location = "/forgot-password"
    }

    constructor(props) {
        super(props);
        this.state = {
            username: getCookie("c_u") || "", // Lấy giá trị từ localStorage
            password: getCookie("c_p") || "",
            // checked: localStorage.getItem("remember_me") || ""
            // remember_me: localStorage.getItem("remember_me") || "",
            // Các trạng thái khác của component
        };
    }

    render() {
        const { loading, token } = this.props;
        const {
            loadings,
            isFocusedUser,
            isFocusedPass,
            checked,
            username,
            password,
            openForgetPassword,
        } = this.state;
        if (token) {
            // localStorage.setItem("currentSelectedKeys", JSON.stringify(['2']))
            return <Redirect to="/invoice" />;
        }

        const onFinish = (values) => {
            this.props.login(values.username, values.password, (e) => this.handleLoading(e));
            const checkRemember = localStorage.getItem('remember_me') !== undefined && localStorage.getItem('remember_me') === "true"
            if (checkRemember === true) {
                setCookieRemember("c_u", values.username, 1)
                setCookieRemember("c_p", values.password, 1)
            } else {
                cookies.remove("c_u")
                cookies.remove("c_p")
            }
        };

        const handleNoti = () => {
            MySwal.fire({
                title: <span style={{ fontSize: 20 }}>Tính năng đang phát triển</span>,
                // text: "You won't be able to revert this!",
                // icon: "warning",
                showCancelButton: false,
                showConfirmButton: false,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                imageUrl: WarningImage,
                confirmButtonText: <span>Chắc chắn!</span>,
                cancelButtonText: <span>Không</span>,
                customClass: {
                    icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
                    popup: "custom-notification",
                    confirmButton: "btn-category-master",
                    cancelButton: "btn-category-master",
                },
                position: "top",
            })
        }


        const defaultCheckValue = () => {
            if (localStorage.getItem('remember_me') !== undefined && localStorage.getItem('remember_me') === "true") {
                return true
            } else {
                return false
            }
        }

        return (

            <div style={{ display: "flex", position: "relative", height: "100vh" }}>
                {screenMobile === false ?
                    <>
                        <CarouselAds />
                        <div className='custom-center' style={{ width: "50%", height: "100vh", flexDirection: "column", background: "#fff" }}>
                            <div style={{ width: "50%" }}>
                                <h5 className='title-box-login'>
                                    Đăng nhập
                                </h5>
                                <Form
                                    initialValues={{
                                        username: username || "",
                                        password: password || "",
                                    }}
                                    name="control-hooks"
                                    layout='vertical'
                                    onFinish={onFinish}

                                // validateMessages={validateMessages}
                                // style={{ maxWidth: 600 }}
                                >
                                    <Form.Item
                                        name="username"
                                        label="Tên đăng nhập"
                                        className='custom-border'
                                        rules={[{ required: true, message: "Vui lòng nhập tên đăng nhập" }]}
                                    >
                                        <Input className='input-sign-up' suffix={<img src={UserIcon} alt=''></img>} placeholder='Tên đăng nhập' />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        label="Mật khẩu"
                                        className='custom-border'
                                        rules={[{ required: true, message: "Vui lòng nhập mật khẩu" }]}
                                    >
                                        <Input.Password className='input-sign-up' suffix={<img src={LockIcon} alt=''></img>} placeholder='Mật khẩu' />
                                    </Form.Item>
                                    <Row>
                                        <Col span={12}>
                                            <Checkbox
                                                className='checkbox-sign-up'
                                                defaultChecked={defaultCheckValue}
                                                style={{ float: "left", fontWeight: "bold", color: "#657fa3" }}
                                                onChange={this.onChangeRemember}>
                                                Ghi nhớ mật khẩu
                                            </Checkbox>
                                        </Col>
                                        <Col span={12}>
                                            <span className='forget-password' style={{ float: "right" }} onClick={() => this.handleOpenForgerPassword()}>Quên mật khẩu</span>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: "5%" }}>
                                        <Button className='btn-login' loading={this.state.loadings} htmlType='submit'>Đăng nhập <img src={Arrow} style={{ marginTop: "0.7%" }} alt=''></img></Button>
                                    </Row>

                                    <Row className='custom-center' style={{ marginTop: "3%" }}>
                                        <span className='text-or'>Hoặc</span>
                                    </Row>

                                    <Row style={{ marginTop: "3%" }}>
                                        <Button onClick={handleNoti} className='btn-for-guest'>Đăng nhập không cần mật khẩu</Button>
                                    </Row>
                                </Form>
                            </div>
                        </div>

                        {/* {this.state.openForgetPassword === true ? <ForgetPassword /> : null} */}

                        <img style={{ position: "absolute", right: 50, bottom: 50, cursor: "pointer" }} onClick={() => this.handleOpenModalContact()} src={CallCenterIcon} alt=''></img>
                    </>
                    :

                    // Mobile
                    <>
                        <div className='custom-center' style={{ width: "100%", height: "auto", flexDirection: "column", background: "#fff" }}>
                            <Row style={{ display: "contents" }}>
                                <img alt='' src={LogoRSmobile}></img>
                                <span className='text-title-mb'>Process Automation - AI Powered Automation - Managed Services</span>
                            </Row>

                            <div style={{ width: "100%" }}>
                                <p className='title-box-login-mb'>
                                    Đăng nhập
                                </p>
                                <Form
                                    // form={form}
                                    initialValues={{
                                        username: username || "",
                                        password: password || "",
                                    }}
                                    name="control-hooks"
                                    layout='vertical'
                                    onFinish={onFinish}

                                    // validateMessages={validateMessages}
                                    style={{ height: "60vh", display: "inline-block", width: "95%" }}
                                >
                                    <Form.Item
                                        name="username"
                                        label=""
                                        className='custom-border'
                                        rules={[{ required: true, message: "Vui lòng nhập tên đăng nhập" }]}
                                    >
                                        <Input className='input-sign-up-mb' prefix={<img src={UserIcon} alt=''></img>} placeholder='Tên đăng nhập' />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        label=""
                                        className='custom-border'
                                        style={{ paddingTop: 12 }}
                                        rules={[{ required: true, message: "Vui lòng nhập mật khẩu" }]}
                                    >
                                        <Input.Password size='large' prefix={<img src={LockIcon} alt=''></img>} className='input-sign-up-mb' placeholder='Mật khẩu' />
                                    </Form.Item>
                                    <Row style={{ paddingTop: 12 }}>
                                        <Col span={12}>
                                            <Checkbox className='checkbox-sign-up' defaultChecked={localStorage.getItem('remember_me') !== undefined && localStorage.getItem('remember_me') === "true" ? true : false} style={{ float: "left" }} onChange={this.onChangeRemember}>Ghi nhớ mật khẩu</Checkbox>
                                        </Col>
                                        <Col span={12}>
                                            <span className='forget-password' style={{ float: "right" }} onClick={() => this.handleOpenForgerPassword()}>Quên mật khẩu</span>
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: "5%" }}>
                                        <Button className='btn-login' loading={this.state.loadings} htmlType='submit'>Đăng nhập <img src={Arrow} style={{ marginTop: "0.7%" }} alt=''></img></Button>
                                    </Row>

                                    <Row className='custom-center' style={{ marginTop: "3%" }}>
                                        <span className='text-or'>Hoặc</span>
                                    </Row>

                                    <Row style={{ marginTop: "3%" }}>
                                        <Button onClick={handleNoti} className='btn-for-guest'>Đăng nhập không cần mật khẩu</Button>
                                    </Row>
                                </Form>
                            </div>
                            <Row className='row-footer-mb'>
                                <img style={{ paddingRight: 6 }} src={ContactIconMB} alt=''></img><span className='text-footer-mobile'>(0961.981.199) (0941.519.328)</span>
                            </Row>
                        </div>

                    </>}
                <ModalContact
                    openModalContact={this.state.openModalContact}
                    handleCloseModal={() => this.handleCloseModalContact()}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        token: state.auth.token,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (username, password, loading) => dispatch(authLogin(username, password, loading)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);

