import { localhost } from "./server";
const apiURL = "/api";

export const endpoint = `${localhost}${apiURL}`;
// export const endpoints = `${localhost}${apiURLS}`;

export const loginURL = `${endpoint}/token/`;
export const authURL = `${endpoint}/verify_token_login/`;
// export const authURL = `${endpoint}/token/verify/`;
export const token_refresh_URL = `${endpoint}/token/refresh/`;
export const logoutURL = `${endpoint}/logout/`;

export const userInfoURL = `${endpoint}/infor-user/`;

export const forgotPassword = `${endpoint}/forgot_password/`;

export const renewPassURL = tok => `${endpoint}/renewpass/${tok}`;

export const cityURL = `${endpoint}/city/`;
export const districtsURL = `${endpoint}/districts/`;

export const inforCustomer = id => `${endpoint}/infor-customer/${id}`;

export const manageCustomer = `${endpoint}/manage-customer/`;

export const templateExportHD = id => `${endpoint}/template-export-hd/${id}`;

export const postTemplateExportHD = `${endpoint}/template-export-hd/`;

export const circularNoURL = `${endpoint}/circular-no/`;

export const inforMaster = id => `${endpoint}/infor-master/${id}`;

export const ChangeinforMaster = `${endpoint}/infor-master/`;

// invoice
export const inforInvoice= `${endpoint}/infor-invoice/`;
export const detailInvoice= `${endpoint}/detail-invoice/`;

export const importFileURL = `${endpoint}/import-file/`;

export const deleteFile = `${endpoint}/delete-data/`;
export const masterFileURL = `${endpoint}/master-file/`;
export const downloadFileURL = `${endpoint}/download-file/`;

export const manageBankURL = `${endpoint}/manage-bank/`;
export const inforStatement = `${endpoint}/infor-statement/`;
export const getHistory = `${endpoint}/infor-upload/`
export const getDetailHistory = `${endpoint}/detail-upload/`

export const uploadFileDirect = `${endpoint}/upload-direct/`
export const downloadDetailHistory = `${endpoint}/download-detail-history/`

export const downloadFileHistory = `${endpoint}/download-file-history/`
export const downloadHandleStatement = `${endpoint}/download-search-statement/`

// Định khoản hóa đơn
export const handleInvoice = `${endpoint}/handle-invoice/`
export const handleStatement = `${endpoint}/handle-statement/`

export const listTaxAgent = `${endpoint}/tax-agent/`

export const listTaxAgentInfor = `${endpoint}/list-tax-agent/`

export const editCategoryAccount = `${endpoint}/edit-category-account/`

export const viewStatement = `${endpoint}/view-statement/`

export const revenueAccount = `${endpoint}/edit-revenue-account/` // Bán ra
export const expenseAccount = `${endpoint}/edit-expense-account/` // Mua vào

export const editCategoryCustomer = `${endpoint}/edit-category-customer/` // Mua vào

export const templateExport = `${endpoint}/template-export-hd/` // Mua vào

export const manageUser = `${endpoint}/manage-user/` // Mua vào

export const lockUser = `${endpoint}/lock-user/` // Mua vào
export const messageContact = `${endpoint}/message/` // Mua vào

export const downloadInputInvoice = `${endpoint}/download-input-invoice/` // Tải chi tiết HD | Page Tra cứu HD
export const downloadInputInvoiceAll = `${endpoint}/download-input-invoice-all/` // Tải tất cả chi tiết HD | Page Tra cứu HD

export const inforInvoiceDone = `${endpoint}/infor-invoice-done/` // Mua vào
export const inforSheetDeclareVat = `${endpoint}/sheet-declare-vat/`
export const exportSheetDeclareVat = `${endpoint}/export-sheet-declare/` // export kê khai
export const changeTaxDeclare = `${endpoint}/change-tax-declare/` // bảng kê
export const exportListDocument = `${endpoint}/export-table-declare/` // export bảng kê














