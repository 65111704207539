import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ErrorImage from "../../../../../images/ErrorNotifiIcon.svg"

const Excel006 = ({
  isOpenModalExcel006,
  setIsOpenModalExcel006,
  inforError,
}) => {
  const handleCancel = () => {
    setIsOpenModalExcel006(false);
  };

  const MySwal = withReactContent(Swal);

  if (isOpenModalExcel006 === true) {
    handleCancel();
    MySwal.fire({
      imageUrl: ErrorImage,
      html: <i style={{ color: "red" }}>{inforError}</i>,
      customClass: {
        confirmButton: "btn-category-master",
        popup: "custom-notification",
        icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
      },
      position: "top",
      confirmButtonColor: "#3ab7b0",
      confirmButtonText: "Xác nhận",
      // width: screenMobile === true ? "80%" : "20%",
    });
  }
  return <></>;
};

export default Excel006;
