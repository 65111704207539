import { downloadDetailHistory, downloadFileHistory, getDetailHistory, getHistory } from "../constants";
import { authAxios } from "./axiosClient";

export const GetListHistoryApi = (params = {}) => {
    const url = getHistory;
    return authAxios().get(url, { params });
};

export const GetDetailHistoryApi = (params = {}) => {
    const url = getDetailHistory;
    return authAxios().get(url, { params });
};

export const DownloadDetailHistoryApi = (params = {}) => {
    const url = downloadDetailHistory;
    return authAxios().post(url, params , { responseType: "blob" });
};

export const DownloadFileHistoryApi = (params = {}) => {
    const url = downloadFileHistory;
    return authAxios().post(url, params , { responseType: "blob" });
};

