import { Button, Form, Input, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'

import ArrowWhite from "../../../images/ArrowWhiteIcon.svg"
import LockIcon from "../../../images/LockIcon.svg"
import LockIconMB from "../../../images_mobile/LockIconMB.svg"
import "./Login.css"
import CallCenterIcon from "../../../images/CallCenter.svg"
import ModalContact from './ModalContact'
import CarouselAds from './CarouselAds'
import { PostRenewPasswordApi, RenewPasswordApi } from '../../../api/usersApi'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import ErrorImage from "../../../images/ErrorNotifiIcon.svg"
import SuccessImage from "../../../images/SuccessNotiIcon.svg"
import LogoRSmobile from "../../../images_mobile/LogoRSmobile.svg"
import ContactIconMB from "../../../images_mobile/IconContactMB.svg"
import { screenMobile } from '../../../Function'

const MySwal = withReactContent(Swal);


function CreateNewPassword() {
    const [form] = Form.useForm();
    const [openModalContact, setOpenModalContact] = useState(false)
    const [openModalErr, setOpenModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState("")
    const [checkErr, setCheckErr] = useState(false)
    const [valueToken, setValueToken] = useState()

    const handleOpenModalContact = () => {
        setOpenModalContact(true)
    }

    const handleCloseModalContact = () => {
        setOpenModalContact(false)
    }

    const onNotification = (icon, message, status, className, button, color) => {
        MySwal.fire({
            timer: 1000000000,
            title: <span className={className} style={{ color: color }}>{status}</span>,
            html: <i style={{ fontSize: 16 }}>{message}</i>,
            // icon: "success",
            imageUrl: icon,
            showConfirmButton: button !== undefined ? true : false,
            confirmButtonText: button,
            // confirmButtonColor: "#fff",
            focusConfirm: true,
            allowOutsideClick: false,
            customClass: {
                icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
                popup: "custom-notification",
                // confirmButton: "custom-confirm-btn"
                // image: "custom-image"
            },
            position: "top",
            // width: screenMobile === true ? "80%" : "20%",
        });
    }
    const onNotificationErr = (icon, message, status, className, button, color) => {
        MySwal.fire({
            timer: 1000000000,
            title: <span className={className} style={{ color: color }}>{status}</span>,
            html: <i style={{ fontSize: 16 }}>{message}</i>,
            // icon: "success",
            imageUrl: icon,
            showConfirmButton: button !== undefined ? true : false,
            confirmButtonText: button,
            // confirmButtonColor: "#fff",
            focusConfirm: true,
            // allowOutsideClick: false,
            customClass: {
                icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
                popup: "custom-notification",
                // confirmButton: "custom-confirm-btn"
                // image: "custom-image"
            },
            position: "top",
            // width: screenMobile === true ? "80%" : "20%",
        });
    }

    const returnPageMail = () => {
        window.location = "/forgot-password"
    }

    const returnPageLogin = () => {
        window.location = "/login"
    }
    const GetRenewPassword = (value) => {
        RenewPasswordApi(value).then(res => {
            setOpenModalErr(false)
            setCheckErr(false)
            setValueToken(res.data.token)
        }).catch(err => {
            setCheckErr(true)
            onNotification(ErrorImage, err.data.message, "Yêu cầu thất bại !!!", 'text-error', <Button className="title-btn" style={{ border: "unset", boxShadow: "unset" }} onClick={returnPageMail}>Quay lại nhập email &nbsp;> </Button>)
        })
    }

    const onFinish = (values) => {
        var FormData = require("form-data");
        var data = new FormData();
        data.append("pass_new", values.pass_new);
        data.append("pass_confirm", values.pass_confirm);
        PostRenewPasswordApi(valueToken, data).then(res => {
            onNotification(SuccessImage, res.data.message, "Yêu cầu thành công !!!", 'text-success-login', <Button className="title-btn" style={{ border: "unset", boxShadow: "unset" }} onClick={returnPageLogin}>Quay lại trang login &nbsp;> </Button>, "#2EB85C")
            form.resetFields()
        }).catch(err => {
            onNotificationErr(ErrorImage, err.data.message, "Yêu cầu thất bại !!!", 'text-error-login', undefined, "#e55353")
        })
    }

    useEffect(() => {
        GetRenewPassword(window.location.pathname.split("/")[2])
    }, []);

    return (
        <>
            {checkErr === false ?

                <div style={{ display: "flex", position: "relative", height: "100vh" }}>
                    {screenMobile === false ?
                        <>
                            <CarouselAds />
                            <div className='custom-center' style={{ width: "50%", height: "100vh", flexDirection: "column", background: "#fff" }}>
                                <div style={{ width: "50%" }}>
                                    <h5 className='title-box-login' style={{ fontSize: 26 }}>
                                        Tạo mật khẩu mới
                                    </h5>
                                    <div >
                                        <p className='text-forgetPW'>
                                            Để đảm bảo tính an toàn và bảo mật. Hãy sử dụng mật khẩu chứa ít nhất 10 kí tự, bao gồm chữ in hoa, chữ thường, số và ký tự đặc biệt
                                        </p>
                                    </div>
                                    <Form
                                        form={form}
                                        name="control-hooks"
                                        layout='vertical'
                                        onFinish={onFinish}
                                    >

                                        <Form.Item
                                            name="pass_new"
                                            label="Mật khẩu mới"
                                            className='custom-border'
                                            rules={[{ required: true, message: "Vui lòng nhập mật khẩu mới" }]}
                                        >
                                            <Input.Password className='input-sign-up' suffix={<img src={LockIcon} alt=''></img>} placeholder='Nhập mật khẩu mới' />
                                        </Form.Item>
                                        <Form.Item
                                            name="pass_confirm"
                                            label="Xác nhận mật khẩu"
                                            className='custom-border'
                                            rules={[
                                                { required: true, message: "Vui lòng nhập mật khẩu xác nhận" },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('pass_new') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Mật khẩu xác nhận chưa đúng'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password className='input-sign-up' suffix={<img src={LockIcon} alt=''></img>} placeholder='Nhập mật khẩu xác nhận' />
                                        </Form.Item>

                                        <Row style={{ marginTop: "5%" }}>
                                            <Button className='btn-login' style={{ backgroundColor: "#058DF4", color: "#fff" }} htmlType='submit'>Xác nhận <img src={ArrowWhite} style={{ marginTop: "0.7%" }}></img></Button>
                                        </Row>
                                    </Form>
                                </div>
                            </div>
                            <img style={{ position: "absolute", right: 50, bottom: 50, cursor: "pointer" }} onClick={handleOpenModalContact} src={CallCenterIcon} alt=''></img>

                        </>
                        :
                        <>
                            <div className='custom-center' style={{ width: "100%", height: "100vh", flexDirection: "column", background: "#fff" }}>
                                <Row style={{ display: "contents" }}>
                                    <img alt='' src={LogoRSmobile}></img>
                                    <span className='text-title-mb'>Process Automation - AI Powered Automation - Managed Services</span>
                                </Row>
                                <Row style={{ display: "contents" }}>
                                    <h5 className='title-box-login-mb'>
                                        Tạo mật khẩu mới
                                    </h5>
                                    <span className='text-forgetPW-mb'>
                                        Để đảm bảo tính an toàn và bảo mật. Hãy sử dụng mật khẩu chứa ít nhất 10 kí tự, bao gồm chữ in hoa, chữ thường, số và ký tự đặc biệt
                                    </span>
                                </Row>

                                <div style={{ width: "100%", marginTop: "6%", rowGap: "1ch" }}>

                                    <Form
                                        form={form}
                                        name="control-hooks"
                                        layout='vertical'
                                        onFinish={onFinish}
                                        // validateMessages={validateMessages}
                                        // style={{ maxWidth: 600 }}
                                        style={{ height: "60vh", display: "inline-block", width: "95%" }}

                                    >
                                        <Form.Item
                                            name="pass_new"
                                            label=""
                                            className='custom-border'
                                            rules={[{ required: true, message: "Vui lòng nhập mật khẩu mới" }]}
                                        >
                                            <Input.Password className='input-sign-up-mb' prefix={<img src={LockIconMB} alt=''></img>} placeholder='Nhập mật khẩu mới' />
                                        </Form.Item>
                                        <Form.Item
                                            name="pass_confirm"
                                            label=""
                                            className='custom-border'
                                            rules={[
                                                { required: true, message: "Vui lòng nhập mật khẩu xác nhận" },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('pass_new') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Mật khẩu xác nhận chưa đúng'));
                                                    },
                                                }),
                                            ]}
                                            style={{ paddingTop: 12 }}
                                        >
                                            <Input.Password className='input-sign-up-mb' prefix={<img src={LockIcon} alt=''></img>} placeholder='Nhập mật khẩu xác nhận' />
                                        </Form.Item>

                                        <Row style={{ paddingTop: 12 }}>
                                            <Button className='btn-login' style={{ backgroundColor: "#058DF4", color: "#fff" }} htmlType='submit'>Xác nhận <img src={ArrowWhite} style={{ marginTop: "0.7%" }}></img></Button>
                                        </Row>
                                    </Form>
                                </div>
                                <Row className='row-footer-mb'>
                                    <img style={{ paddingRight: 6 }} src={ContactIconMB} alt=''></img><span className='text-footer-mobile'>(0961.981.199) (0941.519.328)</span>
                                </Row>
                            </div>
                        </>}


                </div>

                : null}
            <ModalError openModalErr={openModalErr} messageErr={messageErr} />
            <ModalContact
                openModalContact={openModalContact}
                handleCloseModal={handleCloseModalContact}
            />
        </>
    )
}

const ModalError = ({ openModalErr, messageErr }) => {
    return (
        <>
            <Modal title="Basic Modal" open={openModalErr} footer={false} closeIcon={false}>
                {messageErr}
            </Modal>
        </>
    )
}

export default CreateNewPassword