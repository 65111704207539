import { Button, Col, Form, Input, Row, Select, Divider, Space, Tag, Modal, Tooltip, AutoComplete } from 'antd';
import { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Container } from '@mui/material';
import IconEditCompany from "../../../../../images/IconEditCompany.svg"
import { DeleteInForCompanyApi, GetInForCompanyApi, GetListCityApi, GetListDistrictsApi, PostInForCompanyApi, PutInForCompanyApi } from '../../../../../api/inforCompanyApi';
import { PlusOutlined } from '@ant-design/icons';
import ErrorImage from "../../../../../images/ErrorNotifiIcon.svg"
import SuccessImage from "../../../../../images/SuccessNotiIcon.svg"
import { GetListTaxAgent, GetListTaxAgentInfor } from '../../../../../api/manageInforUser';
import WarningImage from "../../../../../images/WarningNotiIcon.svg"
import AddIcon from "./../../../../../images/AddIcon.svg"
import DeleteIcon from "./../../../../../images/DeleteIcon.svg"
import EditIcon from "./../../../../../images/EditIcon.svg"
import "./InforWithRoleAdmin.css"
import { useSelector } from 'react-redux';

const { Option } = Select;
const MySwal = withReactContent(Swal);
const onNotification = (icon, status, message) => {
    MySwal.fire({
        timer: 3000,
        html: <i>{message}</i>,
        imageUrl: icon,
        showConfirmButton: false,
        customClass: {
            icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
            popup: "custom-notification"
        },
        position: "top",
    });
}

function InforWithRoleAdmin() {
    const [form] = Form.useForm();
    const [selectedCity, setSelectedCity] = useState(null);
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [loadingButtonSave, setLoadingButtonSave] = useState(false);
    const [checkEditInfor, setCheckEditInfor] = useState(false)
    const [listEmailSendDoc, setListEmailSendDoc] = useState([])
    const [checkFormatEmail, setCheckFormatEmail] = useState()
    const [dataEmailSendDoc, setDataEmailSendDoc] = useState([])
    const [chooseId, setChooseId] = useState()
    const [name, setName] = useState('');
    const inputRef = useRef(null);
    const userInfo = useSelector((state) => state.getUserInfo.userInfo);

    const [dataTaxAgent, setDataTaxAgent] = useState([])
    const [dataFilter, setDataFilter] = useState([])
    const [isOpenModalAdd, setIsOpenModalAdd] = useState(false)

    const fetchListDataCity = () => {
        GetListCityApi().then((res) => {
            setCities(res.data);
        });
    };

    const fetchListDataDistricts = (selectedCity) => {
        GetListDistrictsApi({ code_city: selectedCity }).then((res) => {
            setDistricts(res.data);
        });
    };

    const handleCityChange = (e) => {
        setSelectedCity(e);
        form.setFieldsValue({ code_district: null });
    };

    const fetchListTaxAgent = () => {
        GetListTaxAgent().then(res => {
            setChooseId(res.data[0].id)
            setDataTaxAgent(res.data)
            setDataFilter(res.data)

            detailInforUser(res.data[0].id)

        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        fetchListTaxAgent();
        fetchListDataCity();
    }, []);

    useEffect(() => {
        fetchListDataDistricts(selectedCity);
    }, [selectedCity]);

    const onFinish = (values) => {
        let FormData = require("form-data");
        let data = new FormData();
        setLoadingButtonSave(true);

        data.append("address", values.address);
        data.append("code_city", values.code_city);
        data.append("code_district", values.code_district);
        data.append("email_send_doc", values.email_send_doc.toString().replaceAll(",", ";"));
        data.append("name_abbreviation", values.name_abbreviation);
        data.append("name_organization", values.name_organization);
        data.append("numberphone", values.numberphone);
        data.append("id_customer", chooseId)
        PutInForCompanyApi(data)
            .then((res) => {
                setCheckEditInfor(false)
                setLoadingButtonSave(false)
                setCheckFormatEmail("")
                fetchListTaxAgent()
                ClickCancel()
                onNotification(SuccessImage, "success", res.data.message)
            }).catch(err => {
                setLoadingButtonSave(false)
                onNotification(ErrorImage, "error", err.data.message)
            });
        // }
    };

    const ClickCancel = () => {
        setCheckEditInfor(false)
    }

    const onNameChange = (event) => {
        if (event.target.value === "") {
            setCheckFormatEmail("")
        }
        setName(event.target.value);
    };



    const addItem = (e) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        let flag = false;

        if (name !== undefined && name !== "") {
            if (dataEmailSendDoc.length > 0) {
                for (const element of dataEmailSendDoc) {
                    if (name.toLowerCase() === element) {
                        flag = true;
                        break;
                    }
                }
                for (const element of listEmailSendDoc) {
                    if (name.toLowerCase() === element) {
                        flag = true;
                        break;
                    }
                }
            }



            if (emailRegex.test(name) === true && flag === false) {
                e.preventDefault();
                setCheckFormatEmail("")
                setListEmailSendDoc([...listEmailSendDoc, name])
                form.setFieldValue('email_send_doc', [...listEmailSendDoc, name])
                setName('');
                setTimeout(() => {
                    inputRef.current?.focus();
                }, 0);
            } else if (emailRegex.test(name) === false) {
                setCheckFormatEmail("Email chưa đúng định dạng !!!")
            } else if (flag === true) {
                setCheckFormatEmail("Email này đã tồn tại !!!")
            }
        } else {
            setCheckFormatEmail("Vui lòng nhập Email cần thêm !!!")
        }
    };

    const detailInforUser = (id) => {
        GetInForCompanyApi(id).then(res => {
            setTimeout(() => {
                form.setFieldsValue({
                    tax_code: res.data.tax_code,
                    name_abbreviation: res.data.name_abbreviation,
                    name_organization: res.data.name_organization,
                    address: res.data.address,
                    code_city: res.data.code_city,
                    code_district: res.data.code_district,
                    numberphone: res.data.numberphone,
                    email_send_doc: res.data.email_send_doc,
                    amount_deviation: res.data.amount_deviation,
                });
            }, 100);
            setDataEmailSendDoc(res.data.email_send_doc)
            fetchListDataDistricts(res.data.code_city)
            setListEmailSendDoc(res.data.email_send_doc)
            setCheckEditInfor(false)
            setChooseId(id)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (checkEditInfor === true) {
            setName()
            setCheckFormatEmail()
        } else if (chooseId !== undefined) {
            detailInforUser(chooseId)
        }
    }, [checkEditInfor]);

    const changeTitle = () => {
        if (checkEditInfor) {
            return "Chỉnh sửa"
        }
        else {
            return "Chi tiết"
        }
    }

    const deleteUser = () => {
        MySwal.fire({
            title: <span style={{ fontSize: 20 }}>Bạn có chắc muốn xóa doanh nghiệp này ?</span>,
            // text: "You won't be able to revert this!",
            // icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            imageUrl: WarningImage,
            confirmButtonText: <span>Chắc chắn!</span>,
            cancelButtonText: <span>Không</span>,
            customClass: {
                icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
                popup: "custom-notification",
                confirmButton: "btn-category-master",
                cancelButton: "btn-category-master",
            },
            position: "top",
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteInForCompanyApi({
                    id_customer: chooseId
                }).then((res) => {
                    MySwal.fire({
                        title: "Thành công!",
                        text: res.data.message,
                        // icon: "success",
                        imageUrl: SuccessImage,
                        customClass: {
                            // confirmButton: "btn-confirm-delete",
                            icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
                            popup: "custom-notification",
                            confirmButton: "btn-category-master",
                            cancelButton: "btn-category-master",
                        },
                        position: "top",
                    });
                    form.resetFields()
                    fetchListTaxAgent();
                })
                    .catch((err) => {
                        MySwal.fire({
                            title: "Thất bại!",
                            text: err.data.message,
                            imageUrl: ErrorImage,
                            customClass: {
                                icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
                                popup: "custom-notification",
                                confirmButton: "btn-category-master",
                                cancelButton: "btn-category-master",
                            },
                            position: "top",
                        });
                        console.log(err)

                    });
            }
        });
    }

    const functionButton = () => {
        if (checkEditInfor === true) {
            return (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                        htmlType="button"
                        className='text-btn design-btn'

                        onClick={() => setTimeout(() => {
                            ClickCancel()
                        }, 200)}
                    >
                        Hủy
                    </Button>
                    <Button
                        // variant="contained"
                        // type="success"
                        htmlType="submit"
                        disabled={loadingButtonSave}
                        className='text-btn'
                        style={
                            {
                                border: "2px solid var(--Color-line, #058DF4)",
                                borderRadius: 8,
                                height: 45,
                                width: "100px",
                                color: "#fff",
                                background: "var(--Color-line, linear-gradient(0deg, #058DF4 0%, #6266EF 100%))",
                            }
                        }
                    >
                        Lưu
                    </Button>
                </div>
            )
        }
    }

    const listDataTaxAgent = () => {
        return dataFilter.map(item => (
            <Tag key={item.id} onClick={() => detailInforUser(item.id)} style={{ height: "fit-content", cursor: "pointer", width: "100%", whiteSpace: "wrap", padding: "4px 7px" }} color={chooseId === item.id ? "processing" : "default"}>{item.name_organization}</Tag>
        ))
    }

    const searchCustomer = (event) => {
        const data = dataTaxAgent.filter(item => item.name_organization.match(event.target.value))
        setDataFilter(data)
    }

    const showModalAdd = () => {
        setIsOpenModalAdd(true)
    }

    const canEdit = checkEditInfor !== true;
    return (
        <Container maxWidth="false" className='container-editOrView' style={{ width: "97%" }}>
            <div className='bg-all' style={{ overflowY: "auto" }}>
                <Row style={{ width: "100%", height: "80vh" }}>
                    <Col span={6} offset={1} >
                        <div className='list-user'>
                            <div className='box-search-customer'>
                                <Input style={{ width: "90%" }} placeholder='Tìm tên doanh nghiệp' onChange={searchCustomer}></Input>
                            </div>
                            <div style={{ rowGap: "1ch", marginTop: "3%", display: "grid" }}>
                                {listDataTaxAgent()}
                            </div>
                        </div>
                    </Col>
                    <Col span={16} offset={1}>
                        <Form
                            form={form}
                            layout="vertical"
                            autoComplete="off"
                            onFinish={onFinish}
                            className='text-label-manage-company'
                            initialValues={{
                                remember: true,
                            }}
                            style={{ width: "94%", height: "75vh" }}
                        >
                            <Col
                                span={24}
                                style={{ display: "flex" }}
                            >
                                <Col span={14}>
                                    <div style={{ display: "flex" }}>
                                        <img src={IconEditCompany} alt=''></img>
                                        <span className="title-infor-company" style={{ margin: "0% 0% 0% 0.5%" }}>
                                            {changeTitle()} thông tin doanh nghiệp
                                        </span>
                                    </div>
                                </Col>
                                <Col span={10} style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button className='btn-invoice-all' onClick={showModalAdd} style={{ marginRight: "2%", background: "rgba(58, 183, 176, 0.15)" }}><img alt='' src={AddIcon}></img></Button>
                                    {chooseId !== userInfo.customer_id ? <Button className='btn-invoice-all' onClick={() => deleteUser()} style={{ marginRight: "2%", background: "#E5535329" }}><img alt='' src={DeleteIcon}></img></Button> : null}
                                    <Button className='btn-invoice-all' onClick={() => setCheckEditInfor(true)} style={{ background: "#058DF429" }}><img alt='' src={EditIcon}></img></Button>
                                </Col>
                            </Col>

                            <div style={{ paddingTop: "3%", display: "grid", gridRowGap: "1.5%" }}>
                                <Row>
                                    <Col className="colTTDN" span={12}>
                                        <Form.Item
                                            label="Tên doanh nghiệp:"
                                            name="name_organization"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Vui lòng nhập tên doanh nghiệp!",
                                                },
                                            ]}
                                        >
                                            <Input
                                                size={
                                                    "large"
                                                }
                                                className="inputTTDN"
                                                placeholder="Tên doanh nghiệp"
                                                readOnly={canEdit}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col className="colTTDN" span={11} offset={1}>
                                        <Form.Item
                                            label="Tỉnh/thành phố:"
                                            name="code_city"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn tỉnh/thành phố!",
                                                },
                                            ]}
                                        >
                                            <Select
                                                size={"large"}
                                                id="code_city"
                                                onChange={handleCityChange}
                                                optionFilterProp="children"
                                                allowClear
                                                disabled={canEdit}
                                                placeholder="Chọn Tỉnh/thành phố"
                                            >
                                                {cities.map((city, index) => (
                                                    <Option key={city.code} value={city.code}>
                                                        {city.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>


                                </Row>

                                <Row>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Tên viết tắt:"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập tên viết tắt!",
                                                },
                                            ]}
                                            name="name_abbreviation"
                                        >
                                            <Input
                                                size={
                                                    "large"
                                                }
                                                className="inputTTDN"
                                                placeholder={"Nhập tên viết tắt"}
                                                readOnly={canEdit}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={11} offset={1}>
                                        <Form.Item
                                            label="Quận/huyện:"
                                            name="code_district"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vui lòng chọn quận/huyện!",
                                                },
                                            ]}
                                        >
                                            <Select
                                                id="code_district"
                                                size={"large"}
                                                allowClear
                                                disabled={canEdit}
                                                optionFilterProp="children"
                                                placeholder="Chọn Quận/huyện"
                                            >
                                                {districts.length > 0
                                                    ? districts.map((district, index) => (
                                                        <Option key={district.code} value={district.code}>
                                                            {district.name}
                                                        </Option>
                                                    ))
                                                    : null}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col
                                        className="colTTDN"
                                        span={15}
                                    >
                                        <Form.Item label="Địa chỉ trụ sở:" name="address">
                                            <Input
                                                size={"large"}
                                                placeholder="Địa chỉ trụ sở"
                                                readOnly={canEdit}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="colTTDN" span={8} offset={1}>
                                        <Form.Item
                                            label="Mã số thuế:"
                                            name="tax_code"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        "Vui lòng nhập mã số thuế!",
                                                },
                                            ]}
                                        >
                                            <Input
                                                size={
                                                    "large"
                                                }
                                                className="inputTTDN"
                                                onBlur={(e) => {
                                                    form.setFieldsValue({ tax_code: e.target.value.trim() });
                                                }}
                                                onKeyPress={(e) => {
                                                    const charCode = e.which
                                                        ? e.which
                                                        : e.keyCode;
                                                    if ((charCode < 48 || charCode > 57) && charCode !== 45) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                disabled={canEdit === false}
                                                readOnly={true}
                                                style={{ color: "black" }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col
                                        className="colTTDN"
                                        span={15}
                                    >
                                        <Form.Item
                                            label="Email nhận hóa đơn, chứng từ:"
                                            name="email_send_doc"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập email gửi từ chứng từ !",
                                                },
                                            ]}
                                            className='form-email'
                                        >
                                            <Select
                                                style={{
                                                    width: "100%",
                                                }}
                                                maxTagCount={"responsive"}
                                                mode='multiple'
                                                placeholder="Chọn email gửi từ chứng từ"
                                                disabled={canEdit}
                                                allowClear={false}
                                                optionFilterProp="children"
                                                size='large'
                                                value={listEmailSendDoc}
                                                maxTagPlaceholder={(omittedValues) => (
                                                    <Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
                                                        <span>+ {omittedValues.length} ...</span>
                                                    </Tooltip>
                                                )}
                                                dropdownRender={(menu) => (
                                                    <>
                                                        {menu}
                                                        <Divider
                                                            style={{
                                                                margin: '8px 0',
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                padding: '0 8px 4px',
                                                                display: "flex",
                                                                justifyContent: "flex-start",
                                                                alignItems: "start"
                                                            }}
                                                        >
                                                            <div style={{ display: "grid" }}>
                                                                <Input
                                                                    placeholder="Nhập email"
                                                                    ref={inputRef}
                                                                    value={name}
                                                                    onChange={onNameChange}
                                                                    onKeyDown={(e) => e.stopPropagation()}
                                                                    style={checkFormatEmail !== undefined && checkFormatEmail !== "" ? { border: "1px solid red" } : null}
                                                                />
                                                                <span style={{ color: "#ff4d4f" }}>{checkFormatEmail}</span>
                                                            </div>
                                                            <Button type="text" onClick={addItem}>
                                                                <PlusOutlined />Thêm email
                                                            </Button>
                                                        </Space>
                                                    </>
                                                )}

                                            >
                                                {listEmailSendDoc.length > 0
                                                    ? listEmailSendDoc.map((district, index) => (
                                                        <Option key={district} value={district}>
                                                            {district}
                                                        </Option>
                                                    ))
                                                    : null}
                                            </Select>
                                        </Form.Item>
                                        <span style={{ float: "left", fontSize: 12, fontStyle: "italic" }}>( Danh sách mail dùng để gửi chứng từ đến VAC )</span>
                                    </Col>
                                    <Col span={8} offset={1}>
                                        <Form.Item
                                            label="Điện thoại:"
                                            name="numberphone"
                                            rules={[
                                                {
                                                    pattern: /^\d+$/,
                                                    message: "Số điện thoại chỉ được nhập bằng chữ số!",
                                                },
                                                {
                                                    max: 12,
                                                    message: "Chỉ được nhập tối đa 12 số",
                                                },
                                            ]}
                                        >
                                            <Input
                                                size={
                                                    "large"
                                                }
                                                className="inputTTDN"
                                                placeholder="Điện thoại"
                                                readOnly={canEdit}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col
                                        span={10}
                                    ></Col>
                                    <Col span={13} offset={1} className="colTTDN" style={{ display: "flex", justifyContent: "flex-end", columnGap: "2ch" }}>
                                        {functionButton()}
                                    </Col>
                                </Row>

                                <Row>
                                    &nbsp;
                                </Row>
                            </div>
                        </Form>
                    </Col>
                </Row>
                {isOpenModalAdd ?
                    <ModalAddCustomer
                        open={isOpenModalAdd}
                        setIsOpenModalAdd={setIsOpenModalAdd}
                        fetchListTaxAgent={fetchListTaxAgent}
                    />
                    : null}
            </div>
        </Container>
    );
}

const ModalAddCustomer = ({ open, setIsOpenModalAdd, fetchListTaxAgent }) => {
    const [form] = Form.useForm()
    const [listTaxAgent, setListTaxAgent] = useState([])
    const userInfo = useSelector((state) => state.getUserInfo.userInfo);
    const [valueCompany, setValueCompany] = useState({
        name: '',
        id: null
    })

    const periodDeclare = [
        {
            id: 0,
            name: "Tháng"
        },
        {
            id: 1,
            name: "Quý"
        },
    ]
    const checkAdminTax = userInfo.role_id === 4
    const handleCancel = () => {
        form.resetFields()
        setIsOpenModalAdd(false)
    }
    const onFinish = (values) => {
        let FormData = require("form-data");
        let data = new FormData();

        if (checkAdminTax) {
            data.append("id_taxagent", userInfo.taxagent_user);
            data.append("name_abbreviation", values.name_abbreviation);
            data.append("name_organization", values.name_organization);
            data.append("tax_code", values.tax_code);
            data.append("email_send_doc", values.email_send_doc);
            data.append("tax_declare_period_type", values.tax_declare_period_type);
        } else {
            data.append("id_taxagent", valueCompany.id);
            data.append("name_abbreviation", values.name_abbreviation);
            data.append("name_organization", values.name_organization);
            data.append("tax_code", values.tax_code);
            data.append("email_send_doc", values.email_send_doc);
            data.append("name_taxagent", valueCompany.name);
            data.append("tax_declare_period_type", values.tax_declare_period_type);
        }

        PostInForCompanyApi(data)
            .then((res) => {
                onNotification(SuccessImage, "success", res.data.message)
                handleCancel()
                fetchListTaxAgent()
            }).catch(err => {
                onNotification(ErrorImage, "error", err.data.message)
            });
    }

    const fetchListTaxAgentInfor = () => {
        GetListTaxAgentInfor().then(res => {
            setListTaxAgent(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    useEffect(() => {
        fetchListTaxAgentInfor()
    }, []);

    const handleTaxAgent = (value, data) => {
        if (data.idTaxAgent === undefined) {
            setValueCompany({
                name: value,
                id: null
            })
        } else {
            setValueCompany({
                name: '',
                id: data.idTaxAgent
            })
        }
    }

    return (
        <Modal
            className='modal-add-customer'
            title={
                <div className='bg-title'>
                    <span className='text-title'>Tạo mới doanh nghiệp</span>
                </div>}
            open={open}
            footer={false}
            width={800}
            onCancel={handleCancel}
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}

                initialValues={{
                    remember: true,
                }}
                className='text-label-add-company'
            >
                <Row>
                    <Col span={11}>
                        <Form.Item label="Thuộc đại lý/ Công ty mẹ" name='id_taxagent'>
                            {!checkAdminTax ?
                                <Input.Group compact key="add_team">
                                    <AutoComplete
                                        style={{
                                            width: "100%",
                                        }}
                                        placeholder="Nhập hoặc chọn loại tài liệu"
                                        onChange={handleTaxAgent}
                                        key="auto"
                                        className='choose-company'
                                    >
                                        {listTaxAgent.map((item, index) => (
                                            <AutoComplete.Option key={item.id} value={item.name_organization} idTaxAgent={item.id}>
                                                {item.name_organization}
                                            </AutoComplete.Option>
                                        ))}
                                    </AutoComplete>
                                </Input.Group>
                                :
                                <Input readOnly defaultValue={userInfo.customer_name}></Input>
                            }

                        </Form.Item>
                    </Col>
                    <Col span={11} offset={2}>
                        <Form.Item
                            label="Mã doanh nghiệp"
                            name='name_abbreviation'
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập mã doanh nghiệp!",
                                },
                            ]}
                        >
                            <Input
                                placeholder='Nhập mã doanh nghiệp'
                                onBlur={(e) => {
                                    form.setFieldsValue({ name_abbreviation: e.target.value.trim() });
                                }}
                            >
                            </Input>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Tên doanh nghiệp"
                            name='name_organization'
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập tên doanh nghiệp!",
                                },
                            ]}
                        >
                            <Input
                                placeholder='Nhập tên doanh nghiệp'
                                onBlur={(e) => {
                                    form.setFieldsValue({ name_organization: e.target.value.trim() });
                                }}
                            >
                            </Input>
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={2}>
                        <Form.Item
                            label="Mã số thuế"
                            name='tax_code'
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập mã số thuế!",
                                },
                                {
                                    pattern: /^[0-9-]+$/,
                                    message: "Chỉ được nhập số và kí tự -",
                                },
                            ]}
                        >
                            <Input
                                placeholder='Vui lòng nhập mã số thuế'
                                onBlur={(e) => {
                                    form.setFieldsValue({ tax_code: e.target.value.trim() });
                                }}
                                onKeyPress={(e) => {
                                    const charCode = e.which
                                        ? e.which
                                        : e.keyCode;
                                    if ((charCode < 48 || charCode > 57) && charCode !== 45) {
                                        e.preventDefault();
                                    }
                                }}
                            ></Input>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Email nhận hóa đơn, chứng từ"
                            name='email_send_doc'
                            rules={[
                                {
                                    message: "Vui lòng nhập đúng định dạng!",
                                    type: "email",
                                },

                                {
                                    required: true,
                                    message: "Vui lòng nhập email gửi chừng từ !",
                                },
                            ]}
                            onBlur={(e) => {
                                form.setFieldsValue({ email_send_doc: e.target.value.trim() });
                            }}
                        >
                            <Input placeholder='Nhập Email nhận hóa đơn, chứng từ'>
                            </Input>
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={2}>
                        <Form.Item
                            label="Kỳ kê khai"
                            name='tax_declare_period_type'
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng chọn lỳ kê khai !",
                                },
                            ]}
                        // onBlur={(e) => {
                        //     form.setFieldsValue({ tax_declare_period_type: e.target.value.trim() });
                        // }}
                        >
                            <Select
                                size={"middle"}
                                id="tax_declare_period_type"
                                className="SelectTTDN"
                                style={{ textAlign: "left", width: "100%" }}
                                optionFilterProp="children"
                                placeholder="Chọn kỳ kê khai"
                            >
                                {periodDeclare.map((item, index) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button

                        htmlType="button"
                        className='text-btn design-btn-cancel'

                        onClick={handleCancel}
                    >
                        Hủy
                    </Button>
                    <Button
                        htmlType="submit"
                        className='text-btn design-btn-submit'
                    >
                        Lưu
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default InforWithRoleAdmin                                                                         