import { Button, Col, Form, Input, Modal, Row, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import "./Invoice.css"
import UploadIcon from "../../../../images/image_manage_docs/UploadInModal.svg"
import ErrorImage from "../../../../images/ErrorNotifiIcon.svg"
import SuccessImage from "../../../../images/SuccessNotiIcon.svg"
import { openNotificationSweetAlert } from '../../../../Function';
import { PostUploadFileDirectApi } from '../../../../api/usersApi';

import { useHistory } from 'react-router-dom';

const { Dragger } = Upload;

function ModalUpload({ open, close, nameCompany, idCustomer }) {
    const [form] = Form.useForm();
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [fileList, setFileList] = useState([])
    const history = useHistory();
    const onFinish = (values) => {

        let checkSize = 0;
        fileList.map(item =>
            checkSize += item.size
        )
        const size = checkSize / 1024 / 1024 > 50

        if (size === false) {
            setLoadingSubmit(true)
            var FormData = require("form-data");
            var data = new FormData();

            data.append("customer_id", idCustomer);
            data.append("type_clause", "");
            if (
                values.file_upload.fileList != undefined &&
                values.file_upload.fileList.length != 0
            ) {
                values.file_upload.fileList.forEach((element) => {
                    data.append("file_upload", element.originFileObj);
                });
            }
            PostUploadFileDirectApi(data).then(res => {
                setLoadingSubmit(false)
                handleClose()
                openNotificationSweetAlert(SuccessImage, res.data.message)
                history.push('/history')
                sessionStorage.setItem("idCompany", idCustomer)
            }).catch(err => {
                setLoadingSubmit(false)
                openNotificationSweetAlert(ErrorImage, err.data.message)
            })
        } else {
            openNotificationSweetAlert(ErrorImage, "Dung lượng file đã quá 50MB !!!")
        }
    }

    const handleClose = () => {
        if (loadingSubmit === false) {
            close();
            setFileList([])
        }
    }

    useEffect(() => {
        if (open === false) {
            form.resetFields();
        }
    }, [open]);

    const handleChange = (info) => {
        let newFileList = [...info.fileList];
        setFileList(newFileList);
    };
    return (

        <Modal open={open} footer={false} onCancel={handleClose} closable={false} width={"40%"} >
            <Row className='title-modal'>
                <span>Tải lên chứng từ</span>
            </Row>
            <div style={{ padding: "14px 0px" }}>
                <label className='label-form'>Tên tổ chức</label>
                <Input readOnly value={nameCompany} />
            </div>
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
                style={{ height: "auto" }}
            >
                <Form.Item
                    name="file_upload"
                    label=<span className='label-form'>Dữ liệu chứng từ</span>
                    className='form-email'
                    rules={[
                        {
                            required: true,
                            message: "Vui lòng chọn file"
                        }
                    ]}
                >
                    <Dragger
                        accept={" .pdf, .jfif, .pjpeg, .jpeg, .pjp, .jpg, .png, .html, .shtml, .ehtml, .shtm, .htm"}
                        name="file"
                        openFileDialogOnClick={true}
                        // defaultFileList={fileList}
                        fileList={fileList}
                        onChange={(info) => handleChange(info)}
                        showUploadList={true}
                        multiple
                        style={{ height: "20vh", overflow: "auto" }}
                        beforeUpload={(file, FileList) => {
                            let checkNameFile = false;

                            if (fileList.length > 0) {
                                for (const element of fileList) {
                                    if (file.name === element.name) {
                                        checkNameFile = true
                                        break;
                                    }
                                }
                            }
                            const isJpgOrPng =
                                ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel.sheet.macroEnabled.12"].includes(file.type);
                            return !isJpgOrPng && !checkNameFile ? false : Upload.LIST_IGNORE;
                        }}
                    >
                        {/* <p className="ant-upload-drag-icon"> */}
                        <img alt='' src={UploadIcon}></img>
                        {/* </p> */}
                        <p className="text-upload">
                            Click hoặc kéo thả để chọn file
                        </p>
                        <p className="text-hint">
                            ( Hỗ trợ các định dạng : .pdf, .jfif, .pjpeg, .jpeg, ...)
                        </p>
                    </Dragger>
                </Form.Item>
                <span className="text-upload">Dung lượng tải lên tối đa : 50 MB</span>
                <Form.Item>
                    <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            htmlType="button"
                            onClick={handleClose}
                            loading={loadingSubmit}
                            className='text-btn design-btn-cancel'
                        >
                            Hủy
                        </Button>
                        <Button
                            variant="contained"
                            type="success"
                            htmlType="submit"
                            loading={loadingSubmit}
                            className='text-btn design-btn-submit'
                        >
                            Lưu
                        </Button>
                    </Col>
                </Form.Item>
            </Form>
        </Modal>

    )
}

export default ModalUpload