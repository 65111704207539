import { listTaxAgent, listTaxAgentInfor, lockUser, manageUser } from "../constants";
import { authAxios } from "./axiosClient";

export const GetListTaxAgent = (params = {}) => {
    const url = listTaxAgent;
    return authAxios().get(url, { params });
};

export const GetListTaxAgentInfor = (params = {}) => {
    const url = listTaxAgentInfor;
    return authAxios().get(url, { params });
};

export const PostInforUser = (params = {}) => {
    const url = manageUser;
    return authAxios().post(url, params);
};

export const PutInforUser = (params = {}) => {
    const url = manageUser;
    return authAxios().put(url, params);
};

export const GetInforUser = (params = {}) => {
    const url = manageUser;
    return authAxios().get(url, { params });
};

export const LockUserApi = (params = {}) => {
    const url = lockUser;
    return authAxios().put(url, params);
};
