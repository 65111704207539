import { Button, Col, DatePicker, Form, Input, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { ExportListDocumentApi } from '../../../../api/listDocument';

const ModalExportListDocument = ({ open, setIsOpenModalExport, idCustomer, dataDetailCompany, yearDeclare }) => {
    const [form] = Form.useForm();
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const onFinish = (value) => {
        // Dùng để tải tất cả thông tin đầu vào hóa đơn dạng file zip: ảnh/bản thể hiện HD, file excel HD theo mẫu
        const fnFileDownload = require("js-file-download");
        let letterPeriod = value.type_declare === "Tháng" ? "T" : "Q"


        let taxDeclarePeriod = letterPeriod === "T" ? value.period_declare.$M + 1 : (value.period_declare.$M / 3 )+ 1
        const filename = dataDetailCompany.tax_code + "_BaoCaoBangKe_" + letterPeriod + taxDeclarePeriod + "_" + value.year_declare + ".zip"

        setLoadingSubmit(true)
        ExportListDocumentApi({
            id_customer: idCustomer,
            tax_declare_year: value.year_declare,
            tax_declare_period: taxDeclarePeriod,
        })
            .then((res) => {
                setIsOpenModalExport(false)
                fnFileDownload(res.data, filename);
                form.resetFields()
                setLoadingSubmit(false)

            })
            .catch((err) => {
                setLoadingSubmit(false)
            });

    };

    const handleCancel = () => {
        form.resetFields()
        setIsOpenModalExport(false)
    }

    useEffect(() => {
        let typeDeclare = ""
        if (Number(dataDetailCompany.tax_declare_period_type) === 0) {
            typeDeclare = "Tháng"
        } else {
            typeDeclare = "Quý"
        }

        form.setFieldsValue({
            year_declare: yearDeclare,
            type_declare: typeDeclare,
            period_declare: null
        })
    }, [open]);

    const formatQuarter = (value, type) => {
        return `${type} ${value}`;
    }

    const chooseTaxDeclare = () => {
        if (Number(dataDetailCompany.tax_declare_period_type) === 0) {
            return (

                <DatePicker
                    placeholder="Chọn tháng"
                    format={(value) => formatQuarter(value.month() + 1, "Tháng")}
                    popupClassName='header-datepicker-listDocument'
                    // className=''
                    picker="month"
                    style={{ width: "100%" }}
                    locale={{
                        lang: {
                            locale: "en_US",
                            yearFormat: "YYYY",
                            shortMonths: [
                                "Tháng 1",
                                "Tháng 2",
                                "Tháng 3",
                                "Tháng 4",
                                "Tháng 5",
                                "Tháng 6",
                                "Tháng 7",
                                "Tháng 8",
                                "Tháng 9",
                                "Tháng 10",
                                "Tháng 11",
                                "Tháng 12"
                            ],
                        }
                    }}
                >
                </DatePicker>

            )
        } else {
            return (
                <DatePicker style={{ width: "100%" }} popupClassName='header-datepicker-listDocument' placeholder="Chọn Quý" format={(value) => formatQuarter(value.quarter(), "Quý")} picker="quarter"></DatePicker>
            )
        }
    }

    return (
        <Modal open={open} onCancel={handleCancel} footer={false} closable={false} width={"40%"}>
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
                style={{ height: "auto" }}
            >
                <Row style={{ display: "flex" }}>
                    <Col span={8} style={{ paddingRight: "1.5%" }}>
                        <Form.Item
                            name="year_declare"
                            label=<span className='label-form'>  Năm kê khai</span>
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input readOnly></Input>
                        </Form.Item>
                    </Col>
                    <Col span={8} style={{ paddingRight: "1.5%" }}>
                        <Form.Item
                            name="type_declare"
                            label=<span className='label-form'>Loại kê khai</span>
                            rules={[
                                {
                                    required: true,
                                }
                            ]}
                        >
                            <Input readOnly></Input>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="period_declare"
                            label=<span className='label-form'>Kỳ kê khai</span>
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng chọn kỳ kê khai"
                                }
                            ]}
                        >
                            {chooseTaxDeclare()}
                        </Form.Item>
                    </Col>
                </Row>

                <Col style={{ display: "flex", justifyContent: "flex-end", paddingTop: "4%" }}>
                    <Button
                        // variant="contained"
                        // type="success"
                        htmlType="button"
                        onClick={handleCancel}
                        loading={loadingSubmit}
                        className='text-btn design-btn-cancel'
                    >
                        Hủy
                    </Button>
                    <Button
                        variant="contained"
                        type="success"
                        htmlType="submit"
                        loading={loadingSubmit}
                        className='text-btn design-btn-submit'
                    >
                        Tải
                    </Button>
                </Col>
            </Form>
        </Modal>
    )
}

export default ModalExportListDocument