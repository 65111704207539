import { useEffect, useState } from 'react'
import "./Tutorial.css"
import { Row, Col, Modal } from 'antd'
import InforCompanyIcon from "../../../images/icon_sidebar/InforCompanyIcon.svg"
import ManageOutputIcon from "../../../images/icon_sidebar/ManageInputIcon.svg"
import SearchDocumentIcon from "../../../images/icon_sidebar/searchDocumentIcon.svg"
import ClauseDocumentIcon from "../../../images/icon_sidebar/ClauseDocumentIcon.svg"
import SettingColumnIcon from "../../../images/icon_sidebar/SettingColumnsIcon.svg"

import CategoryMasterGIF from "../../../images/file_gif/VAC_Add_Master.gif"
import ClauseInvoiceGIF from "../../../images/file_gif/VAC_DK_HD.gif"
import ClauseStatementGIF from "../../../images/file_gif/VAC_DK_SK.gif"
import EditMasterGIF from "../../../images/file_gif/VAC_Edit_Master.gif"
import SettingColumnGIF from "../../../images/file_gif/VAC_MauXuat.gif"
import InforCompanyGIF from "../../../images/file_gif/VAC_TT_DoanhNghiep.gif"
import InforUploadGIF from "../../../images/file_gif/VAC_Upload_CT.gif"
import DocumentInvoiceGIF from "../../../images/file_gif/VAC_view_HD.gif"
import DocumentStatementGIF from "../../../images/file_gif/VAC_View_Sk.gif"

import InforCompanyImage from "../../../images/image_tutorital/InforCompany.png"
import InforUploadImage from "../../../images/image_tutorital/InforUpload.png"
import ClauseInvoiceImage from "../../../images/image_tutorital/ClauseInvoice.png"
import ClauseStatementImage from "../../../images/image_tutorital/ClauseStatement.png"
import AddMasterImage from "../../../images/image_tutorital/AddMaster.png"
import UpdateMasterImage from "../../../images/image_tutorital/UpdateMaster.png"
import SearchInvoiceImage from "../../../images/image_tutorital/SearchInvoice.png"
import SearchStatementImage from "../../../images/image_tutorital/SearchStatement.png"
import SettingColumnsImage from "../../../images/image_tutorital/SettingColumns.png"


const ModalTutorial = ({ open, setIsOpenModalTutorial }) => {
    const [chooseOption, setChooseOption] = useState("Khai báo thông tin DN")

    const handlCancel = () => {
        setIsOpenModalTutorial(false)
    }

    const pageTutorial = () => {
        let gifTutorial = ""
        let imageTutorial = ""
        let stepGuide = []
        if (chooseOption === "Khai báo danh mục") {
            // ADD Master
            const stepAddMaster = [
                '1. Chọn một trong các loại hình kinh doanh của doanh nghiệp',
                '2. Tải tệp tin danh mục tài khoản lên hệ thống tại đây',
                '3. Tải tệp tin danh mục NCC/KH lên hệ thống tại đây',
                '4. Chọn số thông tư',
                '5. Nhập số tiền cho phép lệch của 1 hóa đơn',
                '6. Chọn “Lưu” để lưu toàn bộ thông tin vừa nhập',
                '7. Chọn “Hủy” để hủy toàn bộ thông tin vừa nhập'
            ]

            // Update Master
            const stepUpdateMaster = [
                '1. Chọn “Chỉnh sửa” để cập nhật danh mục tài khoản',
                '2. Chọn loại hóa đơn mua vào hoặc bán ra',
                '3. Tạo thông tin loại tài khoản mới tại đây',
                '4. Chọn vào đây để chỉnh sửa thông tin tài khoản',
                '5. Chọn “Lưu” để lưu toàn bộ thay đổi'
            ]
            gifTutorial = CategoryMasterGIF;
            return <CategoryMasterTutorial gifTutorial={gifTutorial} editMastegory={EditMasterGIF} stepAddMaster={stepAddMaster} stepUpdateMaster={stepUpdateMaster} />
        } else {
            if (chooseOption === "Khai báo thông tin DN") {
                stepGuide = [
                    '1. Chọn “Chỉnh sửa” để thay đổi các thông tin của doanh nghiệp',
                    '2. Chọn Tỉnh/Thành phố tại đây',
                    '3. Chọn Quận/Huyện tại đây',
                    '4. Thêm hoặc xóa email nhận hóa đơn/chứng từ tại đây',
                    '5. Chọn “Lưu” để lưu lại toàn bộ các thông tin vừa thay đổi',
                    '6. Chọn “Hủy” để hủy toàn bộ thay đổi'
                ]
                imageTutorial = InforCompanyImage
                gifTutorial = InforCompanyGIF;
            }
            else if (chooseOption === "Thông tin tải lên") {
                stepGuide = [
                    '1. Chọn vào đây để tải các chứng từ lên hệ thống',
                    '2. Chọn vào đây để chọn chứng từ cần tải lên',
                    `3. Chọn “Lưu” để tải lên`,
                    `4. Chọn “Hủy” để hủy tệp tin vừa chọn`,
                ]
                imageTutorial = InforUploadImage
                gifTutorial = InforUploadGIF;
            }
            else if (chooseOption === "Tra cứu hóa đơn") {
                stepGuide = [
                    '1. Nhập các tiêu chí để lọc hóa đơn cần tìm',
                    '2. Chọn vào biểu tượng này để tra cứu hóa đơn',
                    '3. Chọn vào đây để xem chi tiết hóa đơn',
                    '4. Chọn vào từng đầu mục để xem thông tin chi tiết hoặc ẩn thông tin của từng đầu mục hóa đơn',
                    '5. Chọn vào đây để chỉnh sửa thông tin chi tiết của hóa đơn',
                    '6. Nhập các trường thông tin cần chỉnh sửa',
                    '7. Chọn “Lưu” để lưu toàn bộ thông tin vừa chỉnh sửa',
                    '8. Chọn “Hủy” để hủy toàn bộ thông tin vừa chỉnh sửa'
                ]
                imageTutorial = SearchInvoiceImage
                gifTutorial = DocumentInvoiceGIF;
            }
            else if (chooseOption === "Tra cứu sao kê") {
                stepGuide = [
                    '1. Nhập các tiêu chí để lọc bản sao kê cần tìm',
                    '2. Chọn vào biểu tượng này để tra cứu sao kê',
                    '3. Chọn vào đây để xem chi tiết sao kê',
                    '4. Tải lên các bản sao kê tại đây',
                    '5. Tải xuống các bản sao kê tại đây'
                ]
                imageTutorial = SearchStatementImage
                gifTutorial = DocumentStatementGIF;
            }
            else if (chooseOption === "Định khoản hóa đơn") {
                stepGuide = [
                    '1. Tải lên hóa đơn tại đây (Chỉ cho phép định dạng excel)',
                    '2. Chọn vào đây để chọn hóa đơn cần tải lên',
                    '3. Chọn “Định khoản” để tiến hành định khoản hóa đơn vừa chọn',
                    '4. Chọn “Hủy” để hủy bỏ hóa đơn vừa tải lên'
                ]
                imageTutorial = ClauseInvoiceImage
                gifTutorial = ClauseInvoiceGIF;
            }
            else if (chooseOption === "Định khoản sao kê") {
                stepGuide = [
                    '1. Tải lên tệp tin sao kê tại đây',
                    '2. Chọn vào đây để chọn tệp sao kê cần tải lên',
                    '3. Chọn “Định khoản” để tiến hành định khoản sao kê vừa chọn',
                    '4. Chọn “Hủy” để hủy bỏ tệp sao kê vừa tải lên'
                ]
                imageTutorial = ClauseStatementImage
                gifTutorial = ClauseStatementGIF;
            }
            else if (chooseOption === "Xuất hóa đơn theo mẫu") {
                stepGuide = [
                    '1. Chọn vào biểu tượng này để cài đặt và tùy chỉnh mẫu xuất hóa đơn',
                    '2. Chọn một hoặc nhiều cột cần hiển thị(bảng bên trái) hoặc cần ẩn(bảng bên phải) ở mẫu xuất hóa đơn',
                    '3. Chọn vào đây để thêm hoặc ẩn các cột',
                    '4. Chọn “Lưu” để lưu mẫu xuất',
                    '5. Chọn “Hủy” để hủy toàn bộ thay đổi',
                    '6. Chọn “Quay lại Trang Mẫu” để xem trước mẫu xuất hóa đơn vừa được cài đặt',
                    '7. Quay về màn Thông tin tải lên',
                    '8. Nhấn vào đây để tải xuống theo mẫu xuất đã cài đặt.'
                ]
                imageTutorial = SettingColumnsImage
                gifTutorial = SettingColumnGIF;
            }

            return <ShowGifTutorial gifTutorial={gifTutorial} title={chooseOption} imageTutorial={imageTutorial} stepGuide={stepGuide} />
        }

    }

    useEffect(() => {
        pageTutorial()
    }, [chooseOption]);

    const choosePage = (option) => {
        setChooseOption(option)
    }

    const chooseClassName = (text) => {
        if (chooseOption === text) {
            return "focus-option"
        } else {
            return null;
        }
    }

    return (
        <Modal style={{ top: 50 }} className="ant-tutorial" open={open} onCancel={handlCancel} footer={false} width={"75%"}>
            <Row style={{ height: "85vh" }}>
                <Col className='menu-tutorial' span={6}>
                    <div style={{ padding: "8% 5%" }}>
                        <div style={{ padding: "0% 0% 10%", borderBottom: "1px solid #E6E6E6" }}>
                            <p className='main-option'><img src={InforCompanyIcon} alt=''></img>Quản lý dữ liệu đầu vào</p>
                            <div className='focus-tutorial'>
                                <div style={{ paddingLeft: "7%" }}>
                                    <button className='disable-button' style={{ color: "unset" }} onClick={() => choosePage("Khai báo thông tin DN")}>
                                        <span className={chooseClassName("Khai báo thông tin DN")}>
                                            <span>Khai báo thông tin DN</span>
                                        </span>
                                    </button>

                                    <button className='disable-button' style={{ color: "unset" }} onClick={() => choosePage("Khai báo danh mục")}>
                                        <span className={chooseClassName("Khai báo danh mục")}>
                                            <span>Khai báo danh mục</span>
                                        </span>
                                    </button>

                                    <button className='disable-button' style={{ color: "unset" }} onClick={() => choosePage("Thông tin tải lên")}>
                                        <span className={chooseClassName("Thông tin tải lên")}>
                                            <span>Thông tin tải lên</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: "0% 0% 10%", borderBottom: "1px solid #E6E6E6" }}>
                            <p className='main-option'><img src={ManageOutputIcon} alt=''></img>Quản lý dữ liệu đầu ra</p>
                            <div className='focus-tutorial'>
                                <div style={{ paddingLeft: "10%" }}>

                                    <p className='main-option'><img src={SearchDocumentIcon} alt=''></img>Tra cứu chứng từ</p>

                                    <div className='sub-option'>
                                        <button className='disable-button' style={{ color: "unset" }} onClick={() => choosePage("Tra cứu hóa đơn")} >
                                            <span className={chooseClassName("Tra cứu hóa đơn")}>
                                                <span>Hóa đơn</span>
                                            </span>
                                        </button>

                                        <button className='disable-button' style={{ color: "unset" }} onClick={() => choosePage("Tra cứu sao kê")} >
                                            <span className={chooseClassName("Tra cứu sao kê")}>
                                                <span>Sao kê</span>
                                            </span>
                                        </button>
                                    </div>

                                    <p className='main-option'><img src={ClauseDocumentIcon} alt=''></img>Định khoản chứng từ</p>

                                    <div className='sub-option'>
                                        <button className='disable-button' style={{ color: "unset" }} onClick={() => choosePage("Định khoản hóa đơn")} >
                                            <span className={chooseClassName("Định khoản hóa đơn")}>
                                                <span>Hóa đơn</span>
                                            </span>
                                        </button>

                                        <button className='disable-button' style={{ color: "unset" }} onClick={() => choosePage("Định khoản sao kê")}>
                                            <span className={chooseClassName("Định khoản sao kê")}>
                                                <span>Sao kê</span>
                                            </span>
                                        </button>
                                    </div>

                                    <button className='disable-button' style={{ color: "unset" }} onClick={() => choosePage("Xuất hóa đơn theo mẫu")}>
                                        <span
                                            className={chooseClassName("Xuất hóa đơn theo mẫu")}
                                            style={{ fontSize: 14, fontWeight: "bold", paddingLeft: 10, display: "flex", columnGap: "1ch" }}
                                        >
                                            <img src={SettingColumnIcon} alt=''></img>Xuất hóa đơn theo mẫu
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={18} style={{ padding: "2% 0%" }}>
                    {pageTutorial()}
                </Col>
            </Row>
        </Modal>
    )
}

const ShowGifTutorial = ({ gifTutorial, title, imageTutorial, stepGuide }) => {
    return (
        <div className='tutorial-master'>
            <div style={{ padding: "0% 5%", display: "grid", rowGap: "2ch" }}>
                <p>{title}</p>
                <div className='bg-gif'>
                    <img src={gifTutorial} alt=''></img>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={imageTutorial} alt='' style={{ width: "75%" }}></img>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ display: 'grid', width: "70%" }}>
                        {stepGuide.map(item =>
                            <span key={item}>{item}</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

const CategoryMasterTutorial = ({ gifTutorial, editMastegory, stepAddMaster, stepUpdateMaster }) => {
    return (
        <div className='tutorial-master'>

            <div style={{ padding: "0% 5% 2%", display: "grid", rowGap: "2ch" }}>
                <p>thêm danh mục</p>
                <div className='bg-gif'>
                    <img src={gifTutorial} alt=''></img>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={AddMasterImage} alt='' style={{ width: "75%" }}></img>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ display: 'grid', width: "70%" }}>
                        {stepAddMaster.map(item =>
                            <span key={item}>{item}</span>
                        )}
                    </div>
                </div>
            </div>

            <div style={{ padding: "0% 5%", display: "grid", rowGap: "2ch" }}>
                <p>chỉnh sửa danh mục</p>
                <div className='bg-gif'>
                    <img src={editMastegory} alt=''></img>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={UpdateMasterImage} alt='' style={{ width: "75%" }}></img>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ display: 'grid', width: "70%" }}>
                        {stepUpdateMaster.map(item =>
                            <span key={item}>{item}</span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalTutorial