import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { DownloadDetailHistoryApi, DownloadFileHistoryApi, GetDetailHistoryApi } from '../../../../../api/historyApi'
import { Button, Col, Input, Modal, Row, Select, Table } from 'antd'
import dayjs from 'dayjs'
import DownloadStatement from "./../../../../../images/image_manage_docs/DownloadStatement.svg"
import DownloadIcon from "./../../../../../images/image_manage_docs/DownloadInvoice.svg"

const { Option } = Select;


function ViewDetailMobile({
    isOpenModalDetail,
    setIsOpenModalDetail,
    nameStatusDetail,
    idDetail,
    idCustomer
}) {
    const status = [
        { id: 1, name: "Đang xử lý" },
        { id: 3, name: "Thất bại" },
        { id: 4, name: "Hoàn thành" }
    ]
    const [dataDetail, setDataDetail] = useState()
    const [dataSource, setDataSource] = useState([])
    const [dataFilter, setDataFilter] = useState([])
    const [loadingBtnDownload, setLoadingBtnDownload] = useState(false)
    const [loadingBtnSingleDownload, setLoadingBtnSingleDownload] = useState(false)
    const userInfo = useSelector((state) => state.getUserInfo.userInfo);
    const [searchCodeLookup, setSearchCodeLookup] = useState("")
    const [searchStatus, setSearchStatus] = useState([])
    const [typeUpload, setTypeUpload] = useState("");
    const [pager, setPager] = useState({
        pageSize: 10,
        count: 0,
        current: 1,
    });

    const handleCancel = () => {
        setIsOpenModalDetail(false)
    }

    const customColorStatus = (text) => {
        if (text.toLowerCase() === 'thất bại') {
            return { color: "#E55353", background: "rgba(229, 83, 83, 0.13)" }
        }
        else if (text.toLowerCase() === 'đang xử lý') {
            return { color: "#FAAB35", background: "rgba(250, 171, 53, 0.17)" }
        }
        else if (text.toLowerCase() === 'hoàn thành') {
            return { color: "#2EB85C", background: "rgba(87, 232, 135, 0.15)" }
        }
        else if (text.toLowerCase() === 'đang định khoản') {
            return { color: "#4873DE", background: "rgba(72, 115, 222, 0.13)" }
        }
    }

    const downloadFileSingleRecord = (value) => {
        setLoadingBtnSingleDownload(true)
        const fnFileDownload = require("js-file-download");
        const filename = value.file_name;
        DownloadFileHistoryApi({
            id_detail: value.id,
        }).then((res) => {
            fnFileDownload(res.data, filename);
            setLoadingBtnSingleDownload(false)
        }).catch((err) => {
            setLoadingBtnSingleDownload(false)
        });
    }

    const columns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'name',
            align: "center",
            ellipsis: true,
            width: 50,
            render: (value, item, index) =>
                index + 1 + (pager.current - 1) * pager.pageSize,
        },
        {
            title: 'Thao tác',
            dataIndex: 'thao_tac',
            key: 'thao_tac',
            align: "center",
            ellipsis: true,
            width: 80,
            render: (text, record) => {
                return <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: "1.5ch" }}>
                    <img style={{ cursor: "pointer" }} onClick={() => downloadFileSingleRecord(record)} alt='' src={DownloadStatement}></img>
                </Row>
            }
        },
        {
            title: 'Tên file',
            dataIndex: 'file_name',
            key: 'file_name',
            align: "center",
            ellipsis: true,
            // width: 100
        },

        {
            title: 'Mã chứng từ',
            dataIndex: 'code_lookup',
            key: 'code_lookup',
            align: "center",
            ellipsis: true,
            // width: 100
        },
        {
            title: 'Loại chứng từ',
            dataIndex: 'type_clause',
            key: 'type_clause',
            align: "center",
            ellipsis: true,
            // width: 100
        },
        {
            title: 'Ngày hoàn thành',
            dataIndex: parseInt(typeUpload) !== 2 ? 'classify_date' : "submit_date",
            key: parseInt(typeUpload) !== 2 ? 'classify_date' : "submit_date",
            align: "center",
            ellipsis: true,
            render: (text, record) => (text !== undefined && text !== null) ? formatDate(text) : null
        },

        {
            title: 'Trạng thái',
            dataIndex: 'name_status',
            key: 'name_status',
            align: "center",
            ellipsis: true,
            render: (text, record) => {
                return (<>
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <p className='text-dinh-khoan' style={customColorStatus(text)}>{text}</p>
                    </div>
                </>)
            }
        },
    ];

    const fetchDataDetail = () => {
        GetDetailHistoryApi({
            id_customer: idCustomer,
            upload_id: idDetail
        }).then(res => {
            setTypeUpload(res.data.type_upload)
            setDataDetail(res.data)
            setDataSource(res.data.data_detail)
            setDataFilter(res.data.data_detail)
            // setNameStatusDetail(value.name_status)
        }).catch(err => {
            console.log(err)
        })
    }

    const formatDate = (value) => {
        return dayjs(value).format("DD-MM-YYYY")
    }

    useEffect(() => {
        if (isOpenModalDetail === true) {
            fetchDataDetail()
        }
    }, [isOpenModalDetail]);

    const customColor = () => {
        if (nameStatusDetail.toLowerCase() === "đang xử lý") {
            return "rgb(250, 171, 53)"
        } else if (nameStatusDetail.toLowerCase() === "hoàn thành") {
            return "rgb(46, 184, 92)"
        } else {
            return "rgb(229, 83, 83)"
        }
    }

    const downloadFile = () => {
        setLoadingBtnDownload(true)
        const fnFileDownload = require("js-file-download");
        const filename = "DanhSachFile_" + dayjs(new Date()).format("DDMMYYYY") + ".zip";
        DownloadDetailHistoryApi({
            id_customer: userInfo.customer_id,
            upload_id: dataDetail.id
        }).then((res) => {
            fnFileDownload(res.data, filename);
            setLoadingBtnDownload(false)

        }).catch((err) => {
            setLoadingBtnDownload(false)
        });
    }

    const handleFilter = (e) => {
        setSearchCodeLookup(e.target.value)
    }

    const handleFilterStatus = (value) => {
        setSearchStatus(value)
    }

    useEffect(() => {
        let data = []
        if (searchCodeLookup !== "" && searchStatus.length === 0) {
            data = dataSource.filter(item => item.code_lookup !== null ? item.code_lookup.match(searchCodeLookup) : null)
            setDataFilter(data)
        } else if (searchCodeLookup === "" && searchStatus.length !== 0) {
            data = dataSource.filter(item => searchStatus.includes(parseInt(item.status)))
            setDataFilter(data)
        } else if (searchCodeLookup === "" && searchStatus.length === 0) {
            setDataFilter(dataSource)
        } else if (searchCodeLookup !== "" && searchStatus.length !== 0) {
            data = dataSource.filter(item => searchStatus.includes(parseInt(item.status)) && item.code_lookup !== null ? item.code_lookup.match(searchCodeLookup) : null)
            setDataFilter(data)
        }
    }, [dataSource, searchCodeLookup, searchStatus]);

    const handleChange = (pagination) => {
        const currentPager = { ...pager };
        currentPager.current = pagination.current;
        currentPager.pageSize = 10;
        setPager({ ...currentPager });
        // fetchDataHistory({
        //   id_customer: userInfo.customer_id,
        //   page: pagination.current,
        //   page_size: pagination.pageSize,

        //   // search,
        // });
    };
    return (
        <>
            <Modal
                open={isOpenModalDetail}
                // onOk={handleOk}
                onCancel={handleCancel}
                width={'85%'}

                style={{ height: '60vh', top: 10, position: "relative" }}
                // closable={false}
                // className="modal-detail"

                footer={false}
            // centered
            >
                {dataDetail !== undefined ?
                    <Row style={{ rowGap: "2ch" }}>
                        <div className='title-modal-detail'>
                            <span>Chi tiết lịch sử tải lên</span>
                        </div>
                        <Row className='row-detail-history'>
                            <Col span={24}><span style={{ fontWeight: "bold" }}>Mã gói yêu cầu:</span> {dataDetail.dataset_name}</Col>
                            <Col span={24}><span style={{ fontWeight: "bold" }}>Ngày tải lên:</span> {formatDate(dataDetail.created_date)}</Col>
                            <Col span={24}><span style={{ fontWeight: "bold" }}>Trạng thái:</span> <span style={{ color: customColor() }}> {nameStatusDetail}</span></Col>
                        </Row>

                        <Row style={{ width: "100%" }}>
                            <Col span={24} style={{ display: "flex", columnGap: "3ch" }}>
                                <Input onChange={(value) => handleFilter(value)} size='small' placeholder='Mã chứng từ' style={{ width: 200 }}></Input>
                                <Select
                                    id="code_district"
                                    size={"middle"}
                                    allowClear
                                    maxTagCount={"responsive"}
                                    mode='multiple'
                                    className="SelectTTDN"
                                    placeholder="Chọn trạng thái"
                                    style={{ width: 200 }}
                                    onChange={(value) => handleFilterStatus(value)}
                                    showSearch={false}
                                    optionFilterProp="children"
                                // defaultValue={status[0]}
                                // disabled={canEdit}
                                >
                                    {status.map((item, index) => (
                                        <Option key={index} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}

                                </Select>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <Button loading={loadingBtnDownload} onClick={downloadFile} className='btn-detail-statement'><img alt='' src={DownloadIcon}></img></Button>
                        </Row>

                        <Table
                            size='small'
                            dataSource={dataFilter}
                            columns={columns}
                            onChange={handleChange}
                            // style={{ marginTop: "1%", fontSize: 14, position: "absolute" }}
                            // className='table-invoice-all'
                            bordered

                            scroll={{
                                x: 800
                            }}
                            pagination={true}
                        // pagination={{
                        //     current: pager.current,
                        //     pageSize: pager.pageSize,
                        //     total: pager.count,
                        //     showTotal: (total, range) => `Tổng cộng: ${total}`
                        // }}
                        />

                    </Row>
                    : null}
            </Modal>
        </>
    )
}

export default ViewDetailMobile