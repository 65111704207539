import { Button, Col, Form, Input, InputNumber, Radio, Row, Select } from 'antd';
import { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { localhost } from '../../../../server';
import { Button as ButtonMui, Container } from '@mui/material';
import CategoryMasterIcon from "../../../../images/CategoryMasterIcon.svg"
import "./CategoryMaster.css"

import IconUpload from "../../../../images/Upload.svg";
import IconEdit from "../../../../images/EditIcon.svg";
import { useSelector } from 'react-redux';
import { GetInforMasterApi, GetListCircularNoApi, PutInforMasterApi } from '../../../../api/categoryMaster';
import BtnDownload from './BtnDownload';

import ErrorImage from "../../../../images/ErrorNotifiIcon.svg"
import SuccessImage from "../../../../images/SuccessNotiIcon.svg"
import WarningImage from "../../../../images/WarningNotiIcon.svg"
import CategoryMasterMobile from './CategoryMasterMobile';
import ModalEditCategoryUser from './modal_edit/ModalEditCategoryUser';
import ModalEditCategoryCustomer from './modal_edit/ModalEditCategoryCustomer';
import { openNotificationSweetAlert, screenMobile } from '../../../../Function';

const { Option } = Select;

const MySwal = withReactContent(Swal);

function CategoryMaster() {

  const [value, setValue] = useState(1);
  const [valuePeriodDeclare, setValuePeriodDeclare] = useState("");

  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const [valueNameDMTK, setValueNameDMTK] = useState(null);
  const [valueNameNCC, setValueNameNCC] = useState(null);
  const [listCircular, setListCircular] = useState([])
  const [urlSupplier, setUrlSupplier] = useState()
  const [urlCustomer, setUrlCustomer] = useState()
  const [form] = Form.useForm();
  const [openEditCategoryUser, setOpenEditCategoryUser] = useState(false)
  const [openEditCategoryCustomer, setOpenEditCategoryCustomer] = useState(false)

  const [isEditCategoryUser, setIsEditCategoryUser] = useState(false)
  const [isEditCategoryCustomer, setIsEditCategoryCustomer] = useState(false)

  const userInfo = useSelector((state) => state.getUserInfo.userInfo);

  const onChange = (e) => {
    fileInputRef1.current = null
    fileInputRef.current = null

    let inputElement = document.getElementById("myFile1")
    let inputElement2 = document.getElementById("myFile2")

    inputElement.value = ""
    inputElement2.value = ""
    setValue(e.target.value);
    FetchInforMaster(userInfo.customer_id, e.target.value)
  };

  const isXlsxFile = (file) => {
    if (file !== undefined) {
      const fileType = file.type;
      const validTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ];
      const fileSize = file.size / 1024 / 1024 < 50
      return validTypes.includes(fileType) && fileSize;
    }
  };


  const handleFileChangeDMKH = (event) => {
    const file = event.target.files[0];
    if (!isXlsxFile(file)) {
      return openNotificationSweetAlert(ErrorImage, "Dung lượng file quá 50MB hoặc sai định dạng");
    } else {
      fileInputRef.current = event.target
      setValueNameDMTK(file.name);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // Handle the uploaded file here
    if (!isXlsxFile(file)) {
      return openNotificationSweetAlert(ErrorImage, "Dung lượng file quá 50MB hoặc sai định dạng");
    } else {
      fileInputRef1.current = event.target
      setValueNameNCC(file.name);
    }
  };

  const FetchInforMaster = (id, idTypeBusiness) => {
    GetInforMasterApi({
      params: {
        id_customer: id,
        id_type_business: idTypeBusiness || null,
      }
    }).then(res => {
      if (res.data.category_account.name_file_full === "") {
        setIsEditCategoryUser(false)
      } else {
        setIsEditCategoryUser(true)
      }

      if (res.data.category_customer.name_file_full === "") {
        setIsEditCategoryCustomer(false)
      } else {
        setIsEditCategoryCustomer(true)
      }

      setValueNameDMTK(res.data.category_account.name_file_full)
      setValueNameNCC(res.data.category_customer.name_file_full)

      form.setFieldsValue({
        dmtk_path: res.data.category_account.name_file_full,
        ncc_path: res.data.category_customer.name_file_full,
        circular: res.data.id_circular,
        business_main: res.data.business_main,
        type_business: res.data.id_type_business,
        amount_deviation: res.data.amount_deviation,
        tax_declare_period_type: res.data.tax_declare_period_type !== null ? Number(res.data.tax_declare_period_type) : null,
      })
      setUrlSupplier(localhost + "/" + res.data.category_customer.path_file)
      setUrlCustomer(localhost + "/" + res.data.category_account.path_file)
    }).catch(err => {

    })
  }
  const FetchListCircular = () => {
    GetListCircularNoApi().then(res => {
      setListCircular(res.data)
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    fileInputRef1.current = null
    fileInputRef.current = null
    FetchInforMaster(userInfo.customer_id)
    FetchListCircular()
  }, []);

  const handleRefreshData = () => {
    let inputElement = document.getElementById("myFile1")
    let inputElement2 = document.getElementById("myFile2")

    inputElement.value = ""
    inputElement2.value = ""
    fileInputRef1.current = null
    fileInputRef.current = null
    FetchInforMaster(userInfo.customer_id)
  }

  const onFinish = (values) => {
    let FormData = require("form-data");
    let data = new FormData();
    data.append("id_customer", userInfo.customer_id);
    data.append("id_circular", values.circular);
    data.append("business_main", values.business_main || "");
    data.append("id_type_business", values.type_business);
    data.append("amount_deviation", values.amount_deviation);
    data.append("tax_declare_period_type", values.tax_declare_period_type);
    if (fileInputRef1.current !== null) {
      if (fileInputRef1.current.files.length !== 0) {
        data.append("file_category_customer", fileInputRef1.current.files[0]);
      } else {
        data.append("file_category_customer", null);
      }
    } else {
      data.append("file_category_customer", null);
    }

    if (fileInputRef.current !== null) {
      if (fileInputRef.current.files.length !== 0) {
        data.append("file_category_account", fileInputRef.current.files[0]);
      } else {
        data.append("file_category_account", null);
      }
    } else {
      data.append("file_category_account", null);
    }

    MySwal.fire({
      title: <span style={{ fontSize: 20 }}>Bạn có chắc muốn lưu thay đổi ?</span>,
      // text: "You won't be able to revert this!",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      imageUrl: WarningImage,
      confirmButtonText: <span>Chắc chắn!</span>,
      cancelButtonText: <span>Không</span>,
      customClass: {
        icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
        popup: "custom-notification",
        confirmButton: "btn-category-master",
        cancelButton: "btn-category-master",
      },
      position: "top",
    }).then((result) => {
      if (result.isConfirmed) {
        PutInforMasterApi(data).then((res) => {
          MySwal.fire({
            title: "Thành công!",
            text: res.data.message,
            // icon: "success",
            imageUrl: SuccessImage,
            customClass: {
              // confirmButton: "btn-confirm-delete",
              icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
              popup: "custom-notification",
              confirmButton: "btn-category-master",
              cancelButton: "btn-category-master",
            },
            position: "top",
          });
          handleRefreshData()
        })
          .catch((err) => {
            handleRefreshData()
            MySwal.fire({
              title: "Thất bại!",
              text: err.data.message,
              imageUrl: ErrorImage,
              customClass: {
                icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
                popup: "custom-notification",
                confirmButton: "btn-category-master",
                cancelButton: "btn-category-master",
              },
              position: "top",
            });
          });
      }
    });
  };

  const openModalEditCategoryUser = () => {
    setOpenEditCategoryUser(true)
  }

  const openModalEditCategoryCustomer = () => {
    setOpenEditCategoryCustomer(true)
  }
  const onChangePeriodDeclare = (e) => {
    setValuePeriodDeclare(e.target.value)
  };

  return (
    <>
      {screenMobile === false ?
        <Container maxWidth="false" style={{ backgroundColor: "#fff", padding: "5%", width: "97%", marginTop: "1%", height: "88vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div className='bg-all-master'>
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={onFinish}
              initialValues={{
                remember: true,
              }}
              className='text-label-manage-company'
              style={{ width: "93%", height: "inherit", padding: "1%" }}
            >
              <Row className="rowInforCustomer">
                <Col
                  span={24}
                  style={{ display: "flex", paddingBottom: "1%" }}
                >
                  <img src={CategoryMasterIcon} alt=''></img>
                  <span className="title-infor-company" style={{ margin: 0, marginLeft: "1%" }}>
                    Danh mục
                  </span>
                </Col>
              </Row>
              <div style={{ marginTop: "2%" }}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Loại hình kinh doanh"
                      name='type_business'
                      rules={[
                        {
                          required: true,
                          message:
                            "Vui lòng chọn danh mục Nhà cung cấp/ Khách hàng!",
                        },
                      ]}>
                      <Radio.Group className='text-checkbox' onChange={onChange} value={value} style={{ display: "flex", columnGap: "20px" }}>
                        <Radio value={1} className='checkbox-sign-up'>Thương mại</Radio>
                        <Radio value={2} className='checkbox-sign-up'>Dịch vụ</Radio>
                        <Radio value={3} className='checkbox-sign-up'>Sản xuất</Radio>
                        <Radio value={4} className='checkbox-sign-up'>Xây dựng</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Kỳ kê khai"
                      name='tax_declare_period_type'
                      rules={[
                        {
                          required: true,
                          message:
                            "Vui lòng chọn Kỳ kê khai!",
                        },
                      ]}>
                      <Radio.Group className='text-checkbox' onChange={onChangePeriodDeclare} value={valuePeriodDeclare} style={{ display: "flex", columnGap: "20px" }}>
                        <Radio value={0} className='checkbox-sign-up'>Tháng</Radio>
                        <Radio value={1} className='checkbox-sign-up'>Quý</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Col className='hidden-bg-input'>
                  <Form.Item name="dmtk_path" label={<span><span style={{ color: "red" }}>* </span>Danh mục tài khoản</span>}>
                    <input
                      ref={fileInputRef}
                      accept=".xlsx,.xls"
                      type={isEditCategoryUser === true ? "text" : "file"}
                      style={{ display: "none" }}
                      // onChange={handleFileChangeDMKH}
                      name="myFile2"
                      id="myFile2"
                      onInput={handleFileChangeDMKH}
                    />
                    <Input
                      placeholder="Danh mục Tài khoản"
                      type="text"
                      // disabled
                      value={valueNameDMTK || null}
                      readOnly
                      size='large'
                      suffix={
                        <div style={{ display: "flex" }}>

                          <ButtonMui
                            htmlFor="myFile2"
                            component="label"
                            size="small"
                            className='none-bg'
                            style={{ height: "29px" }}
                          >
                            {isEditCategoryUser === false ?
                              <div className="iconDiv" tooltip="Tải lên" tabindex="0">
                                <div className="iconSVG">
                                  <img
                                    style={{ cursor: "pointer", paddingTop: "9px" }}
                                    src={IconUpload}
                                    alt=""
                                  ></img>
                                </div>
                              </div>
                              :
                              <div className="iconDiv" tooltip="Chỉnh sửa" tabindex="0" onClick={openModalEditCategoryUser}>
                                <div className="iconSVG">
                                  <img
                                    style={{ cursor: "pointer", paddingTop: "9px" }}
                                    src={IconEdit}
                                    alt=""
                                  ></img>
                                </div>
                              </div>
                            }
                          </ButtonMui>
                          <BtnDownload url={urlCustomer} />
                        </div>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col className='hidden-bg-input'>
                  <Form.Item
                    name="ncc_path"
                    label={<span><span style={{ color: "red" }}>* </span>Danh mục nhà cung cấp khách hàng</span>}
                  >
                    <input
                      ref={fileInputRef1}
                      accept=".xlsx,.xls"
                      type={isEditCategoryCustomer === true ? "text" : "file"}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                      name="myFile1"
                      id="myFile1"
                    />
                    <Input
                      size={"large"}
                      placeholder="Danh mục Nhà cung cấp/ Khách hàng"
                      type="text"
                      // disabled
                      suffix={
                        <div style={{ display: "flex" }}>

                          <ButtonMui
                            htmlFor="myFile1"
                            component="label"
                            size="small"
                            className='none-bg'
                            style={{ height: "29px" }}
                          >
                            {isEditCategoryCustomer === false ?
                              <div className="iconDiv" tooltip="Tải lên" tabIndex="0">
                                <div className="iconSVG">
                                  <img
                                    style={{ cursor: "pointer", paddingTop: "9px" }}
                                    src={IconUpload}
                                    alt=""
                                  ></img>
                                </div>
                              </div>
                              :
                              <div className="iconDiv" tooltip="Chỉnh sửa" tabindex="0" onClick={openModalEditCategoryCustomer}>
                                <div className="iconSVG">
                                  <img
                                    style={{ cursor: "pointer", paddingTop: "9px" }}
                                    src={IconEdit}
                                    alt=""
                                  ></img>
                                </div>
                              </div>
                            }
                          </ButtonMui>
                          <BtnDownload url={urlSupplier} />
                        </div>
                      }
                      value={valueNameNCC}
                      // disabled
                      readOnly
                      className="inputTTDN"
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label="Thông tư" name='circular'>
                    <Select
                      size={"large"}
                      id="code_city"
                      className="SelectTTDN"
                      style={{ textAlign: "left" }}
                      optionFilterProp="children"
                    >
                      {listCircular.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Row>
                  <Col span={12}>
                    <Form.Item label="Ngành nghề kinh doanh" name='business_main'>
                      <Input size='large' style={{ width: "98%", float: "left" }}></Input>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Tiền cho phép lệch hóa đơn"
                      rules={[
                        {
                          required: true,
                          message: "Vui lòng nhập tên viết tắt!",
                        },
                      ]}
                      className='error-amount'
                      name="amount_deviation"
                    >
                      <InputNumber
                        min={0}
                        maxLength={12}
                        suffix="đ"
                        size='large'
                        controls={false}
                        placeholder="Nhập số tiền chênh lệch"
                        formatter={(value) =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        style={{ width: "98%", float: "right" }}
                        onKeyPress={(e) => {
                          const charCode = e.which ? e.which : e.keyCode;
                          if (charCode < 48 || charCode > 57) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    htmlType="button"
                    onClick={handleRefreshData}
                    className='text-btn design-btn'
                    style={{ marginRight: "2%", height: "5vh" }}
                  >
                    Hủy
                  </Button>
                  <Button
                    variant="contained"
                    type="success"
                    htmlType="submit"
                    className='text-btn'
                    style={
                      {
                        border: "2px solid var(--Color-line, #058DF4)",
                        borderRadius: 8,
                        height: "5vh",
                        width: "100px",
                        color: "#fff",
                        background: "var(--Color-line, linear-gradient(0deg, #058DF4 0%, #6266EF 100%))",
                      }
                    }
                  >
                    Lưu
                  </Button>
                </Col>
              </div>
            </Form>
          </div>
          {openEditCategoryUser === true ?
            <ModalEditCategoryUser
              open={openEditCategoryUser}
              setOpenEditCategoryUser={setOpenEditCategoryUser}
              idCustomer={userInfo.customer_id}
            />
            : null}

          {openEditCategoryCustomer === true ?
            <ModalEditCategoryCustomer
              open={openEditCategoryCustomer}
              setOpenEditCategoryCustomer={setOpenEditCategoryCustomer}
              idCustomer={userInfo.customer_id}
            />
            : null}

        </Container>
        : <CategoryMasterMobile
          form={form}
          onFinish={onFinish}
          onChange={onChange}
          value={value}
          fileInputRef={fileInputRef}
          handleFileChangeDMKH={handleFileChangeDMKH}
          valueNameDMTK={valueNameDMTK}
          urlCustomer={urlCustomer}
          fileInputRef1={fileInputRef1}
          handleFileChange={handleFileChange}
          urlSupplier={urlSupplier}
          valueNameNCC={valueNameNCC}
          handleRefreshData={handleRefreshData}
          listCircular={listCircular}
        />}
    </>
  )
}
export default CategoryMaster
