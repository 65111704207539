import {inforInvoice, detailInvoice, handleInvoice, downloadInputInvoice, downloadInputInvoiceAll } from "../constants";
import { authAxios } from "./axiosClient";

export const GetInforInvoiceApi = (params = {}) => {
    const url = inforInvoice;
    return authAxios().get(url, { params });
};

export const GetDetailInvoiceApi = (params = {}) => {
    const url = detailInvoice;
    return authAxios().get(url, { params });
};

export const HandleInvoiceApi = (params = {}) => {
    const url = handleInvoice;
    return authAxios().post(url, params);
};

export const PutInvoiceApi = (params = {}) => {
    const url = detailInvoice;
    return authAxios().put(url, params);
};

export const DownloadInputInvoiceApi = (params = {}) => {
    const url = downloadInputInvoice;
    return authAxios().post(url, params , { responseType: "blob" });
};

export const DownloadInputAllInvoiceApi = (params = {}) => {
    const url = downloadInputInvoiceAll;
    return authAxios().post(url, params , { responseType: "blob" });
};


