import './App.css';
import Main from './containers/router/Router';

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  )
}

export default App;
