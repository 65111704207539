import { Container, TableCell, styled, tableCellClasses } from '@mui/material';
import DownloadIcon from "./../../../../../images/image_manage_docs/DownloadInvoice.svg"
import UploadIcon from "./../../../../../images/image_manage_docs/UploadInvoice.svg"

import "./AccountingInvoice.css"
import Typography from "@mui/material/Typography";
import { Button, Col, Form, Input, Modal, Progress, Row, Select, Spin, Upload } from 'antd';
import React, { useEffect, useState } from 'react'
import UploadExcelIcon from "../../../../../images/image_manage_docs/UploadExcel.svg"
import { GetDeleteFile, GetFileDownloadApi, GetListSPNHApi } from '../../../../../api/usersApi';
import { read, utils } from "xlsx";
import ScanningFileGIF from "./../../../../../images/ScanFile.gif"
import { localhost } from '../../../../../server';
import { useSelector } from 'react-redux';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Fade,
  Tooltip,
} from "@mui/material";
import Excel001 from "../error/Excel001"
import Excel005 from "../error/Error005"
import Excel006 from "../error/Excel006"
import ErrorHD from "../error/ErrorHD"
import { makeStyles } from "@mui/styles";
import { HandleInvoiceApi } from '../../../../../api/InvoiceApi';
import ErrorImage from "../../../../../images/ErrorNotifiIcon.svg"
import { openNotificationSweetAlert, screenMobile } from '../../../../../Function';
import { GetListTaxAgent } from '../../../../../api/manageInforUser';

const { Dragger } = Upload;
const { Option } = Select;


const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 240,
  },
  drawerContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    maxWidth: "100%",
    "& .MuiTableCell-root": {
      border: "1px solid #D1CFD7",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif"
    },
  },
  tooltip: {
    fontSize: "20px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif"
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e5e5f3",
    color: "#2D3459",
    padding: 8,
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    textAlign: "center",
    textOverflow: "ellipsis",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 9,
    textOverflow: "ellipsis",
    textAlign: "center",
    width: 200,
    textTransform: "none",
    whiteSpace: "nowrap"
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    minWidth: 150,
    textTransform: "none",
  },
}));

function AccountingInvoice() {
  const [isOpenModalUploadExcel, setIsOpenModalUploadExcel] = useState(false)
  const classes = useStyles();

  // useEffect(() => {
  //   let data = []
  //   for (let i = 0; i < 10; i++) {
  //     data.push({
  //       key: i,
  //       ngay_hach_toan: '11/12/2023',
  //       ngay_chung_tu: '20/12/2023',
  //       so_chung_tu: '123465798',
  //       dien_giai: 'Nước uống đóng chai',
  //       tai_khoan: '642',
  //       tai_khoan_doi_ung: '331',
  //       phat_sinh_no: '4,200,000',
  //       phat_sinh_co: '4,000,000',
  //     })
  //   }
  // }, []);

  const closeModalUploadExcel = () => {
    setFileList([])
    setIsOpenModalUploadExcel(false)
  }

  useEffect(() => {
    if (isOpenModalUploadExcel === false) {
      form.resetFields()
    }
  }, [isOpenModalUploadExcel]);

  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [inforError, setInforError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [infoError, setInfoError] = useState("");
  const [isOpenModalExcel001, setIsOpenModalExcel001] = useState(false);
  const [isOpenModalExcel005, setIsOpenModalExcel005] = useState(false);
  const [isOpenModalExcel006, setIsOpenModalExcel006] = useState(false);
  const [isOpenProgress, setIsOpenProgress] = useState(false);
  const [progressProcessing, setProgressProcessing] = useState(0);
  const [statusFileHD, setStatusFileHD] = useState("");
  const [isOpenErrorHD, setIsOpenErrorHD] = useState(false);
  const [isOpenScanningFile, setIsOpenScanningFile] = useState(false);
  const [nameImport, setNameImport] = useState("");
  const [loading, setLoading] = useState(true);
  const [phimTatDownload, setPhimTatDownload] = useState("");
  const [namePhimTatDownload, setNamePhimTatDownload] = useState("");
  const [dataOutput, setDataOutput] = useState([]);
  const [typeDocument, setTypeDocument] = useState("");
  const [dataTaxAgent, setDataTaxAgent] = useState([])
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);

  const checkRoleAdminTax = userInfo.role_id === 4 || userInfo.role_id === 3

  const handleOpenModal = () => {
    if (statusFileHD === "Processing") {
      setIsOpenProgress(true);
    } else {
      setIsOpenModalUploadExcel(true);
    }
  };

  const readFileTxtProcessing = async (file) => {
    //Chỗ cần sửa
    const number = file.split("/");
    const progress = (
      (parseInt(number[0]) / parseInt(number[1])) *
      100
    ).toFixed(2);
    setProgressProcessing(progress);
    if (number[0] === 0) {
      setIsOpenScanningFile(true);
    }
    if (number[0] > 0) {
      setIsOpenScanningFile(false);
    }
  };

  const fetchDataOutput = async (file) => {
    const fileTxt = localhost + "/" + file.path_export;
    const response = await fetch(fileTxt);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = read(binaryString, { type: "binary" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetData = utils.sheet_to_json(worksheet, {
        header: 1,
        blankrows: true,
      });
      const accountRowIndex = worksheetData.indexOf(worksheetData[0]);
      const header = worksheetData[accountRowIndex];
      let ls_value = [];
      for (let iRow of worksheetData.slice(accountRowIndex + 1)) {
        let dict_value = {};
        for (let [indexH, valueH] of header.entries()) {
          // gán value cho các key là header
          dict_value[valueH] = iRow[indexH];
        }
        ls_value.push(dict_value);
      }
      const filterData12Row = ls_value.splice(0, 12);
      setDataOutput(filterData12Row);
      setProgressProcessing(0);
    };
    reader.readAsBinaryString(blob);
  };

  const fetchDataOutputError = async (file) => {
    const fileTxt = localhost + "/" + file.path_export;
    const response = await fetch(fileTxt);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = read(binaryString, { type: "binary" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetData = utils.sheet_to_json(worksheet, {
        header: 1,
        blankrows: true,
      });
      let message = "";
      let info = "";
      let info1 = "";

      let messageErrorCount = sessionStorage.getItem("messageErrorUser");

      messageErrorCount = parseInt(messageErrorCount) || 0;

      messageErrorCount += 1;
      sessionStorage.setItem("messageErrorUser", messageErrorCount);

      if (worksheetData.slice(1).toString().includes(",")) {
        message = worksheetData.slice(1).toString().split(",")[1].split(":")[1];
        info = worksheetData
          .slice(1)
          .toString()
          .split(",")[2]
          .split(":")
          .slice(1)
          .join(":");
        info1 = worksheetData.slice(1).toString().split(",")[2].split(":")[1];
      } else {
        message = worksheetData.slice(1).toString();
      }

      if (sessionStorage.getItem("messageErrorUser") === "1") {
        if (message.toUpperCase() === "EXCEL_005") {
          setIsOpenModalExcel005(true);
        } else if (message.toUpperCase() === "EXCEL_006") {
          setIsOpenModalExcel006(true);
        }
      }
      setMessageError(message.toUpperCase());
      setInforError(info1);
      setInfoError(info);
    };
    reader.readAsBinaryString(blob);
  };

  const fetchListData = (id) => {
    GetListSPNHApi({
      id_customer: id,
      name_type: "HD",
    })
      .then((res) => {
        setStatusFileHD(res.data.data.status);
        if (res.data.data.name_import) {
          const index = res.data.data.name_import.indexOf("__");
          const name = res.data.data.name_import.slice(index + 2);
          setNameImport(name);
        }
        if (res.data.data.status === "Available") {
          localStorage.setItem("checkErrorHD", "false");
          setIsOpenProgress(false);
          localStorage.setItem("openProgressHD", "false");
          localStorage.removeItem("idCustomer")
          fetchDataOutput(res.data.data);
        }
        if (res.data.data.status === "Processing") {
          readFileTxtProcessing(res.data.data.invoice_processing);
        }
        if (res.data.data.status === "Error") {
          setIsOpenProgress(false);
          localStorage.setItem("openProgressHD", "false");
          localStorage.removeItem("idCustomer")

          fetchDataOutputError(res.data.data);
        }
      })
      .catch((err) => {

      });
  };

  const fetchFileDownload = (id) => {
    GetFileDownloadApi({
      name_type: "HD",
      id_customer: id,
    })
      .then((res) => {
        setPhimTatDownload(res.data.data.path_full);
        setNamePhimTatDownload(res.data.data.name_import);
      })
      .catch((err) => {
        // openNotificationWithIcon("error", err.data.message);
      });
  };

  const fetchListTaxAgent = () => {
    GetListTaxAgent().then(res => {
      setDataTaxAgent(res.data)
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    if (checkRoleAdminTax === true) {
      fetchListTaxAgent()
    }
    const id = setInterval(() => {
      if (
        localStorage.getItem("openProgressHD") === "true" &&
        localStorage.getItem("checkErrorHD") === "false"
      ) {
        const idCustomer = localStorage.getItem("idCustomer")
        if (checkRoleAdminTax) {
          fetchListData(idCustomer);
          fetchFileDownload(idCustomer);
        } else {
          fetchListData(userInfo.customer_id);
          fetchFileDownload(userInfo.customer_id);
        }
      }
    }, 2000);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    localStorage.setItem("checkErrorHD", "false");
    localStorage.setItem("openProgressHD", "false");

    setLoading(true);
    const fetchListDataFirst = () => {
      GetListSPNHApi({
        id_customer: userInfo.customer_id || 0,
        name_type: "HD",
      })
        .then((res) => {
          setLoading(false);
          setStatusFileHD(res.data);
          if (res.data.data.name_import) {
            const index = res.data.data.name_import.indexOf("__");
            const name = res.data.data.name_import.slice(index + 2);
            setNameImport(name);
          }
          if (res.data.data.status === "Available") {
            localStorage.setItem("checkErrorHD", "false");
            setIsOpenProgress(false);
            fetchDataOutput(res.data.data);
          }
          if (res.data.data.status === "Processing") {
            readFileTxtProcessing(res.data.data.invoice_processing);
          }
          if (res.data.data.status === "Error") {
            localStorage.setItem("checkErrorHD", "true");
            setIsOpenProgress(false);
            fetchDataOutputError(res.data.data);
          }
        })
        .catch((err) => {
        });
    };
    fetchListDataFirst();
    fetchFileDownload(userInfo.customer_id);
  }, []);

  useEffect(() => {
    if (dataOutput.length > 0) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [dataOutput]);

  const isXlsxFile = (file) => {
    if (file !== undefined) {
      const fileType = file.type;
      const validTypes = [
        // .xlsx
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

        //.xls
        "application/vnd.ms-excel",
        // Add more valid xlsx MIME types here if needed
      ];
      return validTypes.includes(fileType);
    }
  };

  const handleImport = ($event) => {
    const files = $event.fileList;
    const file = $event.fileList[0];

    if (file !== undefined) {
      if (!isXlsxFile(file)) {
        return;
      } else {
        const file = files[0];
        const readerImport = new FileReader();
        readerImport.onload = (event) => {
          const wb = read(event.target.result);
          const sheets = wb.SheetNames;
          if (sheets.length) {
            const rowMV = utils.sheet_to_json(wb.Sheets[sheets[0]], {
              header: 1,
            });

            const rowBR = utils.sheet_to_json(wb.Sheets[sheets[1]], {
              header: 1,
            });

            // Tìm dòng chứa "Ngày/Date" hoặc "Ghi nợ/Debit"
            const accountRow = rowMV.find(
              (row) =>
                row.includes("MAU_HD") &&
                row.includes("KH_HD") &&
                row.includes("So_HD")
            );

            const accountRow1 = rowBR.find(
              (row) =>
                row.includes("MAU_HD") &&
                row.includes("KH_HD") &&
                row.includes("So_HD")
            );

            // Hóa đơn mua vào
            if (!accountRow) {

              return null; // Hoặc có thể trả về thông báo lỗi
            } else {
              // Lấy header từ dòng chứa "MAU_HD" hoặc "KH_HD"
              const accountRowIndex = rowMV.indexOf(accountRow);
              const header = rowMV[accountRowIndex];
              const headers = header;

              let ls_value = [];
              for (let iRow of rowMV.slice(accountRowIndex + 1)) {
                let dict_value = {};
                for (let [indexH, valueH] of headers.entries()) {
                  // gán value cho các key là header
                  dict_value[valueH] = iRow[indexH];
                }
                ls_value.push(dict_value);
              }
            }

            // Hóa đơn bán ra
            if (!accountRow1) {             
              return null; // Hoặc có thể trả về thông báo lỗi
            } else {
              // Lấy header từ dòng chứa "MAU_HD" hoặc "KH_HD"
              const accountRowIndex = rowBR.indexOf(accountRow1);
              const header = rowBR[accountRowIndex];
              const headers = header;

              let ls_value = [];
              for (let iRow of rowBR.slice(accountRowIndex + 1)) {
                let dict_value = {};
                for (let [indexH, valueH] of headers.entries()) {
                  // gán value cho các key là header
                  dict_value[valueH] = iRow[indexH];
                }
                ls_value.push(dict_value);
              }
            }
          }
        };
        setFileList(files)
        readerImport.readAsArrayBuffer(file.originFileObj);
      }
    } else {
    }
  };

  const onFinishImport = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("file_upload", fileList[0].originFileObj);
    if (checkRoleAdminTax === true) {
      data.append("id_customer", values.id_customer);
    } else {
      data.append("id_customer", userInfo.customer_id);
    }

    sessionStorage.removeItem("messageErrorUser");
    const size = fileList[0].size / 1024 / 1024 > 50

    if (size === false) {
      HandleInvoiceApi(data)
        .then((res) => {
          form.resetFields();
          setFileList([])
          closeModalUploadExcel()
          setIsOpenProgress(true);
          setDataOutput([]);
          setIsOpenScanningFile(true);
          localStorage.setItem("checkErrorHD", "false");
        })
        .catch((err) => {
          closeModalUploadExcel()
          setIsOpenModalExcel001(true);
          setIsOpenModalExcel005(true);
          setIsOpenModalExcel006(true);
          setMessageError(err.data.message);
          setInfoError(err.data.infor);
          setTypeDocument("HD");
          setIsOpenErrorHD(true);
        });
    } else {
      openNotificationSweetAlert(ErrorImage, "Dung lượng file đã quá 50MB !!!")
    }
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.altKey && event.key === "d") {
        // Xử lý sự kiện khi nhấn tổ hợp phím Ctrl + C
        event.preventDefault(); // Ngăn chặn hành vi mặc định của trình duyệt (ví dụ: tìm kiếm trang)
        if (phimTatDownload) {
          downloadFile();
        } else {
        }
      }
    };

    const downloadFile = async () => {
      const fileUrl = localhost + "/" + phimTatDownload;
      // URL của file bạn muốn tải xuống
      if (phimTatDownload === undefined) {
        openNotificationSweetAlert(ErrorImage, "Thông tin tải xuống không tồn tại")
      } else {
        try {
          const response = await fetch(fileUrl);
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = namePhimTatDownload; // Tên file khi tải xuống
          link.click();
          URL.revokeObjectURL(url);
        } catch (error) {
        }
      }

    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [phimTatDownload]);

  const download = async () => {
    const fileUrl = localhost + "/" + phimTatDownload;
    // URL của file bạn muốn tải xuống

    if (phimTatDownload === undefined) {
      openNotificationSweetAlert(ErrorImage, "Thông tin tải xuống không tồn tại")
    } else {
      try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = namePhimTatDownload; // Tên file khi tải xuống
        link.click();
        URL.revokeObjectURL(url);
      } catch (error) {
        console.log(error)
      }
    }
  };

  const fetchDeleteFile = () => {
    GetDeleteFile()
      .then((res) => {
        fetchListData();
        setDataOutput([]);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.altKey && event.key === "x") {
        // Xử lý sự kiện khi nhấn tổ hợp phím Ctrl + C
        event.preventDefault(); // Ngăn chặn hành vi mặc định của trình duyệt (ví dụ: tìm kiếm trang)
        fetchDeleteFile();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const chooseIdCustomer = (value) => {
    localStorage.setItem("idCustomer", value)
  }

  return (
    <Container maxWidth="false" style={{ backgroundColor: "#fff", width: "97%", marginTop: "1%", height: "88vh" }}>
      <div style={{ width: "100%", position: "relative", paddingTop: "2%" }}>
        {screenMobile === false ?
          <Row>
            <Col span={8}></Col>
            <Col span={8} className='title-table-accounting-invoice'>KẾT QUẢ ĐỊNH KHOẢN HÓA ĐƠN</Col>
            <Col span={8} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button className='btn-invoice-all' onClick={download} style={{ marginRight: "3%", background: "rgba(50, 31, 219, 0.12)", border: "unset" }}><img alt='' src={DownloadIcon} style={{ marginRight: "3%" }}></img><span style={{ color: "rgb(85,69,225)", fontWeight: "bold" }}>TẢI XUỐNG</span></Button>
              <Button onClick={handleOpenModal} className='btn-invoice-all' style={{ background: "rgba(58, 183, 176, 0.15)", border: "unset" }}><img alt='' src={UploadIcon} style={{ marginRight: "3%" }}></img><span style={{ color: "rgb(58,183,176)", fontWeight: "bold" }}>TẢI LÊN</span></Button>
            </Col>
          </Row>
          :
          <Row style={{ rowGap: "1ch" }}>
            <Col span={24} className='title-table-accounting-invoice'>KẾT QUẢ ĐỊNH KHOẢN HÓA ĐƠN</Col>
            <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button className='btn-invoice-all' onClick={download} style={{ marginRight: "3%", background: "rgba(50, 31, 219, 0.12)", border: "unset" }}><img alt='' src={DownloadIcon}></img></Button>
              <Button onClick={handleOpenModal} className='btn-invoice-all' style={{ background: "rgba(58, 183, 176, 0.15)", border: "unset" }}><img alt='' src={UploadIcon}></img></Button>
            </Col>
          </Row>
        }

        <Modal open={isOpenModalUploadExcel} footer={false} onCancel={closeModalUploadExcel} closable={false} width={screenMobile === false ? "40%" : "100%"}>
          <Row className='title-modal'>
            <span>Định khoản hóa đơn</span>
          </Row>

          <Spin spinning={false}>
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              onFinish={onFinishImport}
              style={{ height: "auto" }}
              initialValues={{
                remember: true,
              }}
            >
              {checkRoleAdminTax === false ?
                <div style={{ padding: "14px 0px" }}>
                  <label className='label-form'>Tên tổ chức</label>
                  <Input readOnly value={userInfo.customer_name} />
                </div>
                :
                <div style={{ padding: "14px 0px 0px" }}>
                  <Form.Item
                    name="id_customer"
                    label=<span className='label-form'>Tên tổ chức</span>
                    rules={[
                      {
                        required: true,
                        message: "Vui lòng chọn tên tổ chức",
                      },
                    ]}
                    key="id_customer"
                  >
                    <Select
                      placeholder="Chọn tên tổ chức"
                      style={{
                        width: "100%",
                      }}
                      key="type_document1"
                      allowClear
                      maxTagCount="responsive"
                      optionFilterProp="children"
                      showSearch
                      className='bank-statement'
                      onChange={chooseIdCustomer}
                    // optionLabelProp="data-label"
                    // getPopupContainer={getPopupContainer}
                    >
                      {dataTaxAgent.map((item, index) => (
                        <Option key={index} value={item.id}>
                          {item.name_organization}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              }
              <Form.Item
                name="form_import"
                label=<span className='label-form'>Dữ liệu chứng từ</span>
                className='form-email'
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn file"
                  }
                ]}
                key="formImport"
              >
                <Dragger
                  accept=".xls,.xlsx"
                  name="file"
                  onRemove={false}
                  openFileDialogOnClick={true}
                  fileList={fileList}
                  onChange={handleImport}
                  showUploadList={true}
                  maxCount={1}
                  beforeUpload={(file, FileList) => {
                    const isJpgOrPng =
                      !["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"].includes(file.type);
                    // const checkSize = file.size / 1024 / 1024 > 5;

                    return !isJpgOrPng ? false : Upload.LIST_IGNORE;
                  }}
                >
                  <img alt='' src={UploadExcelIcon}></img>
                  <p className="text-upload">
                    Click hoặc kéo thả để chọn file
                  </p>
                  <p className="text-hint-excel">
                    <em>( Hỗ trợ các định dạng : xlsx, xls)</em>
                  </p>
                </Dragger>
              </Form.Item>
              <span className="text-upload">Dung lượng tải lên tối đa : 50 MB</span>

              {screenMobile === false ?
                <Form.Item>
                  <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      // variant="contained"
                      // type="success"
                      htmlType="button"
                      onClick={closeModalUploadExcel}
                      className='text-btn design-btn-cancel'
                      style={{ width: "130px" }}
                    >
                      Hủy
                    </Button>
                    <Button
                      variant="contained"
                      type="success"
                      htmlType="submit"
                      className='text-btn design-btn-submit'
                      style={{ width: "130px" }}
                    >
                      Định khoản
                    </Button>
                  </Col>
                </Form.Item>
                :
                <Form.Item>
                  <Row style={{ display: "flex", justifyContent: "flex-end", marginTop: "5%" }}>
                    <Col span={12}>
                      <Button
                        // variant="contained"
                        // type="success"
                        htmlType="button"
                        onClick={closeModalUploadExcel}
                        className='text-btn design-btn-cancel'
                        style={{ width: "98%" }}
                      >
                        Hủy
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        variant="contained"
                        type="success"
                        htmlType="submit"
                        className='text-btn design-btn-submit'
                        style={{ width: "98%", float: "right" }}
                      >
                        Định khoản
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              }
            </Form>
          </Spin>
        </Modal>


        <div style={{ width: "100%", marginTop: screenMobile === false ? "1%" : "3%", position: "absolute", height: "50vh" }}>
          <Container
            disableGutters
            maxWidth="false"
            component="main"
            className="ContainerHome"
            style={{ maxHeight: "77vh" }}
          >
            <Typography
              variant="button"
              align="left"
              color="text.primary"
              gutterBottom
              sx={{ width: "100%" }}
            >
              {loading === false ? (
                <TableContainer
                  component={Paper}
                  sx={{ maxHeight: "77vh", boxShadow: "none" }}
                  className="tableContainerImport"
                >
                  <Table
                    sx={{
                      minWidth: 1000,
                    }}
                    stickyHeader
                    aria-label="sticky table"
                    size="small"
                    // onPaste={handlePaste}
                    className={classes.table}
                    style={{ width: "100%", border: "hidden" }}
                  >
                    <>
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCell
                            align="center"
                            padding="8px"
                            width={50}
                          >
                            Ngày hạch toán
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            padding="8px"
                            width={50}
                          >
                            Ngày chứng từ
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            padding="8px"
                            width={30}
                          >
                            Số chứng từ
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            padding="8px"
                            width={200}
                          >
                            Diễn giải
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            padding="8px"
                            width={50}
                          >
                            Tài khoản
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            padding="8px"
                            width={50}
                          >
                            Tài khoản đối ứng
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            padding="8px"
                            width={50}
                          >
                            Phát sinh nợ
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            padding="8px"
                            width={50}
                          >
                            Phát sinh có
                          </StyledTableCell>
                          {/* <StyledTableCell align="center" padding="8px">
                              Thao tác
                            </StyledTableCell> */}
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {dataOutput.length !== 0 ? (
                          dataOutput.map((item, index) => (
                            <>
                              <StyledTableRow key={index}>
                                <StyledTableCell>
                                  {item["Ngay_HT"]}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {item["Ngay_HD"]}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {item["Số HD"]}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Tooltip
                                    title={<span style={{ fontFamily: " Roboto, Helvetica, Arial, sans-serif", fontSize: 10 }}>{item["Diễn giải"]}</span>}
                                    placement="top-end"
                                  >
                                    <p className="dien-giai-hoaDon">
                                      {item["Diễn giải"]}
                                    </p>
                                  </Tooltip>
                                </StyledTableCell>
                                <StyledTableCell>
                                  {item["Nợ"]}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {item["Có"]}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {item["Phát sinh nợ"]}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {item["Phát sinh có"]}
                                </StyledTableCell>
                                {/* <StyledTableCell>
                                    <IconButton
                                      onClick={() =>
                                        showModalEditTable(item, index)
                                      }
                                    >
                                      <ModeEditOutlineOutlined />
                                    </IconButton>
                                  </StyledTableCell> */}
                              </StyledTableRow>
                            </>
                          ))
                        ) : (
                          <>
                            <TableRow>
                              <StyledTableCell>
                                <p style={{ color: "rgb(0 0 0 / 44%)" }}>
                                  04/12/2021 08:50
                                </p>
                              </StyledTableCell>

                              <StyledTableCell>
                                <p style={{ color: "rgb(0 0 0 / 44%)" }}>
                                  04/12/2021 08:50
                                </p>
                              </StyledTableCell>

                              <StyledTableCell>
                                <p style={{ color: "rgb(0 0 0 / 44%)" }}>
                                  DD060044/7
                                </p>
                              </StyledTableCell>

                              <StyledTableCell>
                                <Tooltip
                                  title="THANH TOAN PHI SU DUNG DICH VU PHAN MEM GOOGLE
                                    WORKSPACE-TEN MIEN ROBOTICS-BPO.NET"
                                  placement="top-end"
                                >
                                  <p
                                    className="dien-giai"
                                    style={{ color: "rgb(0 0 0 / 44%)" }}
                                  >
                                    THANH TOAN PHI SU DUNG DICH VU PHAN MEM
                                    GOOGLE WORKSPACE-TEN MIEN ROBOTICS-BPO.NET
                                  </p>
                                </Tooltip>
                              </StyledTableCell>

                              <StyledTableCell>
                                <p style={{ color: "rgb(0 0 0 / 44%)" }}>
                                  331
                                </p>
                              </StyledTableCell>

                              <StyledTableCell>
                                <p style={{ color: "rgb(0 0 0 / 44%)" }}>
                                  112
                                </p>
                              </StyledTableCell>

                              <StyledTableCell>
                                <p style={{ color: "rgb(0 0 0 / 44%)" }}>
                                  24,200,000,000.00
                                </p>
                              </StyledTableCell>

                              <StyledTableCell>
                                <p style={{ color: "rgb(0 0 0 / 44%)" }}>
                                  24,200,000.00
                                </p>
                              </StyledTableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </>
                  </Table>
                </TableContainer>
              ) : null}
              {loading === true ? (
                <>
                  <Fade in={loading} unmountOnExit>
                    <div
                      style={{
                        width: "100%",
                        height: 60,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <Hypnosis color="#058df4" width="40px" height="40px" /> */}
                    </div>
                  </Fade>
                </>
              ) : null}
            </Typography>
          </Container>
        </div>
      </div>

      {messageError === "EXCEL_001" ? (
        <Excel001
          setIsOpenModalExcel001={setIsOpenModalExcel001}
          isOpenModalExcel001={isOpenModalExcel001}
          infoError={infoError}
          typeDocument={typeDocument}
        />
      ) : null}

      {messageError === "EXCEL_005" ? (
        <Excel005
          setIsOpenModalExcel005={setIsOpenModalExcel005}
          isOpenModalExcel005={isOpenModalExcel005}
          inforError={inforError}
        />
      ) : null}

      {messageError === "EXCEL_006" ? (
        <Excel006
          setIsOpenModalExcel006={setIsOpenModalExcel006}
          isOpenModalExcel006={isOpenModalExcel006}
          inforError={inforError}
        />
      ) : null}

      {messageError !== "EXCEL_001" &&
        messageError !== "EXCEL_005" &&
        messageError !== "EXCEL_006" ? (
        <ErrorHD
          isOpenErrorHD={isOpenErrorHD}
          setIsOpenErrorHD={setIsOpenErrorHD}
          messageError={messageError}
        />
      ) : null}
      {isOpenProgress === true ? (
        <ProgressHoaDon
          isOpenProgress={isOpenProgress}
          setIsOpenProgress={setIsOpenProgress}
          progressProcessing={progressProcessing}
          nameImport={nameImport}
          isOpenScanningFile={isOpenScanningFile}
          setIsOpenScanningFile={setIsOpenScanningFile}
        />
      ) : null}
    </Container>
  )
}


const ProgressHoaDon = ({
  isOpenProgress,
  setIsOpenProgress,
  progressProcessing,
  nameImport,
  isOpenScanningFile,
  setIsOpenScanningFile,
}) => {
  const handleCancel = () => {
    setIsOpenProgress(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsOpenScanningFile(false);
    }, 5000);
  }, [isOpenProgress]);

  useEffect(() => {
    if (isOpenProgress === true) {
      localStorage.setItem("openProgressHD", "true");
    } else {
      localStorage.setItem("openProgressHD", "false");
    }
  }, []);

  return (
    <>
      <Modal
        open={isOpenProgress}
        onCancel={handleCancel}
        footer={null}
        width={600}
        closable={false}
        style={{ marginTop: "5%" }}
        className='modal-accounting-statement'
      >
        {isOpenScanningFile === false ? (
          <>
            <h3 style={{ textAlign: "center", margin: 0 }}>Tên file: {nameImport}</h3>
            <div
              style={{
                marginTop: "2%",
                display: "flow-root"
              }}
            >
              <span className='text-title-accounting'>Đang định khoản</span>
              <Progress
                percent={progressProcessing === "NaN" ? 0 : progressProcessing}
                status="active"
                style={{ width: "100%" }}
              />
              {progressProcessing < 100 ?
                <span className='text-progress'>{progressProcessing}%<span style={{ fontWeight: 500 }}> complete</span></span>
                :
                <span className='text-progress'>DONE</span>
              }
            </div>
          </>
        ) : (
          <>
            <h2 style={{ textAlign: "center", margin: 0 }}>Đang scan</h2>
            <img src={ScanningFileGIF} width={"100%"} height={150} alt=""></img>
          </>
        )}
      </Modal>
    </>
  );
};
export default AccountingInvoice