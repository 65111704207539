import { useEffect, useState } from 'react'
import "./InforUserRoleAdmin.css"
import { Container } from '@mui/material'
import { Button, Col, Form, Input, Modal, Row, Select, Table, Tag } from 'antd'
import { GetInforUser, GetListTaxAgent, LockUserApi, PostInforUser } from '../../../../../api/manageInforUser'
import AddIcon from "./../../../../../images/AddIcon.svg"
import { useSelector } from 'react-redux'
import { openNotificationSweetAlert } from '../../../../../Function'
import ErrorImage from "../../../../../images/ErrorNotifiIcon.svg"
import SuccessImage from "../../../../../images/SuccessNotiIcon.svg"
import DetailIcon from "../../image/ViewIcon.svg"
import EditIcon from "../../image/EditIcon.svg"
import LockIcon from "../../image/LockIcon.svg"
import UnlockIcon from "../../image/UnlockIcon.svg"
import ModalViewDetail from '../modal/ModalViewDetail'
import ModalEdit from '../modal/ModalEdit'
import Swal from 'sweetalert2'
import { SearchOutlined } from '@ant-design/icons'

const ToastSuccess = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
    customClass: {
        popup: "my-custom-popup",
        timerProgressBar: "my-custom-progress-bar-success", // Thêm class tùy chỉnh
    },
});

const { Option } = Select;

const InforUserRoleAdmin = () => {
    const userInfo = useSelector((state) => state.getUserInfo.userInfo);

    const [chooseId, setChooseId] = useState([])
    const [dataTaxAgent, setDataTaxAgent] = useState([])
    const [dataFilter, setDataFilter] = useState([])
    const [isOpenModalAdd, setIsOpenModalAdd] = useState(false)
    const [dataSource, setDataSource] = useState([])
    const [isOpenModalDetail, setIsOpenModalDetail] = useState(false)
    const [isOpenModalEdit, setIsOpenModalEdit] = useState(false)
    const [dataDetail, setDataDetail] = useState()
    const [idTaxAgent, setIdTaxAgent] = useState()
    const [valueSearch, setValueSearch] = useState("")
    const [pager, setPager] = useState({
        pageSize: 10,
        count: 0,
        current: 1,
    });
    const [nameCompany, setNameCompany] = useState("")

    const fetchListTaxAgent = () => {
        GetListTaxAgent().then(res => {
            setChooseId(res.data[0].id)
            setNameCompany(res.data[0].name_organization)
            setIdTaxAgent(res.data[0].taxagent_user_id)
            fetchListUser({ id_customer: res.data[0].id, search: "", page: 1, page_size: pager.pageSize })
            setDataTaxAgent(res.data)
            setDataFilter(res.data)
        }).catch(err => {
            console.log(err)
        })
    }

    const fetchListUser = (params = {}) => {
        GetInforUser(params).then(res => {
            setPager({
                current: params.page,
                pageSize: params.page_size,
                count: res.data.count,
            });
            setDataSource(res.data.results)
        }).catch(err => {
            console.log(err)
        })
    }

    const searchUser = (e) => {
        setValueSearch(e.target.value)
        const id = setInterval(() => {
            fetchListUser({ id_customer: chooseId, search: e.target.value, page: 1, page_size: pager.pageSize });
            clearInterval(id);
        }, 300);
        return () => clearInterval(id);
    }

    const FetchInforMaster = (value) => {
        fetchListUser({ id_customer: value.id, search: "", page: 1, page_size: pager.pageSize })
        setValueSearch("")
        setChooseId(value.id)
        setIdTaxAgent(value.taxagent_user_id)
        setNameCompany(value.name_organization)
    }

    useEffect(() => {
        fetchListTaxAgent();
    }, []);

    const listDataTaxAgent = () => {
        return dataFilter.map(item => (
            <Tag key={item.id} onClick={() => FetchInforMaster(item)} style={{ height: "fit-content", cursor: "pointer", width: "100%", whiteSpace: "wrap", padding: "4px 7px" }} color={chooseId === item.id ? "processing" : "default"}>{item.name_organization}</Tag>
        ))
    }
    const showModalAdd = () => {
        setIsOpenModalAdd(true)
    }

    const columns = [
        {
            title: 'STT',
            dataIndex: 'stt',
            key: 'name',
            align: "center",
            ellipsis: true,
            width: 50,
            render: (value, item, index) =>
                index + 1 + (pager.current - 1) * pager.pageSize,
        },
        {
            title: 'Tên tài khoản',
            dataIndex: 'username',
            key: 'username',
            align: "center",
            ellipsis: true,

        },

        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            align: "center",
            ellipsis: true,
        },

        {
            title: 'Vai trò',
            dataIndex: 'role_user_id',
            key: 'role_user_id',
            align: "center",
            ellipsis: true,
            render: (text, record, index) => showText(text)
        },

        {
            title: 'Thao tác',
            dataIndex: 'Action',
            key: 'Action',
            align: "center",
            ellipsis: true,
            width: 170,
            render: (text, record, index) => {
                return (
                    <div style={{ columnGap: "1.5ch", justifyContent: "center", alignItems: "center", display: "flex" }}>
                        <button className='disable-button' aria-label='btn-detail' onClick={() => openModalDetail(record)}><img style={{ cursor: "pointer" }} src={DetailIcon} alt=''></img></button>
                        {record.is_active === true ?
                            <button className='disable-button' aria-label='btn-edit' onClick={() => openModalEdit(record)}><img style={{ cursor: "pointer" }} alt='' src={EditIcon}></img></button>
                            :
                            <img style={{ cursor: "not-allowed" }} alt='' src={EditIcon}></img>
                        }
                        {userInfo.id !== record.id ?
                            <button className='disable-button' aria-label='btn-lock-account' onClick={() => lockUser(record)}><img style={{ cursor: "pointer" }} alt='' src={record.is_active === true ? UnlockIcon : LockIcon}></img></button>
                            :
                            <img style={{ opacity: 0 }} alt='' src={record.is_active === true ? UnlockIcon : LockIcon}></img>}
                    </div>
                )
            }
        },
    ];

    const openModalDetail = (record) => {
        setDataDetail(record)
        setIsOpenModalDetail(true)
    }

    const openModalEdit = (record) => {
        setDataDetail(record)
        setIsOpenModalEdit(true)
    }

    const lockUser = (record) => {
        let FormData = require("form-data");
        let data = new FormData();
        data.append("id_user", record.id)
        data.append("is_active", !record.is_active)
        LockUserApi(data).then(res => {
            if (!record.is_active === false) {
                ToastSuccess.fire({
                    icon: "success",
                    title: "Khóa tài khoản thành công",
                });
            } else {
                ToastSuccess.fire({
                    icon: "success",
                    title: " Mở khóa tài khoản thành công",
                });
            }
            fetchListUser(
                {
                    id_customer: record.customer_user_id,
                    search: "",
                    page: pager.current,
                    page_size: pager.pageSize,
                }
            )
        }).catch(err => {
            console.log(err)
        })
    }

    const showText = (text) => {
        if (text === 1) {
            return "Quản lý / Doanhh nghiệp"
        } else if (text === 2) {
            return "Nhân viên"
        }
        else if (text === 3) {
            return "Quản lý vận hành"
        }
        else if (text === 4) {
            return "Đại lý thuế"
        }
    }

    const handleChange = (pagination) => {
        const currentPager = { ...pager };
        currentPager.current = pagination.current;
        currentPager.pageSize = 10;
        setPager({ ...currentPager });
        fetchListUser(
            {
                id_customer: chooseId,
                search: valueSearch,
                page: pagination.current,
                page_size: pagination.pageSize,
            }
        )
    };

    return (
        <Container maxWidth="false" className='container-editOrView' style={{ width: "97%" }}>
            <div className='bg-all-infor-user' style={{ overflowY: "auto" }}>
                <Row style={{ width: "100%" }}>
                    <Col span={6}>
                        <div className='list-user' style={{ height: "78vh", marginLeft: 5 }}>
                            <div className='box-search-customer'>
                                <Input style={{ width: "90%" }} placeholder='Tìm tên doanh nghiệp'></Input>
                            </div>
                            <div style={{ rowGap: "1ch", paddingTop: "3%", display: "grid" }}>
                                {listDataTaxAgent()}
                            </div>
                        </div>
                    </Col>
                    <Col span={17} offset={1}>
                        <Row>
                            <Col span={16}>
                                <Input value={valueSearch} allowClear onChange={searchUser} suffix={<SearchOutlined />} className='search-user' placeholder='Tìm kiếm người dùng'></Input>
                            </Col>
                            <Col span={8}>
                                <Button className='btn-invoice-all' onClick={showModalAdd} style={{ background: "rgba(58, 183, 176, 0.15)", float: "right" }}><img alt='' src={AddIcon}></img></Button>
                            </Col>
                        </Row>
                        <div style={{ width: "100%", position: "relative", paddingTop: "0.5%" }}>
                            <Table
                                size='middle'
                                columns={columns}
                                dataSource={dataSource}
                                onChange={handleChange}
                                style={{ marginTop: "1%", fontSize: 14, position: "absolute", width: "100%" }}
                                className='table-invoice-all'
                                bordered

                                scroll={{
                                    // y: "42vh",
                                    x: 1000,
                                }}
                                pagination={{
                                    current: pager.current,
                                    pageSize: pager.pageSize,
                                    total: pager.count,
                                    showSizeChanger: false,
                                }}
                            />
                        </div>
                        {isOpenModalAdd === true ?
                            <ModalAddUser
                                open={isOpenModalAdd}
                                setIsOpenModalAdd={setIsOpenModalAdd}
                                dataTaxAgent={dataTaxAgent}
                                fetchListUser={fetchListUser}
                                pager={pager}
                            />
                            : null}
                        {isOpenModalDetail === true ?
                            <ModalViewDetail
                                open={isOpenModalDetail}
                                setIsOpenModalDetail={setIsOpenModalDetail}
                                dataDetail={dataDetail}
                                nameCompany={nameCompany}
                            />
                            : null}
                        {isOpenModalEdit === true ?
                            <ModalEdit
                                open={isOpenModalEdit}
                                setIsOpenModalEdit={setIsOpenModalEdit}
                                dataTaxAgent={dataTaxAgent}
                                dataDetail={dataDetail}
                                nameCompany={nameCompany}
                                fetchListUser={fetchListUser}
                                idTaxAgent={idTaxAgent}
                                pager={pager}
                            />
                            : null}
                    </Col>
                </Row>
            </div>
        </Container>
    )
}
const ModalAddUser = ({ open, setIsOpenModalAdd, dataTaxAgent, fetchListUser, pager }) => {
    const [form] = Form.useForm()
    const userInfo = useSelector((state) => state.getUserInfo.userInfo);
    const [loadingBtnSubmit, setLoadingBtnSubmit] = useState(false)
    const roleAdminTax = userInfo.role_id === 4

    const mockRule = !roleAdminTax ?
        [
            {
                id: 1,
                name: "Quản lý / Doanh nghiệp"
            },
            {
                id: 2,
                name: "Nhân viên"
            },
            {
                id: 4,
                name: "Đại lý thuế"
            }
        ]

        :

        [
            {
                id: 1,
                name: "Quản lý / Doanh nghiệp"
            },
            {
                id: 2,
                name: "Nhân viên"
            },
        ]

    const handleCancel = () => {
        form.resetFields();
        setIsOpenModalAdd(false)
    }

    const onFinish = (values) => {
        setLoadingBtnSubmit(true)
        let FormData = require("form-data");
        let data = new FormData();
        const arrCompany = values.id_customer.split(",")
        data.append("id_customer", arrCompany[0]);
        data.append("username", values.username);
        data.append("email_registration", values.email_registration);
        data.append("numberphone", values.numberphone);
        data.append("work_position", values.work_position);
        data.append("role_user_id", values.role_user_id);
        data.append("taxagent_user_id", arrCompany[1]);
        PostInforUser(data).then(res => {
            setLoadingBtnSubmit(false)
            setIsOpenModalAdd(false)
            openNotificationSweetAlert(SuccessImage, res.data.message)
            fetchListUser(
                {
                    id_customer: arrCompany[0],
                    search: "",
                    page: pager.current,
                    page_size: pager.pageSize,
                }
            )

        }).catch(err => {
            setLoadingBtnSubmit(false)
            openNotificationSweetAlert(ErrorImage, err.data.message)
        })
    }

    return (
        <Modal className='modal-add-customer' width={800} open={open} onCancel={handleCancel} footer={false}>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <span style={{ margin: 0, fontSize: 30, fontWeight: "bold", color: "#058DF4" }}>Tạo mới tài khoản</span>
            </div>
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
                initialValues={{
                    remember: true,
                }}
                className='text-label-add-company'
                style={{ paddingTop: "1%" }}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Thuộc đại lý/ Công ty mẹ"
                            name={"id_customer"}
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng chọn đại lý/ Công ty mẹ",
                                },
                            ]}
                        >
                            {/* {!roleAdminTax ? */}
                            <Select
                                id="code_district"
                                size={"middle"}
                                allowClear
                                className="SelectTTDN"
                                optionFilterProp="children"
                                placeholder="Chọn đại lý/ Công ty mẹ"
                            >
                                {dataTaxAgent.map((item, index) => (
                                    <Option key={item.id} value={`${item.id},${item.taxagent_user_id}`}>
                                        {item.name_organization}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Tài khoản"
                            name='username'
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập tài khoản!",
                                },
                            ]}
                        >
                            <Input
                                placeholder='Nhập tài khoản'
                                onBlur={(e) => {
                                    form.setFieldsValue({ username: e.target.value.trim() });
                                }}
                            >
                            </Input>
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={2}>
                        <Form.Item
                            label="Email đăng ký"
                            name='email_registration'
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập email đăng ký!",
                                },
                                {
                                    pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                    message: 'Email chưa đúng định dạng',
                                },
                            ]}
                        >
                            <Input
                                placeholder='Nhập Email'
                                onBlur={(e) => {
                                    form.setFieldsValue({ email_registration: e.target.value.trim() });
                                }}
                            >
                            </Input>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Số điện thoại"
                            name='numberphone'
                            rules={[
                                {
                                    pattern: /^\d+$/,
                                    message: "Số điện thoại chỉ được nhập bằng chữ số!",
                                },
                                {
                                    max: 12,
                                    message: "Chỉ được nhập tối đa 12 số",
                                },
                            ]}
                        >
                            <Input
                                placeholder='Nhập số điện thoại'
                                onBlur={(e) => {
                                    form.setFieldsValue({ numberphone: e.target.value.trim() });
                                }}
                            >
                            </Input>
                        </Form.Item>
                    </Col>
                    <Col span={11} offset={2}>
                        <Form.Item
                            label="Vị trí"
                            name='work_position'
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng nhập vị trí!",
                                },
                            ]}
                        >
                            <Input
                                placeholder='Nhập vị trí'
                                onBlur={(e) => {
                                    form.setFieldsValue({ work_position: e.target.value.trim() });
                                }}
                            >
                            </Input>
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            label="Vai trò"
                            name='role_user_id'
                            rules={[
                                {
                                    required: true,
                                    message: "Vui lòng chọn vai trò!",
                                },
                            ]}
                        >
                            <Select
                                id="code_district"
                                size={"middle"}
                                allowClear
                                className="SelectTTDN"
                                optionFilterProp="children"
                                placeholder="Chọn vai trò"
                            >
                                {mockRule.map((item, index) => (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button

                        htmlType="button"
                        className='text-btn design-btn-cancel'

                        onClick={handleCancel}
                    >
                        Hủy
                    </Button>
                    <Button
                        // variant="contained"
                        // type="success"
                        htmlType="submit"
                        // disabled={loadingButtonSave}
                        className='text-btn design-btn-submit'
                        loading={loadingBtnSubmit}
                    >
                        Lưu
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default InforUserRoleAdmin