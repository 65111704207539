import { Container, Fade, TableBody, TableCell, TableContainer, Table, TableHead, TableRow, Tooltip, Typography, tableCellClasses } from '@mui/material';
import { Button, Col, Form, Input, Modal, Progress, Row, Select, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import DownloadIcon from "./../../../../../images/image_manage_docs/DownloadInvoice.svg"
import "./AccountingStatement.css"
import { localhost } from '../../../../../server';
import { read, utils } from 'xlsx';
import { GetDeleteFile, GetFileDownloadApi, GetListManageBankURLApi, GetListSPNHApi } from '../../../../../api/usersApi';
import ScanningFileGIF from "./../../../../../images/ScanFile.gif"
import UploadIcon from "./../../../../../images/image_manage_docs/UploadInvoice.svg"

import UploadExcelIcon from "../../../../../images/image_manage_docs/UploadExcel.svg"
import styled from '@emotion/styled';
import { makeStyles } from '@mui/styles';
import Excel001 from '../error/Excel001';
import Excel003 from '../error/Excel003';
import Excel005 from '../error/Error005';
import Excel008 from '../error/Error008';
import Excel002 from '../error/Excel002';
import OutOfRequests from '../error/OutOfRequests';
import { useSelector } from 'react-redux';
import { HandleStatementApi } from '../../../../../api/StatementApi';
import { openNotificationSweetAlert, screenMobile } from '../../../../../Function';
import ErrorImage from "../../../../../images/ErrorNotifiIcon.svg"
import { GetListTaxAgent } from '../../../../../api/manageInforUser';

const { Option } = Select;


function formatDate(inputDate) {
  const year = inputDate.slice(0, 2);
  const month = inputDate.slice(2, 4);
  const day = inputDate.slice(4, 6);

  return `${day}/${month}/20${year}`;
}


const { Dragger } = Upload;
const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 240,
  },
  drawerContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  table: {
    minWidth: 650,
    "& .MuiTableCell-root": {
      border: "1px solid #D1CFD7",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif"
    },
  },
  tooltip: {
    fontSize: "20px",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif"
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e5e5f3",
    color: "#2D3459",
    padding: 8,
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: 600,
    textAlign: "center",
    textOverflow: "ellipsis",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: 9,
    textOverflow: "ellipsis",
    textAlign: "center",
    width: 200,
    textTransform: "none",
    whiteSpace: "nowrap"
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    minWidth: 150,
    textTransform: "none",
  },
}));

function AccountingStatement() {
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);

  useEffect(() => {
    let data = []
    for (let i = 0; i < 10; i++) {
      data.push({
        key: i,
        ngay_hach_toan: '11/12/2023',
        ngay_chung_tu: '20/12/2023',
        so_chung_tu: '123465798',
        dien_giai: 'Nước uống đóng chai',
        tai_khoan: '642',
        tai_khoan_doi_ung: '331',
        phat_sinh_no: '4,200,000',
        phat_sinh_co: '4,000,000',
      })
    }
  }, []);

  const classes = useStyles();
  const [formImport] = Form.useForm();
  const [fileImport, setFileImport] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenModalExcel002, setIsOpenModalExcel002] = useState(false);
  const [isOpenModalExcel003, setIsOpenModalExcel003] = useState(false);
  const [isOpenModalExcel005, setIsOpenModalExcel005] = useState(false);
  const [isOpenModalExcel008, setIsOpenModalExcel008] = useState(false);
  const [isOpenModalOutOfRequests, setIsOpenModalOutOfRequests] =
    useState(false);
  const [isOpenProgress, setIsOpenProgress] = useState(false);
  const [progressTranslating, setProgressTranslating] = useState(0);
  const [progressProcessing, setProgressProcessing] = useState(0);
  const [dataOutput, setDataOutput] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpenModalExcel001, setIsOpenModalExcel001] = useState(false);
  const [statusFile, setStatusFile] = useState("");
  const [messageError, setMessageError] = useState("");
  const [infoError, setInfoError] = useState("");
  const [inforError, setInforError] = useState("");

  const [bankList, setBankList] = useState([]);
  const [nameImport, setNameImport] = useState("");
  const [isOpenScanningFile, setIsOpenScanningFile] = useState(false);
  const [phimTatDownload, setPhimTatDownload] = useState("");
  const [namePhimTatDownload, setNamePhimTatDownload] = useState("");
  const [headerName, setHeaderName] = useState([
    "Ngày hạch toán",
    "Ngày chứng từ",
    "Số chứng từ",
    "Tên nhà cung cấp/ Khách hàng",
    "Mã nhà cung cấp/ Khách hàng",
    "Diễn giải",
    "Tài khoản",
    "Tài khoản đối ứng",
    "Phát sinh nợ",
    "Phát sinh có",
  ])
  const [dateNHT, setDateNHT] = useState("");
  const checkRoleAdminTax = userInfo.role_id === 4 || userInfo.role_id === 3
  const [dataTaxAgent, setDataTaxAgent] = useState([])

  const fetchListDataBankAC = () => {
    GetListManageBankURLApi().then((res) => {
      setBankList(res.data);
    });
  };

  useEffect(() => {
    fetchListDataBankAC();
  }, []);

  const handleOpenModal = () => {
    if (statusFile === "Processing") {
      setIsOpenProgress(true);
    } else {
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setFileImport([])

  };

  useEffect(() => {
    if (isModalOpen === false) {
      formImport.resetFields()
    }
  }, [isModalOpen]);

  const readFileTxtTranslating = async (file) => {
    const fileTxt = localhost + "/" + file.path_translating;
    const response = await fetch(fileTxt);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const number = binaryString.split("/");
      const progress = (
        (parseInt(number[0]) / parseInt(number[1])) *
        100
      ).toFixed(2);
      setProgressTranslating(progress);
      if (number[0] === 0) {
        setIsOpenScanningFile(true);
      }
      if (number[0] > 0) {
        setIsOpenScanningFile(false);
      }
      if (progress === "100.00") {
        readFileTxtProcessing(file);
      }
    };
    reader.readAsBinaryString(blob);
  };

  const readFileTxtProcessing = async (file) => {
    const fileTxt = localhost + "/" + file.path_processing;
    const response = await fetch(fileTxt);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const number = binaryString.split("/");
      const progress = (
        (parseInt(number[0]) / parseInt(number[1])) *
        100
      ).toFixed(2);
      setProgressProcessing(progress);
    };
    reader.readAsBinaryString(blob);
  };

  const fetchListData = (id) => {
    GetListSPNHApi({
      name_type: "SPNH",
      id_customer: id
    })
      .then((res) => {
        setStatusFile(res.data.data.status);
        if (res.data.data.name_import) {
          const index = res.data.data.name_import.indexOf("__");
          const name = res.data.data.name_import.slice(index + 2);
          setNameImport(name);
        }
        if (res.data.data.status === "Available") {
          localStorage.setItem("checkError", "false");
          setIsOpenProgress(false);
          localStorage.removeItem("idCusStatement")
          localStorage.setItem("openProgress", "false");
          fetchDataOutput(res.data.data);
        }
        if (res.data.data.status === "Processing") {
          readFileTxtTranslating(res.data.data);
        }

        if (res.data.data.status === "Error") {
          setIsOpenProgress(false);
          localStorage.setItem("openProgress", "false");
          fetchDataOutputError(res.data.data);
        }
        if (res.data.data === "") {
          setIsOpenProgress(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchDataOutput = async (file) => {
    const fileTxt = localhost + "/" + file.path_export;
    const response = await fetch(fileTxt);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = read(binaryString, { type: "binary" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetData = utils.sheet_to_json(worksheet, {
        header: 1,
        blankrows: true,
      });

      const extractedDate = file.name_export.split("_")[0];
      const formattedDate = formatDate(extractedDate);
      setDateNHT(formattedDate);

      const accountRowIndex = worksheetData.indexOf(worksheetData[0]);
      const header = worksheetData[accountRowIndex];
      let ls_value = [];
      for (let iRow of worksheetData.slice(accountRowIndex + 1)) {
        let dict_value = {};
        for (let [indexH, valueH] of header.entries()) {
          // gán value cho các key là header
          dict_value[valueH] = iRow[indexH];
        }
        ls_value.push(dict_value);
      }

      const filterData12Row = ls_value.splice(0, 12);

      setDataOutput(filterData12Row);
      setProgressProcessing(0);
      setProgressTranslating(0);
    };
    reader.readAsBinaryString(blob);
  };

  const fetchDataOutputError = async (file) => {
    const fileTxt = localhost + "/" + file.path_export;
    const response = await fetch(fileTxt);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const workbook = read(binaryString, { type: "binary" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetData = utils.sheet_to_json(worksheet, {
        header: 1,
        blankrows: true,
      });
      let message = "";
      let info1 = "";
      const messageStart = "Info:";
      const messageEnd = ", Image_Url:";

      if (worksheetData.slice(1).toString().includes(",")) {
        message = worksheetData.slice(1).toString().split(",")[1].split(":")[1];
        info1 = worksheetData
          .slice(1)
          .toString()
          .split(",")[2]
          .split(":")
          .slice(1)
          .join(":");

        const startIndex =
          worksheetData.slice(1).toString().indexOf(messageStart) +
          messageStart.length;

        const endIndex = worksheetData.slice(1).toString().indexOf(messageEnd);

        if (startIndex !== -1 && endIndex !== -1) {
          const extractedMessage = worksheetData
            .slice(1)
            .toString()
            .substring(startIndex, endIndex);
          setInfoError(extractedMessage);
        } else {
        }
      } else {
        message = worksheetData.slice(1).toString();
      }

      if (message === "Excel_002") {
        setIsOpenModalExcel002(true);
      }
      if (message === "Excel_003") {
        setIsOpenModalExcel003(true);
      }
      if (message === "Excel_005") {
        setIsOpenModalExcel005(true);
      }
      if (message === "Excel_008") {
        setIsOpenModalExcel008(true);
      }

      if (message === "Excel_001") {
        setIsOpenModalExcel001(true);
      }

      setMessageError(message);
      setInforError(info1);
    };
    reader.readAsBinaryString(blob);
  };

  const fetchListTaxAgent = () => {
    GetListTaxAgent().then(res => {
      setDataTaxAgent(res.data)
    }).catch(err => {
      console.log(err)
    })
  }


  useEffect(() => {
    if (checkRoleAdminTax === true) {
      fetchListTaxAgent()
    }
    const id = setInterval(() => {
      if (
        localStorage.getItem("openProgress") === "true" &&
        localStorage.getItem("checkError") === "false"
      ) {
        const idCustomer = localStorage.getItem("idCusStatement")
        if (checkRoleAdminTax) {
          fetchListData(idCustomer);
          fetchFileDownload(idCustomer);
        } else {
          fetchListData(userInfo.customer_id);
          fetchFileDownload(userInfo.customer_id);
        }
      }
    }, 2000);
    return () => clearInterval(id);
  }, []);

  useEffect(() => {
    localStorage.setItem("checkError", "false");
    localStorage.setItem("openProgress", "false");

    setLoading(true);
    const fetchListDataFirst = () => {
      GetListSPNHApi({
        id_customer: userInfo.customer_id || 0,
        name_type: "SPNH",
      })
        .then((res) => {
          setLoading(false);
          setStatusFile(res.data.data.status);
          if (res.data.data.name_import) {
            const index = res.data.data.name_import.indexOf("__");
            const name = res.data.data.name_import.slice(index + 2);
            setNameImport(name);
          }
          if (res.data.data.status === "Available") {
            localStorage.setItem("checkError", "false");
            setIsOpenProgress(false);
            fetchDataOutput(res.data.data);
          }
          if (res.data.data.status === "Processing") {
            readFileTxtTranslating(res.data.data);
          }

          if (res.data.data.status === "Error") {
            setIsOpenProgress(false);
            localStorage.setItem("checkError", "true");
          }
        })
        .catch((err) => {
        });
    };

    fetchListDataFirst();
    fetchFileDownload(userInfo.customer_id);
  }, []);

  useEffect(() => {
    if (dataOutput.length > 0) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [dataOutput]);

  const handleImport = ($event) => {
    const files = $event.fileList;
    const file = $event.fileList[0];
    if (files.length) {
      setFileImport(files);
    }
    if (file === undefined) {
      setFileImport();
    }
  };
  const [messageUpdateFormat, setMessageUpdateFormat] = useState();

  const onFinishImport = (values) => {
    var FormData = require("form-data");
    var data = new FormData();
    data.append("file_upload", values.form_import.fileList[0].originFileObj);
    data.append("bank", values.bank);

    if (checkRoleAdminTax === true) {
      data.append("id_customer", values.id_customer);
    } else {
      data.append("id_customer", userInfo.customer_id);
    }

    const size = fileImport[0].size / 1024 / 1024 > 50

    if (size === false) {
      HandleStatementApi(data)
        .then((res) => {
          if (res.data.infor === "Update format") {
            setMessageUpdateFormat(res.data);
          }
          handleCloseModal();

          setIsOpenProgress(true);

          // }
          setProgressProcessing(0);
          setProgressTranslating(0);
          formImport.resetFields();
          fetchListData();
          setDataOutput([]);
          fetchFileDownload();
          setIsOpenScanningFile(true);
          localStorage.setItem("checkError", "false");
          localStorage.setItem("idCusStatement", values.id_customer)
        })
        .catch((err) => {
          setMessageError(err.data.message);
          setIsOpenModalOutOfRequests(true)
          setIsOpenModalExcel002(true);
          setIsOpenModalExcel003(true);
          setIsOpenModalExcel005(true);
          setIsOpenModalExcel008(true);
        });
    } else {
      openNotificationSweetAlert(ErrorImage, "Dung lượng file đã quá 50MB !!!")
    }

  };

  const fetchFileDownload = (id) => {
    GetFileDownloadApi({
      name_type: "SPNH",
      id_customer: id
    })
      .then((res) => {
        setPhimTatDownload(res.data.data.path_full);
        setNamePhimTatDownload(res.data.data.name_import);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.altKey && event.key === "d") {
        // Xử lý sự kiện khi nhấn tổ hợp phím Ctrl + C
        event.preventDefault(); // Ngăn chặn hành vi mặc định của trình duyệt (ví dụ: tìm kiếm trang)
        if (phimTatDownload) {
          downloadFile();
        } else {
        }
      }
    };

    const downloadFile = async () => {
      const fileUrl = localhost + "/" + phimTatDownload;
      // URL của file bạn muốn tải xuống
      if (phimTatDownload === undefined) {
        openNotificationSweetAlert(ErrorImage, "Thông tin tải xuống không tồn tại")
      } else {
        try {
          const response = await fetch(fileUrl);
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = namePhimTatDownload; // Tên file khi tải xuống
          link.click();
          URL.revokeObjectURL(url);
        } catch (error) {
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [phimTatDownload]);

  const download = async () => {
    const fileUrl = localhost + "/" + phimTatDownload;
    // URL của file bạn muốn tải xuống
    if (phimTatDownload === undefined) {
      openNotificationSweetAlert(ErrorImage, "Thông tin tải xuống không tồn tại")
    } else {
      try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = namePhimTatDownload; // Tên file khi tải xuống
        link.click();
        URL.revokeObjectURL(url);
      } catch (error) {
      }
    }
  };

  const fetchDeleteFile = () => {
    GetDeleteFile()
      .then((res) => {
        fetchListData();
        setDataOutput([]);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.ctrlKey && event.altKey && event.key === "x") {
        // Xử lý sự kiện khi nhấn tổ hợp phím Ctrl + C
        event.preventDefault(); // Ngăn chặn hành vi mặc định của trình duyệt (ví dụ: tìm kiếm trang)
        fetchDeleteFile();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <Container maxWidth="false" style={{ backgroundColor: "#fff", width: "97%", marginTop: "1%", height: "88vh" }}>
      <div style={{ width: "100%", position: "relative", paddingTop: "2%" }}>
        {screenMobile === false ?
          <Row>
            <Col span={8}></Col>
            <Col span={8} className='title-table-accounting-statement'>KẾT QUẢ ĐỊNH KHOẢN SAO KÊ</Col>
            <Col span={8} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button className='btn-invoice-all' onClick={download} style={{ marginRight: "3%", background: "rgba(50, 31, 219, 0.12)", border: "unset" }}><img alt='' src={DownloadIcon} style={{ marginRight: "3%" }}></img><span style={{ color: "rgb(85,69,225)", fontWeight: "bold" }}>TẢI XUỐNG</span></Button>
              <Button onClick={handleOpenModal} className='btn-invoice-all' style={{ background: "rgba(58, 183, 176, 0.15)", border: "unset" }}><img alt='' src={UploadIcon} style={{ marginRight: "3%" }}></img><span style={{ color: "rgb(58,183,176)", fontWeight: "bold" }}>TẢI LÊN</span></Button>
            </Col>
          </Row>
          :
          <Row style={{ rowGap: "1ch" }}>
            <Col span={24} className='title-table-accounting-statement'>KẾT QUẢ ĐỊNH KHOẢN SAO KÊ</Col>
            <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button className='btn-invoice-all' style={{ marginRight: "3%", background: "rgba(50, 31, 219, 0.12)", border: "unset" }}><img alt='' src={DownloadIcon}></img></Button>
              <Button onClick={handleOpenModal} className='btn-invoice-all' style={{ background: "rgba(58, 183, 176, 0.15)", border: "unset" }}><img alt='' src={UploadIcon}></img></Button>
            </Col>
          </Row>
        }
        <div style={{ width: "100%", position: "absolute", marginTop: screenMobile === false ? "1%" : "3%" }}>
          <Container
            disableGutters
            maxWidth="false"
            component="main"
            className="ContainerHome"
          >
            <Typography
              variant="button"
              align="left"
              color="text.primary"
              gutterBottom
              sx={{ width: "100%" }}
            >
              {loading === false ? (
                <TableContainer
                  // component={Paper}
                  sx={{ maxHeight: "77vh", boxShadow: "none" }}
                  className="tableContainerImport"
                >
                  <Table
                    sx={{ minWidth: 700 }}
                    stickyHeader
                    aria-label="sticky table"
                    size="small"
                    // onPaste={handlePaste}
                    className={classes.table}
                    style={{ width: "100%", border: "hidden" }}
                  >
                    {headerName.length > 0 ? (
                      <TableHead>
                        <StyledTableRow>
                          {headerName.map((column, index) => (
                            <StyledTableCell
                              style={{ fontSize: 14 }}
                              key={index}
                            >
                              {column}
                            </StyledTableCell>
                          ))}
                        </StyledTableRow>
                      </TableHead>
                    ) : (
                      <TableHead>
                        <TableRow>
                          <StyledTableCell width={300} style={{ fontSize: 14 }}>
                            <br />
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                    )}

                    {loading === true ? (
                      <TableRow>
                        <Fade in={loading} unmountOnExit>
                          <div
                            style={{
                              width: "100%",
                              height: 60,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            {/* <Hypnosis
                              color="#058df4"
                              width="40px"
                              height="40px"
                            /> */}
                          </div>
                        </Fade>
                      </TableRow>
                    ) : (
                      <TableBody>
                        {dataOutput.length ? (
                          dataOutput.map((item, index) => (
                            <>
                              <StyledTableRow key={index}>
                                <StyledTableCell>
                                  {dateNHT}
                                </StyledTableCell>

                                <StyledTableCell>
                                  {/* {dayjs(item["Ngày"]).format("DD/MM/YYYY")} */}
                                  {item["Ngày"]}
                                </StyledTableCell>

                                <StyledTableCell>
                                  {item["Số chứng từ"]}
                                </StyledTableCell>

                                <StyledTableCell>
                                  <Tooltip
                                    title={<span style={{ fontFamily: " Roboto, Helvetica, Arial, sans-serif", fontSize: 10 }}>{item["Tên đối tượng"]}</span>}
                                    placement="top-end"
                                  >
                                    <p className="text-name-customer">
                                      {item["Tên đối tượng"]}
                                    </p>
                                  </Tooltip>
                                </StyledTableCell>

                                <StyledTableCell>
                                  {item["Mã đối tượng"]}
                                </StyledTableCell>

                                <StyledTableCell>
                                  <Tooltip
                                    title={<span style={{ fontFamily: " Roboto, Helvetica, Arial, sans-serif", fontSize: 10 }}>{item["Diễn giải"]}</span>}
                                    placement="top-end"
                                  >
                                    <p className="dien-giai-hoaDon">
                                      {item["Diễn giải"]}
                                    </p>
                                  </Tooltip>
                                </StyledTableCell>

                                <StyledTableCell>{item["Nợ"]}</StyledTableCell>

                                <StyledTableCell>{item["Có"]}</StyledTableCell>

                                <StyledTableCell>
                                  {item["Phát sinh nợ"]}
                                </StyledTableCell>

                                <StyledTableCell>
                                  {item["Phát sinh có"]}
                                </StyledTableCell>

                                {/* <StyledTableCell>
                                  <IconButton
                                    onClick={() =>
                                      showModalEditTable(item, index)
                                    }
                                  >
                                    <ModeEditOutlineOutlined />
                                  </IconButton>
                                </StyledTableCell> */}
                              </StyledTableRow>
                            </>
                          ))
                        ) : (
                          <>
                            <TableRow>
                              <StyledTableCell>
                                <p style={{ color: "rgb(0 0 0 / 44%)" }}>
                                  04/12/2021 08:50
                                </p>
                              </StyledTableCell>

                              <StyledTableCell>
                                <p style={{ color: "rgb(0 0 0 / 44%)" }}>
                                  04/12/2021 08:50
                                </p>
                              </StyledTableCell>

                              <StyledTableCell>
                                <p style={{ color: "rgb(0 0 0 / 44%)" }}>
                                  DD060044/7
                                </p>
                              </StyledTableCell>

                              <StyledTableCell>
                                <Tooltip
                                  title="THANH TOAN PHI SU DUNG DICH VU PHAN MEM GOOGLE
                                    WORKSPACE-TEN MIEN ROBOTICS-BPO.NET"
                                  placement="top-end"
                                >
                                  <p
                                    className="khach-hang"
                                    style={{ color: "rgb(0 0 0 / 44%)" }}
                                  >
                                    THANH TOAN PHI SU DUNG DICH VU PHAN MEM
                                    GOOGLE WORKSPACE-TEN MIEN ROBOTICS-BPO.NET
                                  </p>
                                </Tooltip>
                              </StyledTableCell>

                              <StyledTableCell>
                                <p style={{ color: "rgb(0 0 0 / 44%)" }}>
                                  1231231
                                </p>
                              </StyledTableCell>

                              <StyledTableCell>
                                <Tooltip
                                  title="THANH TOAN PHI SU DUNG DICH VU PHAN MEM GOOGLE
                                    WORKSPACE-TEN MIEN ROBOTICS-BPO.NET"
                                  placement="top-end"
                                >
                                  <p
                                    className="dien-giai"
                                    style={{ color: "rgb(0 0 0 / 44%)" }}
                                  >
                                    THANH TOAN PHI SU DUNG DICH VU PHAN MEM
                                    GOOGLE WORKSPACE-TEN MIEN ROBOTICS-BPO.NET
                                  </p>
                                </Tooltip>
                              </StyledTableCell>

                              <StyledTableCell>
                                <p style={{ color: "rgb(0 0 0 / 44%)" }}>331</p>
                              </StyledTableCell>

                              <StyledTableCell>
                                <p style={{ color: "rgb(0 0 0 / 44%)" }}>112</p>
                              </StyledTableCell>

                              <StyledTableCell>
                                <p style={{ color: "rgb(0 0 0 / 44%)" }}>
                                  24,200,000,000.00
                                </p>
                              </StyledTableCell>

                              <StyledTableCell>
                                <p style={{ color: "rgb(0 0 0 / 44%)" }}>
                                  24,200,000.00
                                </p>
                              </StyledTableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              ) : null}
              {loading === true ? (
                <>
                  <Fade in={loading} unmountOnExit>
                    <div
                      style={{
                        width: "100%",
                        height: 60,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* <Hypnosis color="#058df4" width="40px" height="40px" /> */}
                    </div>
                  </Fade>
                </>
              ) : null}
            </Typography>
          </Container>
        </div>
      </div>

      <Modal open={isModalOpen} footer={false} onCancel={handleCloseModal} closable={false} width={screenMobile === false ? "40%" : "100%"}>
        <Row className='title-modal'>
          <span>Định khoản sao kê</span>
        </Row>

        <Form
          form={formImport}
          layout="vertical"
          autoComplete="off"
          onFinish={onFinishImport}
          style={{ height: "auto" }}
        // onFinishFailed={onFinishFailed}
        // validateMessages={validateMessages}
        // loading={loading}
        // className='text-label-manage-company'
        // initialValues={{
        //     remember: true,
        // }}

        >
          {checkRoleAdminTax === false ?
            <div style={{ padding: "14px 0px" }}>
              <label className='label-form'>Tên tổ chức</label>
              <Input readOnly value={userInfo.customer_name} />
            </div>
            :
            <div style={{ padding: "14px 0px 0px" }}>
              <Form.Item
                name="id_customer"
                label=<span className='label-form'>Tên tổ chức</span>
                rules={[
                  {
                    required: true,
                    message: "Vui lòng chọn tên tổ chức",
                  },
                ]}
                key="id_customer"
              >
                <Select
                  placeholder="Chọn tên tổ chức"
                  style={{
                    width: "100%",
                  }}
                  key="type_document1"
                  allowClear
                  maxTagCount="responsive"
                  optionFilterProp="children"
                  showSearch
                  className='bank-statement'

                // optionLabelProp="data-label"
                // getPopupContainer={getPopupContainer}
                >
                  {dataTaxAgent.map((item, index) => (
                    <Option key={index} value={item.id}>
                      {item.name_organization}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          }


          <Form.Item
            name="bank"
            label=<span className='label-form'>Tên ngân hàng</span>
            rules={[
              {
                required: true,
                message: "Vui lòng chọn ngân hàng",
              },
            ]}
            key="bank"
          >
            <Select
              placeholder="Chọn ngân hàng"
              style={{
                width: "100%",
              }}
              key="type_document1"
              allowClear
              maxTagCount="responsive"
              optionFilterProp="children"
              showSearch
              className='bank-statement'
            // optionLabelProp="data-label"
            // getPopupContainer={getPopupContainer}
            >
              {bankList.map((item, index) => (
                <Option key={item.id} value={item.code_bank}>
                  {item.code_bank !== "KHAC"
                    ? item.code_bank + " - " + item.name_bank
                    : item.name_bank}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="form_import"
            label=<span className='label-form'>Dữ liệu chứng từ</span>
            className='form-email'
            rules={[
              {
                required: true,
                message: "Vui lòng chọn file"
              }
            ]}
          >
            <Dragger
              accept=".xls,.xlsx"
              name="file"
              openFileDialogOnClick={true}
              fileList={fileImport}
              onChange={handleImport}
              showUploadList={true}
              maxCount={1}
              beforeUpload={(file, FileList) => {
                const isJpgOrPng =
                  !["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"].includes(file.type);
                // const checkSize = file.size / 1024 / 1024 > 5;
                if (isJpgOrPng) {
                  // openNotificationWithIcon(
                  //     "error",
                  //     "Lỗi",
                  //     `Sai định dạng file !`
                  // );
                  // return Upload.LIST_IGNORE;
                }
                // if (checkSize) {
                //   openNotificationWithIcon("error", "Lỗi", `Quá 5MB !`);
                // }

                return !isJpgOrPng ? false : Upload.LIST_IGNORE;
              }}
            >
              {/* <p className="ant-upload-drag-icon"> */}
              <img alt='' src={UploadExcelIcon}></img>
              {/* </p> */}
              <p className="text-upload">
                Click hoặc kéo thả để chọn file
              </p>
              <p className="text-hint-excel">
                <em>( Hỗ trợ các định dạng : xlsx, xls)</em>
              </p>
            </Dragger>
          </Form.Item>
          <span className="text-upload">Dung lượng tải lên tối đa : 50 MB</span>

          {screenMobile === false ?
            <Form.Item>
              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  // variant="contained"
                  // type="success"
                  htmlType="button"
                  onClick={handleCloseModal}
                  className='text-btn design-btn-cancel'
                  style={{ width: "130px" }}
                >
                  Hủy
                </Button>
                <Button
                  variant="contained"
                  type="success"
                  htmlType="submit"
                  className='text-btn design-btn-submit'
                  style={{ width: "130px" }}
                >
                  Định khoản
                </Button>
              </Col>
            </Form.Item>
            :
            <Form.Item>
              <Row style={{ display: "flex", justifyContent: "flex-end", marginTop: "5%" }}>
                <Col span={12}>
                  <Button
                    // variant="contained"
                    // type="success"
                    htmlType="button"
                    onClick={handleCloseModal}
                    className='text-btn design-btn-cancel'
                    style={{ width: "98%" }}
                  >
                    Hủy
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    variant="contained"
                    type="success"
                    htmlType="submit"
                    className='text-btn design-btn-submit'
                    style={{ width: "98%", float: "right" }}
                  >
                    Định khoản
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          }
        </Form>
      </Modal>

      {
        messageUpdateFormat && messageUpdateFormat.infor === "Update format" ?
          null
          : (
            <>
              {messageError === "Excel_001" ? (
                <Excel001
                  setIsOpenModalExcel001={setIsOpenModalExcel001}
                  isOpenModalExcel001={isOpenModalExcel001}
                  nameImport={nameImport}
                  infoError={infoError}
                />
              ) : null}
              {messageError === "Excel_002" ? (
                <Excel002
                  isOpenModalExcel002={isOpenModalExcel002}
                  setIsOpenModalExcel002={setIsOpenModalExcel002}
                  nameImport={nameImport}
                  infoError={infoError}
                />
              ) : null}
              {messageError === "Excel_003" ? (
                <Excel003
                  isOpenModalExcel003={isOpenModalExcel003}
                  setIsOpenModalExcel003={setIsOpenModalExcel003}
                  isModalOpen={isModalOpen}
                  setIsModalOpen={setIsModalOpen}
                  nameImport={nameImport}
                  infoError={infoError}
                />
              ) : null}
              {messageError === "Excel_005" ? (
                <Excel005
                  setIsOpenModalExcel005={setIsOpenModalExcel005}
                  isOpenModalExcel005={isOpenModalExcel005}
                  inforError={inforError}
                />
              ) : null}
              {messageError === "Excel_008" ? (
                <Excel008
                  isOpenModalExcel008={isOpenModalExcel008}
                  setIsOpenModalExcel008={setIsOpenModalExcel008}
                  nameImport={nameImport}
                  infoError={infoError}
                />
              ) : null}
            </>
          )
      }
      {
        messageError !== "Excel_002" &&
          messageError !== "Excel_003" &&
          messageError !== "Excel_008" &&
          messageError !== "Excel_005" ? (
          <OutOfRequests
            isOpenModalOutOfRequests={isOpenModalOutOfRequests}
            setIsOpenModalOutOfRequests={setIsOpenModalOutOfRequests}
            messageError={messageError}
            nameImport={nameImport}
          />
        ) : null
      }

      {
        isOpenProgress === true ? (
          <ProgressSaoKe
            isOpenProgress={isOpenProgress}
            setIsOpenProgress={setIsOpenProgress}
            progressTranslating={progressTranslating}
            progressProcessing={progressProcessing}
            nameImport={nameImport}
            isOpenScanningFile={isOpenScanningFile}
          />
        ) : null
      }
    </Container>
  )
}

const ProgressSaoKe = ({
  isOpenProgress,
  setIsOpenProgress,
  progressTranslating,
  progressProcessing,
  nameImport,
  isOpenScanningFile,
}) => {
  const handleCancel = () => {
    setIsOpenProgress(false);
  };

  useEffect(() => {
    if (isOpenProgress === true) {
      localStorage.setItem("openProgress", "true");
    } else {
      localStorage.setItem("openProgress", "false");
    }
  }, []);

  return (
    <>
      <Modal
        open={isOpenProgress}
        onCancel={isOpenScanningFile === false ? handleCancel : null}
        footer={null}
        width={600}
        closable={false}
        style={{ marginTop: "5%" }}
        className='modal-accounting-statement'
      >
        {isOpenScanningFile === false ? (
          <>
            <h3 style={{ textAlign: "center", margin: 0 }}>Tên file: {nameImport}</h3>
            <div
              style={{
                marginTop: "2%",
                display: "flow-root"
              }}
            >
              <span className='text-title-accounting'>Đang biên dịch</span>
              <Progress
                percent={
                  progressTranslating === "NaN" ? 0 : progressTranslating
                }
                // status="active"

                status={progressTranslating < 100 ? 'active' : 'success'}
                style={{ width: "100%" }}
              />
              {progressTranslating < 100 ?
                <span className='text-progress'>{progressTranslating}%<span style={{ fontWeight: 500 }}> complete</span></span>
                :
                <span className='text-progress'>DONE</span>
              }
              <div style={{
                paddingTop: "5%",
              }}>
                <span className='text-title-accounting'>Đang định khoản</span>
                <Progress
                  percent={progressProcessing === "NaN" ? 0 : progressProcessing}
                  // status="active"
                  status={progressProcessing < 100 ? 'active' : 'success'}
                  style={{ width: "100%" }}
                />
                {progressProcessing < 100 ?
                  <span className='text-progress'>{progressProcessing}%<span style={{ fontWeight: 500 }}> complete</span></span>
                  :
                  <span className='text-progress'>DONE</span>
                }
              </div>
            </div>
          </>
        ) : (
          <>
            <h2 style={{ textAlign: "center", margin: 0 }}>Đang scan</h2>
            <img src={ScanningFileGIF} width={"100%"} height={150} alt=""></img>
          </>
        )}
      </Modal>
    </>
  );
};

export default AccountingStatement