import { Modal, Tabs } from 'antd'
import React, { useState } from 'react'
import SheetBuy from './SheetBuy';
import SheetSell from './SheetSell';

function ModalEditCategoryUser({ open, setOpenEditCategoryUser, idCustomer }) {
    const [keyTab, setKeyTab] = useState("1")

    const onChange = (key) => {
        setKeyTab(key)
    };

    const closeModal = () => {
        setOpenEditCategoryUser(false)
    }

    const items = [

        {
            key: '1',
            label: "Mua vào",
            children:
                <SheetBuy
                    idCustomer={idCustomer}
                    setOpenEditCategoryUser={setOpenEditCategoryUser}
                />
        },
        {
            key: '2',
            label: "Bán ra",
            children:
                <SheetSell
                    idCustomer={idCustomer}
                    setOpenEditCategoryUser={setOpenEditCategoryUser}
                />
        },
    ];

    return (
        <>
            <Modal open={open} footer={false} onCancel={closeModal} width={"80%"} style={{ position: "relative", top: "3%" }}>
                <>
                    <Tabs defaultActiveKey="1" items={items} onChange={onChange} activeKey={keyTab}>
                    </Tabs>
                </>
            </Modal>
        </>
    )
}

export default ModalEditCategoryUser