import { Button, Col, Form, Input, InputNumber, Radio, Row, Select } from 'antd';
import { Button as ButtonMui, Container } from '@mui/material';
import CategoryMasterIcon from "../../../../images/CategoryMasterIcon.svg"
import "./CategoryMaster.css"
import IconUpload from "../../../../images/Upload.svg";
import BtnDownload from './BtnDownload';

const { Option } = Select;

function CategoryMasterMobile({
    form,
    onFinish,
    onChange,
    value,
    fileInputRef,
    handleFileChangeDMKH,
    valueNameDMTK,
    urlCustomer,
    fileInputRef1,
    handleFileChange,
    urlSupplier,
    valueNameNCC,
    handleRefreshData,
    listCircular
}) {
    return (
        <Container maxWidth="false" style={{ backgroundColor: "#fff", padding: "0%", width: "100%", marginTop: "1%", height: "88vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
                initialValues={{
                    remember: true,
                }}
                className='text-label-manage-company'
                style={{ width: "100%", height: "inherit", padding: "2%" }}
            >
                <div className='bg-all-master-mobile' style={{ overflowY: "auto" }}>

                    <Row className="rowInforCustomer">
                        <Col
                            span={24}
                            style={{ display: "flex", paddingBottom: "1%" }}
                        >
                            <img style={{ witdh: 22, height: 22 }} src={CategoryMasterIcon} alt=''></img>
                            <span className="title-infor-company" style={{ margin: 0, marginLeft: "1%" }}>
                                Danh mục
                            </span>
                        </Col>
                    </Row>
                    <div style={{ marginTop: "4%" }}>
                        <Col>
                            <Form.Item
                                label="Loại hình kinh doanh"
                                name='type_business'
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            "Vui lòng chọn danh mục Nhà cung cấp/ Khách hàng!",
                                    },
                                ]}>
                                <Radio.Group className='text-checkbox' onChange={onChange} value={value} style={{ width: "100%", display: "grid" }}>
                                    <Row style={{ paddingBottom: "2%" }}>
                                        <Col span={12}><Radio value={1} className='checkbox-sign-up'>Thương mại</Radio></Col>
                                        <Col span={12}><Radio value={2} className='checkbox-sign-up'>Dịch vụ</Radio></Col>
                                    </Row>
                                    <Row >
                                        <Col span={12}><Radio value={3} className='checkbox-sign-up'>Sản xuất</Radio></Col>
                                        <Col span={12}><Radio value={4} className='checkbox-sign-up'>Xây dựng</Radio></Col>
                                    </Row>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col className='hidden-bg-input'>
                            <Form.Item name="dmtk_path" label={<span><span style={{ color: "red" }}>* </span>Danh mục tài khoản</span>}>
                                <input
                                    ref={fileInputRef}
                                    accept=".xlsx,.xls"
                                    type="file"
                                    style={{ display: "none" }}
                                    // onChange={handleFileChangeDMKH}
                                    name="myFile2"
                                    id="myFile2"
                                    onInput={handleFileChangeDMKH}
                                />
                                <Input
                                    placeholder="Danh mục Tài khoản"
                                    type="text"
                                    value={valueNameDMTK || null}
                                    // disabled
                                    readOnly
                                    size='large'
                                    suffix={
                                        <div style={{ display: "flex" }}>
                                            <ButtonMui
                                                htmlFor="myFile2"
                                                component="label"
                                                size="small"
                                                className='none-bg'
                                                style={{ height: "29px" }}
                                            >
                                                <div className="iconDiv" tooltip="Tải lên" tabindex="0">
                                                    <div className="iconSVG">
                                                        <img
                                                            style={{ cursor: "pointer", paddingTop: "9px" }}
                                                            src={IconUpload}
                                                            alt=""
                                                        ></img>
                                                    </div>
                                                </div>
                                            </ButtonMui>
                                            <BtnDownload url={urlCustomer} />
                                        </div>
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col className='hidden-bg-input'>
                            <Form.Item
                                name="ncc_path"
                                label={<span><span style={{ color: "red" }}>* </span>Danh mục nhà cung cấp khách hàng</span>}
                            >
                                <input
                                    ref={fileInputRef1}
                                    accept=".xlsx,.xls"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                    name="myFile1"
                                    id="myFile1"
                                />
                                <Input
                                    size={"large"}
                                    placeholder="Danh mục Nhà cung cấp/ Khách hàng"
                                    type="text"
                                    // disabled
                                    suffix={
                                        <div style={{ display: "flex" }}>

                                            <ButtonMui
                                                htmlFor="myFile1"
                                                component="label"
                                                size="small"
                                                className='none-bg'
                                                style={{ height: "29px" }}
                                            >
                                                <div className="iconDiv" tooltip="Tải lên" tabindex="0">
                                                    <div className="iconSVG">
                                                        <img
                                                            style={{ cursor: "pointer", paddingTop: "9px" }}
                                                            src={IconUpload}
                                                            alt=""
                                                        ></img>
                                                    </div>
                                                </div>
                                            </ButtonMui>


                                            <BtnDownload url={urlSupplier} />
                                        </div>
                                    }
                                    value={valueNameNCC}
                                    // disabled
                                    readOnly
                                    className="inputTTDN"
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label="Thông tư" name='circular'>
                                <Select
                                    size={"large"}
                                    id="code_city"
                                    className="SelectTTDN"
                                    style={{ textAlign: "left", width: "100%" }}
                                    optionFilterProp="children"
                                >
                                    {listCircular.map((item, index) => (
                                        <Option key={index} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Ngành nghề kinh doanh" name='business_main'>
                                    <Input size='large' style={{ width: "100%", float: "left" }}></Input>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Tiền cho phép lệch hóa đơn"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Vui lòng nhập tên viết tắt!",
                                        },
                                    ]}
                                    className='error-amount'
                                    name="amount_deviation"
                                >
                                    <InputNumber
                                        min={0}
                                        maxLength={12}
                                        suffix="đ"
                                        size='large'
                                        controls={false}
                                        placeholder="Nhập số tiền chênh lệch"
                                        formatter={(value) =>
                                            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                        }
                                        style={{ width: "100%", float: "right" }}
                                        onKeyPress={(e) => {
                                            const charCode = e.which ? e.which : e.keyCode;
                                            if (charCode < 48 || charCode > 57) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Col style={{ display: "flex", justifyContent: "flex-end", paddingTop: "2%" }}>
                    <Button
                        htmlType="button"
                        onClick={handleRefreshData}
                        className='text-btn design-btn'
                        style={{ height: 45, width: "50%" }}
                    >
                        Hủy
                    </Button>
                    <Button
                        variant="contained"
                        type="success"
                        htmlType="submit"
                        className='text-btn design-btn-submit'
                        style={{ height: 45, width: "50%" }}
                    >
                        Lưu
                    </Button>
                </Col>
            </Form>
        </Container>
    )
}

export default CategoryMasterMobile