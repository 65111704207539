import { Button, Modal } from "@mui/material";
import { Col, Form, Input, Row } from "antd";
import React from "react";
import SendPhone from "../../../images/send.svg";
import "./ModalContact.css"
import { openNotificationSweetAlert, screenMobile } from "../../../Function";
import { PostMessageContactApi } from "../../../api/usersApi";
import ErrorImage from "./../../../images/ErrorNotifiIcon.svg"
import SuccessImage from "./../../../images/SuccessNotiIcon.svg"
import axios from "axios";
import { localhost } from "../../../server";

const { TextArea } = Input;

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 773,
    height: 438,
    bgcolor: "background.paper",
    p: 4,
    borderRadius: "10px",
};

const style768 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: 540,
    bgcolor: "background.paper",
    p: 4,
    borderRadius: "10px",
};



const ModalContact = ({ openModalContact, handleCloseModal }) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        const FormData = require('form-data');
        let data = new FormData();
        data.append('first_name', values.firstname);
        data.append('last_name', values.lastname);
        data.append('email', values.email);
        data.append('phone_number', values.phone_number);
        data.append('content', values.content);
        axios.post(`${localhost}/api/message/`, data).then(res => {
            form.resetFields()
            handleCloseModal()
            openNotificationSweetAlert(SuccessImage, res.data.message)
        }).catch(err => {
            openNotificationSweetAlert(ErrorImage, err.data.message)

            console.log(err)
        })
    }

    return (
        <Modal
            open={openModalContact}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="modalGetInTouch"
        >
            {screenMobile === true ? (
                <Row style={style768} className="rowGetInTouch">
                    <Form
                        form={form}
                        layout="vertical"
                        autoComplete="off"
                        onFinish={onFinish}
                        initialValues={{
                            remember: true,
                        }}
                        style={{
                            width: "100%",
                            padding: "0% 2% 0 5%",
                        }}
                    >
                        <Row>
                            <Col span={10}>
                                <Col span={24} className="ColGetInTouch">
                                    <p className="pContactModal">GET IN TOUCH</p>
                                </Col>
                                <Col span={24} className="ColGetInTouch">
                                    <p
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            color: "#2D3459",
                                        }}
                                    >
                                        Let us know more about you
                                    </p>
                                </Col>
                                <div style={{ marginTop: "12%", display: "inline-block" }}>
                                    <Col span={24} className="ColGetInTouch">
                                        <Form.Item
                                            name="lastname"
                                            className="formItemGetInTouch"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập họ",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Họ"
                                                size="large"
                                                className="textFieldContact"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} className="ColGetInTouch">
                                        <Form.Item
                                            name="firstname"
                                            className="formItemGetInTouch"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập tên",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Tên"
                                                size="large"
                                                className="textFieldContact"
                                            />
                                        </Form.Item>
                                    </Col>
                                </div>
                                <div style={{ display: "inline-block" }}>
                                    <Col span={24} className="ColGetInTouch">
                                        <Form.Item
                                            name="email"
                                            rules={[
                                                {
                                                    message: "Vui lòng nhập đúng định dạng",
                                                    type: "email",
                                                },
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập Email",
                                                },
                                            ]}
                                            className="formItemGetInTouch"
                                        >
                                            <Input
                                                placeholder="Email"
                                                size="large"
                                                className="textFieldContact"
                                                onBlur={(e) => {
                                                    form.setFieldsValue({ email: e.target.value.trim() });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24} className="ColGetInTouch">
                                        <Form.Item
                                            name="phone_number"
                                            rules={[
                                                {
                                                    pattern: /^\d+$/,
                                                    message: "Số điện thoại chỉ được nhập bằng chữ số",
                                                },
                                            ]}
                                            className="formItemGetInTouch"
                                        >
                                            <Input
                                                placeholder="Số điện thoại"
                                                size="large"
                                                className="textFieldContact"
                                            />
                                        </Form.Item>
                                    </Col>
                                </div>
                                <Col span={24} className="ColGetInTouch">
                                    <Form.Item name="content" className="formItemGetInTouch">
                                        <TextArea
                                            placeholder="Nội dung"
                                            size="large"
                                            style={{
                                                height: 60,
                                                resize: "none",
                                            }}
                                            className="TextAreaContactcontent"
                                        />
                                    </Form.Item>
                                </Col>
                            </Col>
                            <Col span={1}>
                                <div
                                    style={{
                                        border: "1px solid rgb(26 189 243 / 35%)",
                                        height: "90%",
                                        width: "1%",
                                        margin: " auto",
                                        marginTop: "50%",
                                    }}
                                ></div>
                            </Col>
                            <Col span={13}>
                                <Col span={24} className="ColGetInTouch">
                                    <p className="pContactModal">CONTACT</p>
                                </Col>
                                <Col span={24} className="ColGetInTouch">
                                    <p
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            color: "#2D3459",
                                        }}
                                    >
                                        Always within your reach
                                    </p>
                                </Col>
                                <div
                                    style={{
                                        display: "inline-block",
                                        marginTop: "19%",
                                        marginLeft: "5%",
                                    }}
                                >
                                    <Col span={24} className="ColGetInTouch">
                                        <p style={{ fontWeight: 500 }}>Phone: </p>
                                    </Col>
                                    <Col span={24} className="ColGetInTouch">
                                        <p style={{ fontWeight: 500 }}> 0961981199 (Ms Giang)</p>
                                    </Col>
                                </div>
                                <div
                                    style={{
                                        display: "inline-block",
                                        marginTop: "6%",
                                        marginLeft: "5%",
                                    }}
                                >
                                    <Col span={24} className="ColGetInTouch">
                                        <p style={{ fontWeight: 500 }}> 0702769786 (Ms Trang)</p>
                                    </Col>
                                </div>
                                <div
                                    style={{
                                        display: "inline-block",
                                        marginTop: "7%",
                                        marginLeft: "5%",
                                    }}
                                >
                                    <Col span={24} className="ColGetInTouch">
                                        <p style={{ fontWeight: 500 }}>Email: </p>
                                    </Col>
                                    <Col span={24} className="ColGetInTouch">
                                        <p style={{ fontWeight: 500 }}>sales@rainscales.com</p>
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <div style={{ position: "absolute", bottom: -47 }}>
                                <Form.Item>
                                    <Button type="submit" className="btnGetInTouch1">
                                        <svg
                                            width="54"
                                            height="52"
                                            viewBox="0 0 74 72"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M72 36C72 55.8823 55.8823 72 36 72C16.1177 72 0 55.8823 0 36C0 16.1177 16.1177 0 36 0C55.8823 0 72 16.1177 72 36Z"
                                                fill="url(#paint0_linear_444_28836)"
                                            />
                                            <g filter="url(#filter0_d_444_28836)">
                                                <path
                                                    d="M25.0215 35.1415C29.7003 33.6758 33.777 35.6288 35.5023 37.1513C35.6593 37.2899 35.8948 37.2991 36.0538 37.1628L53.676 22.0581C53.9934 21.786 53.7333 21.2708 53.3259 21.3646L18.3053 29.4315C17.9749 29.5076 17.8831 29.9345 18.153 30.1397L24.6511 35.079C24.7564 35.159 24.8954 35.181 25.0215 35.1415Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M37.6858 49.5221C39.9006 44.899 38.3793 40.6 36.9714 38.8651C36.8246 38.6842 36.8342 38.4141 37.011 38.2623L54.8207 22.9669C55.1376 22.6947 55.6071 23.0294 55.4532 23.4177L42.1679 56.9413C42.0421 57.2587 41.6021 57.2828 41.4425 56.981L37.6909 49.8902C37.6305 49.7761 37.63 49.6386 37.6858 49.5221Z"
                                                    fill="white"
                                                />
                                                <path
                                                    d="M19.1323 43.9159C19.9228 38.309 24.1273 36.2969 26.1308 35.9917C30.2121 35.2042 33.2152 36.9856 34.2066 37.9747C38.8248 42.1635 37.3745 47.431 36.0721 49.5412C32.1504 55.4592 26.3438 54.288 23.9307 52.9626C19.3566 50.6052 18.8259 45.9492 19.1323 43.9159Z"
                                                    fill="white"
                                                />
                                            </g>
                                            <path
                                                d="M27.3315 41.7405L31.3471 43.0473L30.6204 47.2071"
                                                stroke="#3A8AC3"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <path
                                                d="M24.0889 43.7798L28.1044 45.0867L27.3778 49.2465"
                                                stroke="#3A8AC3"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                            <defs>
                                                <filter
                                                    id="filter0_d_444_28836"
                                                    x="17.9946"
                                                    y="16.3533"
                                                    width="55.4888"
                                                    height="51.8406"
                                                    filterUnits="userSpaceOnUse"
                                                    color-interpolation-filters="sRGB"
                                                >
                                                    <feFlood
                                                        flood-opacity="0"
                                                        result="BackgroundImageFix"
                                                    />
                                                    <feColorMatrix
                                                        in="SourceAlpha"
                                                        type="matrix"
                                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                        result="hardAlpha"
                                                    />
                                                    <feOffset dx="10" dy="3" />
                                                    <feGaussianBlur stdDeviation="4" />
                                                    <feComposite in2="hardAlpha" operator="out" />
                                                    <feColorMatrix
                                                        type="matrix"
                                                        values="0 0 0 0 0.227451 0 0 0 0 0.4 0 0 0 0 0.796078 0 0 0 1 0"
                                                    />
                                                    <feBlend
                                                        mode="normal"
                                                        in2="BackgroundImageFix"
                                                        result="effect1_dropShadow_444_28836"
                                                    />
                                                    <feBlend
                                                        mode="normal"
                                                        in="SourceGraphic"
                                                        in2="effect1_dropShadow_444_28836"
                                                        result="shape"
                                                    />
                                                </filter>
                                                <linearGradient
                                                    id="paint0_linear_444_28836"
                                                    x1="-2.21918"
                                                    y1="41.9178"
                                                    x2="72"
                                                    y2="29.0959"
                                                    gradientUnits="userSpaceOnUse"
                                                >
                                                    <stop offset="0.0226328" stop-color="#42C0DC" />
                                                    <stop offset="0.354167" stop-color="#49B0FB" />
                                                    <stop offset="1" stop-color="#4471FF" />
                                                </linearGradient>
                                            </defs>
                                        </svg>
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>
                    </Form>
                </Row>
            ) : (
                <Row style={style} className="rowGetInTouch">
                    <Form
                        form={form}
                        layout="vertical"
                        autoComplete="off"
                        onFinish={onFinish}
                        initialValues={{
                            remember: true,
                        }}
                        style={{
                            width: "100%",
                            padding: "0% 2% 0 5%",
                        }}
                    >
                        <Row>
                            <Col span={13}>
                                <Col span={24} className="ColGetInTouch">
                                    <p
                                        style={{
                                            textAlign: "center",
                                            fontSize: "30px",
                                            fontWeight: 700,
                                            color: "#058DF4",
                                            marginTop: "26px",
                                        }}
                                    >
                                        GET IN TOUCH
                                    </p>
                                </Col>
                                <Col span={24} className="ColGetInTouch">
                                    <p
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            color: "#2D3459",
                                        }}
                                    >
                                        Let us know more about you
                                    </p>
                                </Col>
                                <div style={{ marginTop: "12%", display: "flex" }}>
                                    <Col span={11} className="ColGetInTouch">
                                        <Form.Item
                                            name="lastname"
                                            className="formItemGetInTouch"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập họ",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Họ"
                                                size="large"
                                                className="textFieldContact"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} className="ColGetInTouch"></Col>
                                    <Col span={11} className="ColGetInTouch">
                                        <Form.Item
                                            name="firstname"
                                            className="formItemGetInTouch"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập tên",
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Tên"
                                                size="large"
                                                className="textFieldContact"
                                            />
                                        </Form.Item>
                                    </Col>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <Col span={11} className="ColGetInTouch">
                                        <Form.Item
                                            name="email"
                                            rules={[
                                                {
                                                    message: "Vui lòng nhập đúng định dạng",
                                                    type: "email",
                                                },
                                                {
                                                    required: true,
                                                    message: "Vui lòng nhập Email",
                                                },
                                            ]}
                                            className="formItemGetInTouch"
                                        >
                                            <Input
                                                placeholder="Email"
                                                size="large"
                                                className="textFieldContact"
                                                onBlur={(e) => {
                                                    form.setFieldsValue({ email: e.target.value.trim() });
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={2} className="ColGetInTouch"></Col>
                                    <Col span={11} className="ColGetInTouch">
                                        <Form.Item
                                            name="phone_number"
                                            rules={[
                                                {
                                                    pattern: /^\d+$/,
                                                    message: "Số điện thoại chỉ được nhập bằng chữ số",
                                                },
                                            ]}
                                            className="formItemGetInTouch"
                                        >
                                            <Input
                                                placeholder="Số điện thoại"
                                                size="large"
                                                className="textFieldContact"
                                            />
                                        </Form.Item>
                                    </Col>
                                </div>
                                <Col span={24} className="ColGetInTouch">
                                    <Form.Item name="content" className="formItemGetInTouch">
                                        <TextArea
                                            placeholder="Nội dung"
                                            size="large"
                                            style={{
                                                height: 100,
                                                resize: "none",
                                            }}
                                            className="TextAreaContactcontent"
                                        />
                                    </Form.Item>
                                </Col>
                            </Col>
                            <Col span={2}>
                                <div
                                    style={{
                                        border: "1px solid rgb(26 189 243 / 35%)",
                                        height: "90%",
                                        width: "1%",
                                        margin: " auto",
                                        marginTop: "50%",
                                    }}
                                ></div>
                            </Col>
                            <Col span={9}>
                                <Col span={24} className="ColGetInTouch">
                                    <p
                                        style={{
                                            textAlign: "center",
                                            fontSize: "30px",
                                            fontWeight: 700,
                                            color: "#058DF4",
                                            marginTop: "26px",
                                        }}
                                    >
                                        CONTACT
                                    </p>
                                </Col>
                                <Col span={24} className="ColGetInTouch">
                                    <p
                                        style={{
                                            textAlign: "center",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            color: "#2D3459",
                                        }}
                                    >
                                        Always within your reach
                                    </p>
                                </Col>
                                <div
                                    style={{
                                        display: "flex",
                                        marginTop: "19%",
                                        marginLeft: "5%",
                                    }}
                                >
                                    <Col span={4} className="ColGetInTouch">
                                        <p style={{ fontWeight: 500 }}>Phone: </p>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={19} className="ColGetInTouch">
                                        <p style={{ fontWeight: 500 }}> 0961981199 (Ms Giang)</p>
                                    </Col>
                                </div>
                                <div
                                    style={{ display: "flex", marginTop: "6%", marginLeft: "5%" }}
                                >
                                    <Col span={4} className="ColGetInTouch">
                                        <p style={{ fontWeight: 500 }}></p>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={19} className="ColGetInTouch">
                                        <p style={{ fontWeight: 500 }}> 0702769786 (Ms Trang)</p>
                                    </Col>
                                </div>
                                <div
                                    style={{ display: "flex", marginTop: "7%", marginLeft: "5%" }}
                                >
                                    <Col span={4} className="ColGetInTouch">
                                        <p style={{ fontWeight: 500 }}>Email: </p>
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col span={19} className="ColGetInTouch">
                                        <p style={{ fontWeight: 500 }}>sales@rainscales.com</p>
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                        <div style={{ position: "absolute", bottom: -37, right: -30 }}>
                            <Form.Item>
                                <Button type="submit" className="btnGetInTouch1">
                                    <img src={SendPhone} alt="" />
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </Row>
            )}
        </Modal>
    );
};

export default ModalContact;
