import { exportSheetDeclareVat, inforInvoiceDone, inforSheetDeclareVat } from "../constants";
import { authAxios } from "./axiosClient";

export const GetListSheetDeclareVat = (params = {}) => {
    const url = inforSheetDeclareVat;
    return authAxios().get(url, { params });
};
export const ExportSheet = (params = {}) => {
    const url = exportSheetDeclareVat;
    return authAxios().post(url, params, { responseType: "blob" });
};


