import { cityURL, districtsURL, inforCustomer, manageCustomer } from "../constants";
import { authAxios } from "./axiosClient";

export const GetListCityApi = (params = {}) => {
    const url = cityURL;
    return authAxios().get(url, { params });
};

export const GetListDistrictsApi = (params = {}) => {
    const url = districtsURL;
    return authAxios().get(url, { params });
};

export const GetInForCompanyApi = (id,params = {}) => {
    const url = inforCustomer;
    return authAxios().get(url(id), { params });
};

export const PutInForCompanyApi = (params = {}) => {
    const url = manageCustomer;
    return authAxios().put(url,  params );
};

export const PostInForCompanyApi = (params = {}) => {
    const url = manageCustomer;
    return authAxios().post(url, params );
};

export const DeleteInForCompanyApi = (params = {}) => {
    const url = manageCustomer;
    return authAxios().delete(url, {params});
};

