import {
  Col,
  DatePicker,
  Row,
  Select,
  Table,
} from "antd";
import { useEffect, useState } from "react";
import dayjs from 'dayjs';
import DownloadIcon from "./../../../../images/image_manage_docs/DownloadInvoice.svg";

import "./TaxDocument.css";
import { screenMobile } from "../../../../Function";
import { GetListTaxAgent } from "../../../../api/manageInforUser";
import { Container } from "@mui/material";
import { GetInForCompanyApi } from "../../../../api/inforCompanyApi";


import 'dayjs/locale/vi';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { ExportSheet, GetListSheetDeclareVat } from "../../../../api/sheetVAT";
import { useSelector } from "react-redux";
const { Option } = Select;
dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);

// Thiết lập locale tiếng Việt cho dayjs
dayjs.locale('vi');
function TaxDocument() {

  const [dataTaxAgent, setDataTaxAgent] = useState([])

  const [idCustomer, setIdCustomer] = useState(null)

  const [dataSource, setDataSource] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [yearDeclare, setYearDeclare] = useState(dayjs().year())
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);

  const checkRoleAdminTax = userInfo.role_id === 4 || userInfo.role_id === 3
  const checkIdCustomer = !checkRoleAdminTax ? userInfo.customer_id : idCustomer
  const customColorStatus = (text) => {
    if (text.toLowerCase() === "thất bại") {
      return { color: "#E55353", background: "rgba(229, 83, 83, 0.13)" };
    } else if (text.toLowerCase() === "đang xử lý") {
      return { color: "#FAAB35", background: "rgba(250, 171, 53, 0.17)" };
    } else if (text.toLowerCase() === "hoàn thành") {
      return { color: "#2EB85C", background: "rgba(87, 232, 135, 0.15)" };
    } else if (text.toLowerCase() === "đang định khoản") {
      return { color: "#4873DE", background: "rgba(72, 115, 222, 0.13)" };
    }
  };


  // const columnsMobile = [
  //   {
  //     title: "STT",
  //     dataIndex: "stt",
  //     key: "name",
  //     align: "center",
  //     ellipsis: true,
  //     width: 30,
  //     render: (value, item, index) =>
  //       index + 1 + (pager.current - 1) * pager.pageSize,
  //   },
  //   {
  //     title: "Thao tác",
  //     dataIndex: "thao_tac",
  //     key: "thao_tac",
  //     align: "center",
  //     ellipsis: true,
  //     width: 50,
  //     render: (text, record) => {
  //       return (
  //         <img
  //           style={{ cursor: "pointer" }}
  //           onClick={() => openModalDetail(record)}
  //           src={DetailIcon}
  //           alt=""
  //         ></img>
  //       );
  //     },
  //   },
  //   {
  //     title: "Số hóa đơn",
  //     dataIndex: "invoice_number",
  //     key: "invoice_number",
  //     align: "center",
  //     ellipsis: true,
  //     width: 70,
  //   },

  //   {
  //     title: "Mã số thuế",
  //     dataIndex: "tax_code",
  //     key: "tax_code",
  //     align: "center",
  //     ellipsis: true,
  //     width: 80,
  //   },

  //   {
  //     title: "Thành tiền",
  //     dataIndex: "total_vat_amount",
  //     key: "total_vat_amount",
  //     align: "center",
  //     ellipsis: true,
  //     width: 80,
  //     render: (text, record) => text.toLocaleString(),
  //   },

  //   {
  //     title: "Định khoản",
  //     dataIndex: "name_status",
  //     key: "name_status",
  //     align: "center",
  //     ellipsis: true,
  //     width: 80,
  //     render: (text, record) => {
  //       return (
  //         <div
  //           style={{
  //             width: "100%",
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //           }}
  //         >
  //           <p className="text-dinh-khoan" style={customColorStatus(text)}>
  //             {text}
  //           </p>
  //         </div>
  //       );
  //     },
  //   },
  // ];

  const columns = [
    {
      title: "STT",
      dataIndex: "stt",
      key: "name",
      align: "center",
      ellipsis: true,
      width: 50,
      render: (value, record, index) => index + 1

    },

    {
      title: "Kỳ kê khai",
      dataIndex: "tax_declare_year",
      key: "tax_declare_year",
      align: "center",
      ellipsis: true,
      width: 150,
      render: (value, record, index) => record.tax_declare_period_name + " " + record.tax_declare_period
    },
    {
      title: "Thuế GTGT đầu vào",
      dataIndex: "ct24",
      key: "ct24",
      align: "center",
      ellipsis: true,
      render: (text, record) => text.toLocaleString(),
    },

    {
      title: "Thuế GTGT đầu ra",
      dataIndex: "ct35",
      key: "ct35",
      align: "center",
      ellipsis: true,
      // width: 120,
      render: (text, record) => text.toLocaleString(),
    },

    {
      title: "Thuế GTGT đủ điều kiện khấu trừ",
      dataIndex: "ct25",
      key: "ct25",
      align: "center",
      ellipsis: true,
      render: (text, record) => text.toLocaleString(),
    },

    {
      title: "Thuế GTGT phải nộp",
      dataIndex: "ct36",
      key: "ct36",
      align: "center",
      ellipsis: true,
      render: (text, record) => text.toLocaleString(),
      // width: 150,
    },

    {
      title: "Thao tác",
      dataIndex: "action",
      key: "action",
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record) =>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <button onClick={() => exportSheetVAT(record)} style={{ border: "none", background: "unset", cursor: "pointer" }}>
            <img alt='' src={DownloadIcon} style={{ marginRight: "3%" }}></img>
          </button>
        </div>
    },
  ];

  const fetchInforSheetVAT = (params = {}) => {
    setLoadingTable(true);
    GetListSheetDeclareVat(params)
      .then((res) => {
        setLoadingTable(false);
        setDataSource(res.data);
      })
      .catch((err) => {
        setLoadingTable(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setLoadingTable(true);
    if (checkIdCustomer !== null) {
      fetchInforSheetVAT({
        id_customer: checkIdCustomer,
        tax_declare_year: yearDeclare,
      });
    }
  }, [idCustomer, yearDeclare]);

  const changeYearDeclare = (value) => {
    setYearDeclare(value.$y)
  }

  useEffect(() => {
    fetchListTaxAgent();
  }, []);

  // const fetchInforCompany = (id) => {
  //   GetInForCompanyApi(id).then(res => {
  //     // setDataDetailCompany(res.data)
  //   }).catch(err => {

  //   })
  // }

  const fetchListTaxAgent = () => {
    GetListTaxAgent().then(res => {
      const data = res.data.filter(item => item.id === res.data[0].id)
      setDataTaxAgent(res.data)
      chooseIdCustomer(res.data[0].id, data[0].name_organization)
    }).catch(err => {
      console.log(err)
    })
  }

  const chooseIdCustomer = (value, name) => {
    setIdCustomer(value)
    // fetchInforCompany(value)
    // if (name !== "") {
    //   if (typeof name === "string") {
    //     setNameCompany(name)
    //   } else if (typeof name === "object") {
    //     setNameCompany(name.children)
    //   }
    // } else {
    //   const data = dataTaxAgent.filter(item => item.id === value)
    //   setNameCompany(data[0].name_organization)
    // }
  };

  const exportSheetVAT = (data) => {
    // Tải thông tin đầu vào của HD dạng file zip: ảnh/bản thể hiện HD, file excel HD theo mẫu
    const fnFileDownload = require("js-file-download");

    const periodName = data.tax_declare_period_name === "Tháng" ? "T" : "Q"
    const filename =
  
      data.ct05 + "_TKGTGT_01_GTGT_TT80_2024_" + periodName + data.tax_declare_period + "_" + data.tax_declare_year + ".xls";
    ExportSheet({
      id_customer: checkIdCustomer,
      tax_declare_year: data.tax_declare_year,
      tax_declare_period: data.tax_declare_period,
    })
      .then((res) => {
        console.log(res)
        fnFileDownload(res.data, filename);
      })
      .catch((err) => { });
  }

  return (
    <Container maxWidth="false" style={{ backgroundColor: "#fff", width: "97%", marginTop: "1%", height: "88vh" }}>


      <Row style={{ paddingTop: 10 }}>
        {checkRoleAdminTax && dataTaxAgent.length > 0 &&
          <div style={{ display: 'flex', alignItems: "center" }}>
            <span className='text-choose-company'>DOANH NGHIỆP : </span>
            <Select
              size={"middle"}
              style={{ width: "230px", float: "left", marginLeft: "12px" }}
              placeholder="Chọn doanh nghiệp"
              showSearch
              className='choose-company'
              optionFilterProp="children" // phải có mới search đc
              onChange={chooseIdCustomer}
              defaultValue={dataTaxAgent[0].id}
            >
              {dataTaxAgent.map((item, index) => (
                <Option key={item.id} value={item.id}>
                  {item.name_organization}
                </Option>
              ))}
            </Select>
          </div>
        }
        <div style={{ display: 'flex', alignItems: "center", marginLeft: checkRoleAdminTax && dataTaxAgent.length > 0 && "2%" }}>
          <span className='text-choose-company'>NĂM KÊ KHAI : </span>

          <DatePicker allowClear={false} defaultValue={dayjs()} picker="year" onChange={changeYearDeclare} style={{ width: "230px", float: "left", marginLeft: "12px" }} />
        </div>
      </Row>

      <div style={{ width: "100%" }}>
        {/* {screenMobile === false ? ( */}
        <div style={{ width: "100%", position: "relative" }}>
          <Row>
            <Col span={16} style={{ paddingTop: "1%" }} offset={4} className="title-table-invoice-all">
              {"TỜ KHAI THUẾ GTGT"}
            </Col>
          </Row>

          <Table
            size="small"
            columns={columns}
            dataSource={dataSource}
            // onChange={handleChange}
            style={{ marginTop: "1%", fontSize: 14, position: 'absolute' }}
            className="table-invoice-all"
            bordered
            scroll={{
              // y: dataSource.length > 0 ? window.screen.availHeight >= 830 ? "40vh" : "36vh" : null,
              // x: 1400,
            }}
            loading={loadingTable}
          />
        </div>
        {/* ) : (
          null
        )} */}
      </div>

    </Container>
  );
}

export default TaxDocument;
