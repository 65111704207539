import React from "react";
import ImageExcel001 from "../../../../../images/Excel001.png";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

function Excel001({ isOpenModalExcel001, setIsOpenModalExcel001, infoError }) {
  const handleCancel = () => {
    setIsOpenModalExcel001(false);
  };

  const MySwal = withReactContent(Swal);

  if (isOpenModalExcel001 === true) {
    handleCancel();
    MySwal.fire({
      html: <i style={{ color: "red" }}>{infoError}</i>,
      imageUrl:  ImageExcel001,
      // iconUrl: ErrorImage,
      customClass: {
        popup: "custom-notification",
        confirmButton: "btn-category-master",
        icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
      },
      imageWidth: "90%",

      position: "top",
      confirmButtonColor: "#3ab7b0",
      confirmButtonText: "Xác nhận",
      // width: screenMobile === true ? "80%" : "50%",
    });
  }

  return <></>;
}

export default Excel001;
