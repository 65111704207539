import { Button, Form, Input, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import ArrowWhite from "../../../images/ArrowWhiteIcon.svg"
import EmailIcon from "../../../images/IconEmail.svg"
import EmailIconMB from "../../../images_mobile/IconEmailMB.svg"
import "./Login.css"
import { ForgotPasswordApi } from '../../../api/usersApi'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import CarouselAds from './CarouselAds'
import ModalContact from './ModalContact'
import CallCenterIcon from "../../../images/CallCenter.svg"
import ErrorImage from "../../../images/ErrorNotifiIcon.svg"
import SuccessImage from "../../../images/SuccessNotiIcon.svg"
import LogoRSmobile from "../../../images_mobile/LogoRSmobile.svg"
import ContactIconMB from "../../../images_mobile/IconContactMB.svg"
import { screenMobile } from '../../../Function'

const MySwal = withReactContent(Swal);


function ForgetPassword() {
    const [form] = Form.useForm();
    const [messageErrEmail, setMessageErrEmail] = useState()
    const [valueEmail, setValueEmail] = useState()
    const [openModalContact, setOpenModalContact] = useState(false)
    const [loadingBtn, setLoadingBtn] = useState(false)

    const handleOpenModalContact = () => {
        setOpenModalContact(true)
    }

    const handleCloseModalContact = () => {
        setOpenModalContact(false)
    }

    const onNotification = (icon, message, status, className, color) => {
        MySwal.fire({
            timer: 1000000000,
            title: <span className={className} style={{ color: color }}>{status}</span>,
            html: <i style={{ fontSize: 16 }}>{message}</i>,
            // icon: "success",
            imageUrl: icon,
            showConfirmButton: false,
            // confirmButtonText: <span className="title-btn">{titleBtn} &nbsp;> </span>,
            // confirmButtonColor: "#fff",
            customClass: {
                icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
                popup: "custom-notification",
                // confirmButton: "custom-confirm-btn"
                // image: "custom-image"
            },
            position: "top",
            // width: screenMobile === true ? "80%" : "20%",
        });
    }

    const onFinish = (values) => {
        setLoadingBtn(true)
        var FormData = require("form-data");
        var data = new FormData();

        data.append("email_sign", values.email);
        ForgotPasswordApi(data).then(res => {
            form.resetFields()
            onNotification(SuccessImage, res.data.message, "Yêu cầu thành công !!!", 'text-success', "#2EB85C")
            setMessageErrEmail()
            setLoadingBtn(false)
        }).catch(err => {
            setMessageErrEmail(err.data.message)
            onNotification(ErrorImage, err.data.message, "Yêu cầu thất bại !!!", 'text-error', "#e55353")
            setLoadingBtn(false)
        })
    }

    const checkMessageErr = () => {
        return <p className='error-email'>{messageErrEmail}</p>
    }

    useEffect(() => {
        if (messageErrEmail !== undefined) {
            checkMessageErr()
        }
    }, [messageErrEmail]);

    const handleChangeInput = (e) => {
        setMessageErrEmail()
    }

    return (
        <>
            <div style={{ display: "flex", position: "relative", height: "100vh" }}>
                {screenMobile === false ?
                    <>
                        <CarouselAds />
                        <div className='custom-center' style={{ width: "50%", height: "100vh", flexDirection: "column", background: "#fff" }}>
                            <div style={{ width: "50%" }}>
                                <h5 className='title-box-login' style={{ fontSize: 26 }}>
                                    Xác nhận email
                                </h5>
                                <div >
                                    <p className='text-forgetPW'>Nhập địa chỉ email đã được xác minh của tài khoản người dùng của bạn và chúng tôi sẽ gửi cho bạn liên kết đặt lại mật khẩu</p>
                                </div>
                                <Form
                                    form={form}
                                    name="control-hooks"
                                    layout='vertical'
                                    onFinish={onFinish}
                                >

                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        className='custom-border'
                                        rules={[
                                            { required: true, message: "Vui lòng nhập Email" },
                                            {
                                                type: 'email',
                                                message: 'Định dạng email không hợp lệ',
                                            },
                                        ]}
                                    >
                                        <Input onChange={handleChangeInput} className='input-sign-up' suffix={<img src={EmailIcon} alt=''></img>} placeholder='Nhập địa chỉ email' />
                                    </Form.Item>

                                    <Row style={{ marginTop: "6%" }}>
                                        <Button className='btn-login' loading={loadingBtn} style={{ backgroundColor: "#058DF4", color: "#fff" }} htmlType='submit'>Tiếp tục <img src={ArrowWhite} alt=''></img></Button>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                        <img style={{ position: "absolute", right: 50, bottom: 50, cursor: "pointer" }} onClick={handleOpenModalContact} src={CallCenterIcon} alt=''></img>

                    </>
                    :
                    <>
                        <div className='custom-center' style={{ width: "100%", height: "100vh", flexDirection: "column", background: "#fff" }}>
                            <Row style={{ display: "contents" }}>
                                <img alt='' src={LogoRSmobile}></img>
                                <span className='text-title-mb'>Process Automation - AI Powered Automation - Managed Services</span>
                            </Row>
                            <Row style={{ display: "contents" }}>
                                <h5 className='title-box-login-mb'>
                                    Xác nhận email
                                </h5>
                                <span className='text-forgetPW-mb'>Nhập địa chỉ email đã được xác minh của tài khoản người dùng của bạn và chúng tôi sẽ gửi cho bạn liên kết đặt lại mật khẩu</span>
                            </Row>
                            <div style={{ width: "100%", marginTop: "6%", rowGap: "1ch" }}>
                                <Form
                                    form={form}
                                    name="control-hooks"
                                    layout='vertical'
                                    onFinish={onFinish}
                                    style={{ height: "60vh", display: "inline-block", width: "95%" }}
                                >

                                    <Form.Item
                                        name="email"
                                        label=""
                                        className='custom-border'
                                        // validateStatus={messageErrEmail && 'error'}
                                        // help={messageErrEmail && messageErrEmail}
                                        rules={[
                                            { required: true, message: "Vui lòng nhập Email" },
                                            {
                                                type: 'email',
                                                message: 'Định dạng email không hợp lệ',
                                            },
                                        ]}
                                    >
                                        <Input onChange={handleChangeInput} className='input-sign-up-mb' prefix={<img src={EmailIconMB} alt=''></img>} placeholder='Nhập địa chỉ email' />
                                        {/* {messageErrEmail && checkMessageErr()} */}
                                    </Form.Item>

                                    <Row style={{ paddingTop: "24px" }}>
                                        <Button className='btn-login' loading={loadingBtn} style={{ backgroundColor: "#058DF4", color: "#fff" }} htmlType='submit'>Tiếp tục <img src={ArrowWhite} alt=''></img></Button>
                                    </Row>
                                </Form>
                            </div>
                            <Row className='row-footer-mb'>
                                <img style={{ paddingRight: 6 }} src={ContactIconMB} alt=''></img><span className='text-footer-mobile'>(0961.981.199) (0941.519.328)</span>
                            </Row>
                        </div>
                    </>}
                <ModalContact
                    openModalContact={openModalContact}
                    handleCloseModal={handleCloseModalContact}
                />
            </div>

        </>
    )
}

export default ForgetPassword