import { Button, Col, Row, Select, Table, Transfer } from 'antd';
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Container } from '@mui/material';
import "./SettingDocument.css"
import { IoSettingsOutline } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { GetTemplateDoc, PostTemplateDocApi } from '../../../../api/settingDocApi';
import NoDataImage from "../../../../images/NoDataImage.svg"
import ErrorImage from "../../../../images/ErrorNotifiIcon.svg"
import SuccessImage from "../../../../images/SuccessNotiIcon.svg"
import difference from "lodash/difference";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { GetListTaxAgent } from '../../../../api/manageInforUser';
import dayjs from 'dayjs';
import { screenMobile } from '../../../../Function';
const { Option } = Select;

const MySwal = withReactContent(Swal);
const onNotification = (icon, status, message, action) => {
  const checkSuccess = status === "success"
  MySwal.fire({
    timer: 500000000,
    // title: <strong>Có mã số thuế</strong>,
    html: <i>{message}</i>,
    imageUrl: icon,
    showConfirmButton: checkSuccess,
    confirmButtonText: checkSuccess ? <Button className="title-btn" style={{ border: "unset", boxShadow: "unset" }} onClick={() => action(false)}>Quay lại trang mẫu &nbsp;> </Button> : null,
    allowOutsideClick: !checkSuccess,
    customClass: {
      icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
      popup: "custom-notification"
    },
    position: "top",
  });
}


function SettingDocument() {
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const [listColumns, setListColumns] = useState([])
  const [onShowSetting, setOnShowSetting] = useState(false)
  const [columns, setColumns] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [dataTaxAgent, setDataTaxAgent] = useState([])
  const listRole = ["Admin Tax", "Admin Manager"]
  const [idCustomer, setIdCustomer] = useState(null)
  const [listTemplate, setListTemplate] = useState([])

  const columnSmall = ['Số hóa đơn', "TK Nợ", "TK Có", "Loại chứng từ", "Kí hiệu hóa đơn", "Số lượng", "Đơn vị tính"]
  const columnMedium = ["Trạng thái", "Ngày tải lên", 'Ngày hạch toán', "Ngày hóa đơn", "Mã đối tượng", "Mẫu hóa đơn", "Tên doanh nghiệp", "Mã số thuế", "Thuế suất hóa đơn", "Tổng tiền sau thuế", "Tiền thuế", "Chiết khấu thương mại", "Đơn giá", "Thành tiền chưa VAT"]

  const fetchTemplateDoc = (id) => {
    GetTemplateDoc(id).then(res => {
      setListColumns(res.data)
      let columnTable = []

      for (const element of res.data.ls_id_templatehd_customer) {
        for (const elementHD of res.data.data_templatehd) {
          if (Number(element) === elementHD.id) {
            const data = {
              title: <span>{elementHD.name_display}</span>,
              dataIndex: elementHD.name_abbreviation,
              key: elementHD.name_abbreviation,
              align: !columnSmall.includes(elementHD.name_display) && !columnMedium.includes(elementHD.name_display) ? "left" : "center",
              width: columnSmall.includes(elementHD.name_display) ? 130 : columnMedium.includes(elementHD.name_display) ? 200 : 300,
              ellipsis: true,
              render: (text) => {
                return <span className='text-columns'>{text}</span>
              }
            }
            columnTable.push(data)
          }
        }
      }
      setColumns(columnTable)

      const dataTable = []
      for (let i = 0; i < 10; i++) {
        const data = {
          accounting_date: "11/11/2023",
          invoice_date: "01/01/2024",
          invoice_no: "4749836",
          debit_account: "642",
          credit_account: "331",
          object_code: "0100109106",
          object_name: "VIETTEL HCM - CN TẬP ĐOÀN CÔNG NGHIỆP - VIỄN THÔNG QUÂN ĐỘI",
          content: "Cước viễn thông kỳ cước đóng trước 6 tháng",
          type_document: "HDMV",
          tax_code: "0100109106",
          item_amount_withoutvat: "474,545",
          invoice_template: "01GTKT0/007",
          invoice_series: "AD/20E",
          vat_percentage: "0.1",
          item_name: "Cước viễn thông kỳ cước đóng trước 6 tháng",
          unit_name: "",
          quantity: "1.0",
          unit_price: "474,545.00",
          discount_amount: "0.00",
          create_date: "05/12/2023",
          customer_name: "Rainscales VN",
          total_vat_amount: "0",
          total_amount_withvat: "474,545.00",
          status: "",
        }
        dataTable.push(data)
      }
      setDataSource(dataTable)
      setListTemplate(res.data.data_history_export)
    }).catch(err => {
      console.log(err)
    })
  }

  const chooseIdCustomer = (value, name) => {
    setIdCustomer(value)
    fetchTemplateDoc(value)
  };

  const fetchListTaxAgent = () => {
    GetListTaxAgent().then(res => {
      setDataTaxAgent(res.data)
      chooseIdCustomer(res.data[0].id)
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    if (listRole.includes(userInfo.role_name)) {
      fetchListTaxAgent();
    } else {
      chooseIdCustomer(userInfo.customer_id)
    }
  }, []);

  const checkRoleAdminTax = userInfo.role_id === 4 || userInfo.role_id === 3

  return (
    <Container maxWidth="false" className='container-infor-setting' style={onShowSetting === true ? { width: "97%", padding: "1.5%" } : { width: "97%", padding: "1% 1% 3%", height: "88vh" }}>
      <div className={onShowSetting === true ? 'div-setting' : 'div-show-data'}>
        {onShowSetting === true ?
          <SettingColumns fetchTemplateDoc={fetchTemplateDoc} listTemplate={listTemplate} setOnShowSetting={setOnShowSetting} setListColumns={setListColumns} listColumns={listColumns} idCustomer={idCustomer} setIdCustomer={setIdCustomer} />
          :
          <>
            {checkRoleAdminTax === true && dataTaxAgent.length > 0 ?
              <Row style={{ paddingTop: 10, display: "flex" }}>
                <span className='text-choose-company'>DOANH NGHIỆP : </span>
                <Select
                  size={"middle"}
                  style={{ width: "230px", float: "left", marginLeft: "12px" }}
                  placeholder="Chọn doanh nghiệp"
                  showSearch
                  className='choose-company'
                  optionFilterProp="children" // phải có mới search đc
                  onChange={chooseIdCustomer}
                  defaultValue={idCustomer === null ? dataTaxAgent[0].id : idCustomer}
                >
                  {dataTaxAgent.map((item, index) => (
                    <Option key={item.id} value={item.id}>
                      {item.name_organization}
                    </Option>
                  ))}
                </Select>
              </Row>
              : null}
            <Row style={{ width: "100%", display: "flex" }}>
              <Col span={2}></Col>
              <Col span={20} className='title-setting-doc'>BẢNG MẪU XUẤT HÓA ĐƠN</Col>
              <Col span={2}>
                <Button className='design-btn-cancel btn-setting' onClick={() => setTimeout(() => {
                  setOnShowSetting(true)
                }, 200)}><IoSettingsOutline style={{ fontSize: 20 }} /></Button>
              </Col>
            </Row>
            <Table className="table-setting-doc" scroll={{
              x: 1400
            }} style={{ width: "100%", paddingTop: "0.5%", height: "auto", position: "absolute" }} pagination={false} bordered dataSource={dataSource} columns={columns} size='middle' />
          </>
        }
      </div>
    </Container>
  )
}

const SettingColumns = ({ setOnShowSetting, listColumns, idCustomer, setIdCustomer, listTemplate, fetchTemplateDoc }) => {
  const [mockData, setMockData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const getMock = (mockTemplate) => {
    const tempTargetKeys = [];
    const tempMockData = [];
    for (let i = 0; i < listColumns.data_templatehd.length; i++) {
      const data = {
        key: (listColumns.data_templatehd[i].id).toString(),
        title: listColumns.data_templatehd[i].name_display,
        description: `description of content${i + 1}`,
      };
      tempMockData.push(data);
    }

    for (const element of mockTemplate) {
      tempTargetKeys.push(element)
    }
    setMockData(tempMockData);
    setTargetKeys(tempTargetKeys);
  };
  useEffect(() => {
    if (listColumns.data_history_export.length > 0) {
      getMock(listColumns.data_history_export[0].ls_id_templatehd_customer);
    } else {
      getMock(listColumns.ls_id_templatehd_customer);
    }
  }, []);

  const ClickEditColumnsDoc = () => {
    let FormData = require("form-data");
    let data = new FormData();
    data.append("id_customer", idCustomer)
    data.append("ls_id_templatehd_customer", targetKeys)
    PostTemplateDocApi(data).then(res => {
      setIdCustomer(idCustomer)
      fetchTemplateDoc(idCustomer)
      onNotification(SuccessImage, "success", res.data.message, setOnShowSetting)
    }).catch(err => {
      onNotification(ErrorImage, "error", err.data.message)
    })
  }

  const chooseTemplate = (value) => {
    const mockTemplate = listColumns.data_history_export.filter(item => item.id === value)
    getMock(mockTemplate[0].ls_id_templatehd_customer)
  }

  return (
    <>
      <Row style={{ width: "100%" }}>
        <Col span={20}>
          <u className='title-setting'>Cài đặt mẫu xuất chứng từ</u>
        </Col>
        <Col span={4}>
          <Select
            size={"middle"}
            style={{ width: !screenMobile ? "230px" : "unset", float: "right", marginLeft: "12px" }}
            placeholder="Chọn template"

            className='choose-company'
            optionFilterProp="children" // phải có mới search đc
            onChange={chooseTemplate}
            defaultValue={listTemplate.length > 0 ? listTemplate[0].id : null}
          >
            {listTemplate.map((item, index) => (
              <Option key={item.id} value={item.id}>
                {dayjs(item.created_date).format("DD-MM-YYYY") + " Lần " + item.other1}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <CustomTransfer mockData={mockData} targetKeys={targetKeys} setTargetKeys={setTargetKeys} setMockData={setMockData} getMock={getMock} />

      <Col style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          // variant="contained"
          // type="success"
          htmlType="button"
          onClick={() => setTimeout(() => {
            setOnShowSetting(false)
          }, 200)}
          className='text-btn design-btn-cancel'
        >
          Huỷ
        </Button>
        <Button
          // variant="contained"
          // type="success"
          htmlType="submit"
          className='text-btn design-btn-submit'
          onClick={ClickEditColumnsDoc}
        >
          Lưu
        </Button>
      </Col>
    </>
  );
}

const TableTransfer = ({ mockDataCustom, setMockDataCustom, setTargetKeys, leftColumns, rightColumns, dataSource, targetKeys, ...restProps }) => {

  const filterOption = (inputValue, option) => option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
  const handleChange = (newTargetKeys, direction, moveKey) => {
    const newArr = targetKeys.concat(moveKey)
    if (direction === 'left') {
      setTargetKeys(newTargetKeys);

    } else {
      setTargetKeys(newArr);
    }
  };

  return (
    <Transfer
      dataSource={dataSource}
      targetKeys={targetKeys}
      disabled={false}
      showSearch
      onChange={handleChange}
      showSelectAll={false}
      filterOption={filterOption}
      className='custom-transfer'
      locale={{
        searchPlaceholder: "Nhập nội dung tìm kiếm..."
      }}
      {...restProps}
      style={{ padding: screenMobile === false ? "2% 0%" : "4% 0% 2%" }}
    >
      {({
        direction,
        onItemSelectAll,
        filteredItems,
        onItemSelect,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === 'left' ? leftColumns : rightColumns;
        const rowSelection = {
          getCheckboxProps: item => ({ disabled: listDisabled || item.disabled }),
          onSelectAll(selected, selectedRows) {
            const treeSelectedKeys = selectedRows
              .filter(item => !item.disabled)
              .map(({ key }) => key);
            const diffKeys = selected
              ? difference(treeSelectedKeys, listSelectedKeys)
              : difference(listSelectedKeys, treeSelectedKeys);
            onItemSelectAll(diffKeys, selected);
          },
          onSelect({ key }, selected) {
            onItemSelect(key, selected);
          },
          selectedRowKeys: listSelectedKeys
        };
        return (
          <div style={{ width: "99%", paddingBottom: "3%" }}>
            <Table
              rowSelection={rowSelection}
              columns={columns}
              pagination={false}
              bordered={false}
              dataSource={filteredItems}
              className='table-setting-inside-transfer'
              size="small"
              style={{ width: "99%", cursor: screenMobile === true ? null : "pointer", paddingLeft: "2%" }}
              scroll={{
                y: screenMobile === true ? "15vh" : "33vh", // mobile
                // y: "33vh", // desktop
              }}
              locale={{
                emptyText: <div style={{ marginTop: "11%" }}>
                  <img src={NoDataImage} alt='' />
                  <p className='text-no-data'>Kéo thả từ mục bên trái sang phải để thêm, xóa hoặc sắp xếp lại thứ tự cột!</p>
                </div>,
              }}
              onRow={({ key, disabled: itemDisabled }) => ({
                onClick: () => {
                  if (itemDisabled || listDisabled) return;
                  onItemSelect(key, !listSelectedKeys.includes(key));
                }
              })}
            />
          </div>
        );
      }}
    </Transfer>
  );
};

const leftTableColumns = [
  {
    dataIndex: 'title',
    title: 'Tên cột'

  },
];
const rightTableColumns = [
  {
    dataIndex: 'title',
    title: 'Tên cột'
  }
];

const CustomTransfer = ({ mockData, targetKeys, setTargetKeys }) => {
  const filterOption = (inputValue, option) => option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
  const [mockDataCustom, setMockDataCustom] = useState([])

  const swapTransferLists = () => {
    const sourceList = document.querySelectorAll('.ant-transfer-list')[0];
    const targetList = document.querySelectorAll('.ant-transfer-list')[1];
    const operationDiv = document.querySelector('.ant-transfer-operation');

    if (sourceList && targetList && operationDiv) {
      const parent = sourceList.parentNode;
      parent.insertBefore(targetList, sourceList);
      parent.insertBefore(operationDiv, targetList.nextSibling);
    }
  };
  const customOperations = [<LeftOutlined />, <RightOutlined />];
  useEffect(() => {
    swapTransferLists();
  }, []);
  useEffect(() => {
    setMockDataCustom(mockData)
  }, [mockData]);

  return (
    <div className="custom-transfer">
      <TableTransfer
        dataSource={mockDataCustom}
        mockDataCustom={mockDataCustom}
        targetKeys={targetKeys}
        setTargetKeys={setTargetKeys}
        setMockDataCustom={setMockDataCustom}
        showSearch
        titles={[
          <span key={"Có sẵn"}>Danh mục có sẵn</span>,
          <span key={"Đã chọn"}>Danh mục đã chọn</span>
        ]}
        filterOption={filterOption}
        leftColumns={leftTableColumns}
        rightColumns={rightTableColumns}
        operations={customOperations}
      />
    </div>
  );
};

export default SettingDocument

