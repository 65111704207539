import { cityURL, districtsURL, inforCustomer, manageCustomer, postTemplateExportHD, templateExport, templateExportHD } from "../constants";
import { authAxios } from "./axiosClient";

export const GetTemplateDoc = (id, params = {}) => {
    const url = templateExportHD;
    return authAxios().get(url(id), { params });
};

export const GetListTemplate = (params = {}) => {
    const url = templateExport;
    return authAxios().get(url, { params });
};

export const PostTemplateDocApi = (params = {}) => {
    const url = postTemplateExportHD;
    return authAxios().post(url, params);
};

