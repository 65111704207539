import { Button, Col, Form, Input, Row, Select, Table, Tooltip, DatePicker } from 'antd'
import { useEffect, useState } from 'react'
import SearchIcon from "./../../../../images/image_manage_docs/search.svg"
import UploadIcon from "./../../../../images/image_manage_docs/UploadInvoice.svg"
import DetailIcon from "./../../../../images/image_manage_docs/ViewDetail.svg"
import DownloadStatement from "./../../../../images/image_manage_docs/DownloadStatement.svg"
import "./Statement.css"
import dayjs from 'dayjs';
import { Container } from '@mui/material';
import ModalUploadStatementAll from './ModalUploadStatementAll';
import { DownloadHandleStatementApi, GetInforStatementApi } from '../../../../api/StatementApi';
import { useSelector } from 'react-redux';
import ModalViewDetailStatement from './ModalViewDetailStatement';
import ResetFields from "./../../../../images/image_manage_docs/ResetFieldFilter.svg"
import StatementMB from './mobile/StatementMB';
import { GetListTaxAgent } from '../../../../api/manageInforUser';
import { screenMobile } from '../../../../Function';

const { RangePicker } = DatePicker;
const { Option } = Select;

function Statement() {
  const [form] = Form.useForm();
  const [datesAccounting, setDatesAccounting] = useState(null);
  const [datesUpload, setDatesUpload] = useState(null);
  const [isOpenModalUploadAll, setIsOpenModalUploadAll] = useState(false)
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const [dataDetail, setDataDetail] = useState()
  const [status, setStatus] = useState([])
  const [dataTaxAgent, setDataTaxAgent] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [nameCompany, setNameCompany] = useState("")
  const [fieldFilter, setFieldFilter] = useState({
    code_lookup: null,
    from_submit_date: null,
    to_submit_date: null,
    file_name: null,
    from_create_date: null,
    to_create_date: null,
    status: null
  })
  const [idCustomer, setIdCustomer] = useState("")

  const listRole = ["Admin Tax", "Admin Manager"]
  const checkRoleAdminTax = userInfo.role_id === 4 || userInfo.role_id === 3
  const checkIdCustomer = checkRoleAdminTax === false ? userInfo.id : idCustomer

  const [pager, setPager] = useState({
    pageSize: 10,
    count: 0,
    current: 1,
  });

  const fetchDataStatement = (params = {}) => {
    setLoadingTable(true)
    GetInforStatementApi(params).then(res => {
      setPager({
        current: params.page,
        pageSize: params.page_size,
        count: res.data.count,
      });
      setDataSource(res.data.results)
      setStatus(res.data.data_status)
      setLoadingTable(false)
    }).catch(err => {
      setLoadingTable(false)
      console.log(err)
    })
  }

  const loadData = (idCus) => {
    const id = setInterval(() => {
      fetchDataStatement({ id_customer: idCus, page: 1, page_size: pager.pageSize });
      clearInterval(id);
    }, 300);
    return () => clearInterval(id);
  }

  const fetchListTaxAgent = () => {
    GetListTaxAgent().then(res => {
      const data = res.data.filter(item => item.id === res.data[0].id)
      setNameCompany(data[0].name_organization)
      setDataTaxAgent(res.data)
      setIdCustomer(res.data[0].id)

      loadData(res.data[0].id)
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    if (listRole.includes(userInfo.role_name)) {
      fetchListTaxAgent();
    } else {
      setNameCompany(userInfo.customer_name)
      setIdCustomer(userInfo.customer_id)

      loadData(userInfo.customer_id)
    }
  }, []);

  const openModalDetail = (value) => {
    setDataDetail(value)
    setIsOpenModalDetail(true)
  }

  const closeModalDetail = () => {
    setIsOpenModalDetail(false)
  }

  const customColorStatus = (text) => {
    if (text.toLowerCase() === 'thất bại') {
      return { color: "#E55353", background: "rgba(229, 83, 83, 0.13)" }
    }
    else if (text.toLowerCase() === 'đang xử lý') {
      return { color: "#FAAB35", background: "rgba(250, 171, 53, 0.17)" }
    }
    else if (text.toLowerCase() === 'hoàn thành') {
      return { color: "#2EB85C", background: "rgba(87, 232, 135, 0.15)" }
    }
    else if (text.toLowerCase() === 'đang định khoản') {
      return { color: "#4873DE", background: "rgba(72, 115, 222, 0.13)" }
    }
  }

  const downloadFileSingleRecord = (value) => {
    const fnFileDownload = require("js-file-download");
    const filename = value.name_file_handle;
    DownloadHandleStatementApi({
      id_customer: idCustomer,
      id_statement: value.id,
    }).then((res) => {
      fnFileDownload(res.data, filename);
    }).catch((err) => {
    });
  }

  const columnsMobile = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: "center",
      ellipsis: true,
      width: 40,
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
    },
    {
      title: 'Thao tác',
      dataIndex: 'thao_tac',
      key: 'thao_tac',
      align: "center",
      ellipsis: true,
      width: 70,
      render: (text, record) => {
        return <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: "1.5ch" }}>
          {parseInt(record.status) === 4 ?
            <img style={{ cursor: "pointer" }} onClick={() => downloadFileSingleRecord(record)} alt='' src={DownloadStatement}></img>
            :
            <img style={{ opacity: "0.35" }} alt='' src={DownloadStatement}></img>
          }
        </Row>
      }
    },
    {
      title: 'Ngày tải lên',
      dataIndex: 'created_date',
      key: 'created_date',
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record, index) => (text !== null && text !== undefined) ? dayjs(text).format("DD-MM-YYYY") : null
    },

    {
      title: 'Mã chứng từ',
      dataIndex: 'code_lookup',
      key: 'code_lookup',
      align: "left",
      ellipsis: true,
      width: 200,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'name_status',
      key: 'name_status',
      align: "center",
      ellipsis: true,
      width: 120,
      render: (text, record) => {
        return (
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <p className='text-dinh-khoan' style={customColorStatus(text)}>{text}</p>
          </div>
        )
      }
    },
  ];

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      align: "center",
      ellipsis: true,
      width: 50,
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
    },
    {
      title: 'Thao tác',
      dataIndex: 'thao_tac',
      key: 'thao_tac',
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record) => {
        return <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: "1.5ch" }}>
          {parseInt(record.status) === 4 ?
            <>
              <button className='disable-button' onClick={() => openModalDetail(record)}>
                <img style={{ cursor: "pointer" }} src={DetailIcon} alt=''></img>
              </button>

              <button className='disable-button' onClick={() => downloadFileSingleRecord(record)}>
                <img style={{ cursor: "pointer" }} alt='' src={DownloadStatement}></img>
              </button>
            </>
            :
            <>
              <img style={{ opacity: "0.35" }} src={DetailIcon} alt=''></img>
              <img style={{ opacity: "0.35" }} alt='' src={DownloadStatement}></img>
            </>}
        </Row>
      }
    },
    {
      title: 'Ngày tải lên',
      dataIndex: 'created_date',
      key: 'created_date',
      align: "center",
      ellipsis: true,
      width: 120,
      render: (text, record, index) => (text !== null && text !== undefined) ? dayjs(text).format("DD-MM-YYYY") : null
    },
    {
      title: 'Ngày hoàn thành',
      dataIndex: 'submit_date',
      key: 'submit_date',
      align: "center",
      ellipsis: true,
      width: 150,

      render: (text, record, index) => (text !== null && text !== undefined) ? dayjs(text).format("DD-MM-YYYY") : null
    },

    {
      title: 'Tên sao kê',
      dataIndex: 'file_statement',
      key: 'file_statement',
      align: "left",
      ellipsis: true,
      render: (text, record, index) => {
        if (text.length > 0) {
          return text[0].name
        } else {
          return null
        }
      }
    },
    {
      title: 'Tên ngân hàng',
      dataIndex: 'data_bank',
      key: 'data_bank',
      align: "left",
      ellipsis: true,
      render: (text, record, index) => text.name_bank
    },
    {
      title: 'Mã chứng từ',
      dataIndex: 'code_lookup',
      key: 'code_lookup',
      align: "left",
      ellipsis: true,
      width: 150,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'name_status',
      key: 'name_status',
      align: "center",
      ellipsis: true,
      width: 170,
      render: (text, record) => {
        return (
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            {text === "Thất bại" ?
              <Tooltip placement="topRight" title={record.other1}>
                <p className='text-dinh-khoan' style={customColorStatus(text)}>{text}</p>
              </Tooltip>
              :
              <p className='text-dinh-khoan' style={customColorStatus(text)}>{text}</p>
            }
          </div>
        )
      }
    },
  ];

  const disabledDateAccounting = (current) => {
    if (!datesAccounting) {
      return false;
    }
    const tooLate = datesAccounting[0] && current.diff(datesAccounting[0], 'days') >= 366;
    const tooEarly = datesAccounting[1] && datesAccounting[1].diff(current, 'days') >= 366;
    return !!tooEarly || !!tooLate;
  };

  const disabledDateUpload = (current) => {
    if (!datesUpload) {
      return false;
    }
    const tooLate = datesUpload[0] && current.diff(datesUpload[0], 'days') >= 366;
    const tooEarly = datesUpload[1] && datesUpload[1].diff(current, 'days') >= 366;
    return !!tooEarly || !!tooLate;
  };

  const trimField = (text) => {
    if (text !== undefined && text !== null) {
      return text.trim()
    } else {
      return null
    }
  }

  const onFinish = (values) => {
    fetchDataStatement(
      {
        id_customer: checkIdCustomer,
        page: 1,
        page_size: pager.pageSize,
        code_lookup: trimField(values.code_lookup),
        from_submit_date: values.submit_date !== undefined && values.submit_date !== null ? dayjs(values.submit_date[0]).format("YYYY-MM-DD") : null,
        to_submit_date: values.submit_date !== undefined && values.submit_date !== null ? dayjs(values.submit_date[1]).format("YYYY-MM-DD") : null,
        file_name: trimField(values.file_name),
        from_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[0]).format("YYYY-MM-DD") : null,
        to_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[1]).format("YYYY-MM-DD") : null,
        status: JSON.stringify(values.status)
      }
    )
    setFieldFilter({
      code_lookup: trimField(values.code_lookup),
      from_submit_date: values.submit_date !== undefined && values.submit_date !== null ? dayjs(values.submit_date[0]).format("YYYY-MM-DD") : null,
      to_submit_date: values.submit_date !== undefined && values.submit_date !== null ? dayjs(values.submit_date[1]).format("YYYY-MM-DD") : null,
      file_name: trimField(values.file_name),
      from_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[0]).format("YYYY-MM-DD") : null,
      to_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[1]).format("YYYY-MM-DD") : null,
      status: JSON.stringify(values.status)
    })
  }

  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = 10;
    setPager({ ...currentPager });
    fetchDataStatement(
      {
        id_customer: checkIdCustomer,
        page: pagination.current,
        page_size: pagination.pageSize,
        code_lookup: fieldFilter.code_lookup,
        from_submit_date: fieldFilter.from_submit_date,
        to_submit_date: fieldFilter.to_submit_date,
        file_name: fieldFilter.file_name,
        from_create_date: fieldFilter.from_create_date,
        to_create_date: fieldFilter.to_create_date,
        status: fieldFilter.status
      }
    )
  };

  const openModalUploadAll = () => {
    setIsOpenModalUploadAll(true)
  }

  const closeModalUploadAll = () => {
    setIsOpenModalUploadAll(false)
  }

  const resetData = () => {
    form.resetFields();
    fetchDataStatement({
      page: 1,
      page_size: pager.pageSize,
      id_customer: checkIdCustomer,
    })

    setFieldFilter({
      code_lookup: null,
      from_submit_date: null,
      to_submit_date: null,
      file_name: null,
      from_create_date: null,
      to_create_date: null,
      status: null
    })
  }

  const chooseIdCustomer = (value, name) => {
    if (name !== undefined) {
      setNameCompany(name)
    } else {
      const data = dataTaxAgent.filter(item => item.id === value)
      setNameCompany(data[0].name_organization)
    }
    setIdCustomer(value)
    loadData(value)
  };

  return (

    <Container maxWidth="false" style={{ backgroundColor: "#fff", width: "97%", marginTop: "1%", height: "88vh" }}>
      {checkRoleAdminTax === true && dataTaxAgent.length > 0 ?
        <Row style={{ paddingTop: 10, display: "flex" }}>
          <span className='text-choose-company'>DOANH NGHIỆP : </span>
          <Select
            size={"middle"}
            style={{ width: "230px", float: "left", marginLeft: "12px" }}
            placeholder="Chọn doanh nghiệp"
            showSearch
            className='choose-company'
            optionFilterProp="children"
            onChange={(value) => chooseIdCustomer(value)}
            defaultValue={dataTaxAgent[0].id}
          >
            {dataTaxAgent.map((item, index) => (
              <Option key={item.id} value={item.id}>
                {item.name_organization}
              </Option>
            ))}
          </Select>
        </Row>
        : null}
      {screenMobile === false ?
        <div style={{ width: "100%", padding: "16px 0px", }}>
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}

            initialValues={{
              remember: true,
            }}
            className='text-label-manage-docs'
          >
            <Row>
              <Col span={6}>
                <Form.Item label="Ngày hoàn thành" name={"submit_date"}>
                  <RangePicker
                    disabledDate={disabledDateAccounting}
                    onCalendarChange={(val) => {
                      setDatesAccounting(val);
                    }}
                    locale={{
                      lang: {
                        locale: "en_US",
                        rangePlaceholder: ["Ngày bắt đầu", "Ngày kết thúc"],
                        yearFormat: "YYYY",
                        shortMonths: [
                          "Tháng 1",
                          "Tháng 2",
                          "Tháng 3",
                          "Tháng 4",
                          "Tháng 5",
                          "Tháng 6",
                          "Tháng 7",
                          "Tháng 8",
                          "Tháng 9",
                          "Tháng 10",
                          "Tháng 11",
                          "Tháng 12"
                        ],
                        shortWeekDays: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
                        ok: "OK",
                        monthBeforeYear: true,
                      }
                    }}
                    style={{ width: "100%" }}
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>
              </Col>
              <Col span={4} offset={1}>
                <Form.Item label="Trạng thái" name={"status"}>
                  <Select
                    id="code_district"
                    size={"middle"}
                    allowClear
                    mode='multiple'
                    className="SelectTTDN"
                    placeholder={"Chọn trạng thái"}
                    maxTagCount={"responsive"}
                    optionFilterProp="children"
                  >

                    {status.map((item, index) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}

                  </Select>
                </Form.Item>
              </Col>
              <Col span={4} offset={1}>
                <Form.Item label="Mã chứng từ" name={"code_lookup"}>
                  <Input onBlur={(e) => form.setFieldValue("code_lookup", e.target.value.trim())} placeholder='Nhập mã tra cứu'></Input>
                </Form.Item>
              </Col>
              <Col span={4} offset={1}>
                <Form.Item label="Tên sao kê" name={"file_name"}>
                  <Input onBlur={(e) => form.setFieldValue("file_name", e.target.value.trim())} placeholder='Nhập Tên sao kê'></Input>
                </Form.Item>
              </Col>

              <Col span={2} offset={1}>
                <Form.Item label=" ">
                  <Button htmlType='submit' className='btn-search'><img src={SearchIcon} alt=''></img></Button>
                  <Button htmlType='button' className='btn-search' onClick={resetData} style={{ marginRight: "10%" }}><img src={ResetFields} alt=''></img></Button>

                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <Form.Item label="Ngày tải lên" name={"create_date"}>
                  <RangePicker
                    disabledDate={disabledDateUpload}
                    onCalendarChange={(val) => {
                      setDatesUpload(val);
                    }}
                    locale={{
                      lang: {
                        locale: "en_US",
                        rangePlaceholder: ["Ngày bắt đầu", "Ngày kết thúc"],
                        yearFormat: "YYYY",
                        shortMonths: [
                          "Tháng 1",
                          "Tháng 2",
                          "Tháng 3",
                          "Tháng 4",
                          "Tháng 5",
                          "Tháng 6",
                          "Tháng 7",
                          "Tháng 8",
                          "Tháng 9",
                          "Tháng 10",
                          "Tháng 11",
                          "Tháng 12"
                        ],
                        shortWeekDays: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
                        ok: "OK",
                        monthBeforeYear: true,
                      }
                    }}
                    style={{ width: "100%" }}
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div style={{ width: "100%", position: "relative" }}>
            <Row>
              <Col span={8}></Col>
              <Col span={8} className='title-table-invoice-all'>DANH SÁCH SAO KÊ</Col>
              <Col span={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={openModalUploadAll} className='btn-invoice-all' style={{ background: "rgba(58, 183, 176, 0.15)", border: "unset" }}><img alt='' src={UploadIcon} style={{ marginRight: "3%" }}></img><span style={{ color: "rgb(58,183,176)", fontWeight: "bold" }}>TẢI LÊN</span></Button>
              </Col>
            </Row>
            <Table
              size='small'
              columns={columns}
              dataSource={dataSource}
              onChange={handleChange}
              style={{ marginTop: "1%", fontSize: 14 }}
              className='table-invoice-all'
              bordered
              loading={loadingTable}
              scroll={dataSource.length > 0 ? {
                y: "38vh",
                x: 1200,
              } : null}
              pagination={{
                current: pager.current,
                pageSize: pager.pageSize,
                total: pager.count,
                showSizeChanger: false,
                showTotal: (total) => `Tổng cộng: ${total}`
              }}
            />
          </div>
          {isOpenModalUploadAll ?
            <ModalUploadStatementAll
              open={isOpenModalUploadAll}
              close={closeModalUploadAll}
              nameCompany={nameCompany}
              idCustomer={idCustomer}
            />
            : null}

          {isOpenModalDetail ?
            <ModalViewDetailStatement
              open={isOpenModalDetail}
              close={closeModalDetail}
              dataDetail={dataDetail}
              idCustomer={idCustomer}
            />
            : null}
        </div>
        :
        <>
          <StatementMB
            columns={columnsMobile}
            dataSource={dataSource}
            handleChange={handleChange}
            pager={pager}
            openModalUploadAll={openModalUploadAll}
            fetchDataStatement={fetchDataStatement}
            setFieldFilter={setFieldFilter}
            disabledDateAccounting={disabledDateAccounting}
            setDatesAccounting={setDatesAccounting}
            status={status}
            disabledDateUpload={disabledDateUpload}
            setDatesUpload={setDatesUpload}
            loadingTable={loadingTable}
          />
          {isOpenModalUploadAll &&
            <ModalUploadStatementAll
              open={isOpenModalUploadAll}
              close={closeModalUploadAll}
            />
          }
        </>
      }
    </Container>
  )
}


export default Statement