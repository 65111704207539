import { authAxios } from "../api/axiosClient";
// import Cookies from 'universal-cookie';
import { deleteFile, downloadFileURL, forgotPassword, importFileURL, manageBankURL, masterFileURL, messageContact, renewPassURL, uploadFileDirect, userInfoURL } from "../constants";

export const usersApi = {
  getAll: (params) => {
    const url = "/api/users";
    return authAxios().get(url, { params });
  },
};

export const UserInfoUrlApi = (params = {}) => {
  //tắt
  const url = userInfoURL;
  return authAxios().get(url, params);
};

export const RenewPasswordApi = (tok, params = {}) => {
  //tắt 
  const url = renewPassURL;
  return authAxios().get(url(tok), params);
};

export const PostRenewPasswordApi = (tok, params = {}) => {
  //tắt 
  const url = renewPassURL;
  return authAxios().post(url(tok), params);
};

export const ForgotPasswordApi = (params = {}) => {
  const url = forgotPassword;
  return authAxios().post(url, params);
};

export const PostImportFileApi = (params = {}) => {
  const url = importFileURL;
  return authAxios().post(url, params);
};

export const GetDeleteFile = (params = {}) => {
  const url = deleteFile;
  return authAxios().get(url, { params });
};

export const GetListSPNHApi = (params = {}) => {
  const url = importFileURL;
  return authAxios().get(url, { params });
};

export const GetListMasterApi = (params = {}) => {
  const url = masterFileURL;
  return authAxios().get(url, { params });
};

export const GetFileDownloadApi = (params = {}) => {
  const url = downloadFileURL;
  return authAxios().get(url, { params });
};


export const GetListManageBankURLApi = (params = {}) => {
  const url = manageBankURL;
  return authAxios().get(url, { params });
};

export const PostUploadFileDirectApi = (params = {}) => {
  const url = uploadFileDirect;
  return authAxios().post(url, params);
};

export const PostMessageContactApi = (params = {}) => {
  const url = messageContact;
  return authAxios().post(url, params);
};
