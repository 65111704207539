import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ErrorImage from "../../../../../images/ErrorNotifiIcon.svg"

function Excel005({
  isOpenModalExcel005,
  setIsOpenModalExcel005,
  // nameImport,
  inforError,
}) {
  const handleCancel = () => {
    setIsOpenModalExcel005(false);
  };

  const MySwal = withReactContent(Swal);

  if (isOpenModalExcel005 === true) {
    handleCancel();
    MySwal.fire({
      imageUrl: ErrorImage,
      html: <i style={{ color: "red" }}>{inforError}</i>,
      customClass: {
        popup: "custom-notification",
        confirmButton: "btn-category-master",
        icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
      },
      position: "top",
      confirmButtonColor: "#3085d6",
      // confirmButtonColor: "#3ab7b0",
      confirmButtonText: "Xác nhận",
      // width: screenMobile === true ? "80%" : "20%",
    });
  }

  return <></>;
}

export default Excel005;
