import React from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ErrorImage from "../../../../../images/ErrorNotifiIcon.svg"


function Excel008({
  isOpenModalExcel008,
  setIsOpenModalExcel008,
  nameImport,
  infoError,
}) {
  const handleCancel = () => {
    localStorage.setItem("checkError", "true");
    setIsOpenModalExcel008(false);
  };

  const MySwal = withReactContent(Swal);

  if (isOpenModalExcel008 === true) {
    handleCancel();
    MySwal.fire({
      imageUrl: ErrorImage,
      title: <strong>{nameImport}</strong>,
      html: <i style={{ color: "red" }}>{infoError}</i>,
      customClass: {
        popup: "custom-notification",
        confirmButton: "btn-category-master",
        icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
      },
      position: "top",
      confirmButtonColor: "#3ab7b0",
      confirmButtonText: "Xác nhận",
      // width: screenMobile === true ? "80%" : "20%",
    });
  }

  return <></>;
}

export default Excel008;
