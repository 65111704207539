import { Button, Col, Form, Input, Modal, Row, Select, Table, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { DatePicker } from 'antd';
import DetailIcon from "./../../../../images/image_manage_docs/ViewDetail.svg"
import "./History.css"
import dayjs from 'dayjs';
import { Container } from '@mui/material';
import ModalViewDetailHistory from './ModalViewDetailHistory';
import { useSelector } from 'react-redux';
import { DownloadDetailHistoryApi, GetListHistoryApi } from '../../../../api/historyApi';
import SearchIcon from "./../../../../images/image_manage_docs/search.svg"
import ResetFields from "./../../../../images/image_manage_docs/ResetFieldFilter.svg"
import HistoryMobile from './mobile/HistoryMobile';
import { GetListTaxAgent } from '../../../../api/manageInforUser';
import UploadIcon from "./../../../../images/image_manage_docs/UploadInvoice.svg"
import { PostUploadFileDirectApi } from '../../../../api/usersApi';
import { openNotificationSweetAlert, screenMobile } from '../../../../Function';
import ErrorImage from "./../../../../images/ErrorNotifiIcon.svg"
import SuccessImage from "./../../../../images/SuccessNotiIcon.svg"
import UploadDraggerIcon from "../../../../images/image_manage_docs/UploadInModal.svg"
import DownloadStatement from "./../../../../images/image_manage_docs/DownloadStatement.svg"

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Dragger } = Upload;

function History() {
  const [form] = Form.useForm();
  const [datesAccounting, setDatesAccounting] = useState(null);
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false)
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const [dataSource, setDataSource] = useState([])
  const [status, setStatus] = useState([])
  const [nameStatusDetail, setNameStatusDetail] = useState("")
  const [idDetail, setIdDetail] = useState()
  const [idCustomer, setIdCustomer] = useState()
  const [loadingTable, setLoadingTable] = useState(false)
  const [dataTaxAgent, setDataTaxAgent] = useState([])
  const [isOpenModalUpload, setIsOpenModalUpload] = useState(false)
  const listRole = ["Admin Tax", "Admin Manager"]
  const [fieldFilter, setFieldFilter] = useState({
    type_upload: null,
    dataset_name: null,
    from_create_date: null,
    to_create_date: null,
    status: null,
  })
  const [nameCompany, setNameCompany] = useState("")

  const nguon_du_lieu = [
    { id: 0, name: "Mail" },
    { id: 1, name: "Upload từ VAC" }
  ]
  const [pager, setPager] = useState({
    pageSize: 10,
    count: 0,
    current: 1,
  });

  const checkIdCompany = typeof sessionStorage.getItem("idCompany") === "string"

  const openModalDetail = (value) => {
    setIsOpenModalDetail(true)
    setNameStatusDetail(value.name_status)
    setIdDetail(value.id)
    setIdCustomer(value.customer_id)
  }

  const customColorStatus = (text) => {
    if (text.toLowerCase() === 'thất bại') {
      return { color: "#E55353", background: "rgba(229, 83, 83, 0.13)" }
    }
    else if (text.toLowerCase() === 'đang xử lý') {
      return { color: "#FAAB35", background: "rgba(250, 171, 53, 0.17)" }
    }
    else if (text.toLowerCase() === 'hoàn thành') {
      return { color: "#2EB85C", background: "rgba(87, 232, 135, 0.15)" }
    }
    else if (text.toLowerCase() === 'đang định khoản') {
      return { color: "#4873DE", background: "rgba(72, 115, 222, 0.13)" }
    }
  }

  const columnsMobile = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'name',
      align: "center",
      ellipsis: true,
      width: 50,
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
    },

    {
      title: 'Thao tác',
      dataIndex: 'thao_tac',
      key: 'address',
      align: "center",
      ellipsis: true,
      width: 80,
      render: (text, record) => <img style={{ cursor: "pointer" }} onClick={() => openModalDetail(record)} src={DetailIcon} alt=''></img>
    },

    {
      title: 'Ngày tải lên',
      dataIndex: 'created_date',
      key: 'created_date',
      align: "center",
      ellipsis: true,
      width: 140,
      render: (text, record) => (text !== undefined && text !== null) ? dayjs(text).format("DD-MM-YYYY") : null
    },

    {
      title: 'Mã gói yêu cầu',
      dataIndex: 'dataset_name',
      key: 'dataset_name',
      align: "center",
      ellipsis: true,
      width: 150,
    },

    {
      title: 'Nguồn dữ liệu',
      dataIndex: 'type_upload',
      key: 'type_upload',
      align: "center",
      ellipsis: true,
      width: 170,
      render: (text, record) => {
        if (text === "0") {
          return "Mail"
        } else {
          return "Upload từ VAC"
        }
      }
    },

    {
      title: 'SL số hóa',
      dataIndex: '',
      key: '',
      align: "center",
      ellipsis: true,
      width: 150,
      render: (text, record) => {
        if (record.total_file_crowd === null) {
          return "0/" + record.file_count
        } else {
          return record.total_file_crowd + "/" + record.file_count
        }
      }
    },

    {
      title: 'SL hoàn thành',
      dataIndex: '',
      key: '',
      align: "center",
      ellipsis: true,
      width: 180,
      render: (text, record) => {
        if (record.total_file_done === null) {
          return "0/" + record.file_count
        } else {
          return record.total_file_done + "/" + record.file_count
        }
      }
    },

    {
      title: 'Trạng thái gói',
      dataIndex: 'name_status',
      key: 'name_status',
      align: "center",
      ellipsis: true,
      width: 170,
      render: (text, record) => {
        return (
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <p className='text-dinh-khoan' style={customColorStatus(text)}>{text}</p>
          </div>
        )
      }
    },
  ];

  const columns = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'name',
      align: "center",
      ellipsis: true,
      width: 50,
      render: (value, item, index) =>
        index + 1 + (pager.current - 1) * pager.pageSize,
    },

    {
      title: 'Thao tác',
      dataIndex: 'thao_tac',
      key: 'address',
      align: "center",
      ellipsis: true,
      width: 70,
      render: (text, record) => {
        return (
          <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: "1.5ch" }}>
            {checkViewDetail(record)}
            <img style={{ cursor: "pointer" }} onClick={() => downloadFile(record)} alt='' src={DownloadStatement}></img>
          </Row>
        )
      }
    },

    {
      title: 'Ngày tải lên',
      dataIndex: 'created_date',
      key: 'created_date',
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record) => (text !== undefined && text !== null) ? dayjs(text).format("DD-MM-YYYY") : null
    },
    {
      title: 'Mã gói yêu cầu',
      dataIndex: 'dataset_name',
      key: 'dataset_name',
      align: "center",
      ellipsis: true,
      width: 170,
    },
    {
      title: 'Nguồn dữ liệu',
      dataIndex: 'type_upload',
      key: 'type_upload',
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record) => {
        if (text === "0") {
          return "Mail"
        } else {
          return "Upload từ VAC"
        }
      }
    },

    {
      title: 'Loại chứng từ',
      dataIndex: 'note_type_clause',
      key: 'note_type_clause',
      align: "center",
      ellipsis: true,
      width: 150,
      render: (text, record, index) => typeDocument(text)
    },

    {
      title: 'SL số hóa',
      dataIndex: '',
      key: '',
      align: "center",
      ellipsis: true,
      width: 80,
      render: (text, record) => {
        if (record.type_upload !== "2") {
          if (record.total_file_crowd === null) {
            return "0/" + record.file_count
          } else {
            return record.total_file_crowd + "/" + record.file_count
          }
        } else {
          return "-"
        }
      }
    },

    {
      title: 'SL hoàn thành',
      dataIndex: '',
      key: '',
      align: "center",
      ellipsis: true,
      width: 100,
      render: (text, record) => {
        if (record.total_file_done === null) {
          return "0/" + record.file_count
        } else {
          return record.total_file_done + "/" + record.file_count
        }
      }
    },

    {
      title: 'Trạng thái gói',
      dataIndex: 'name_status',
      key: 'name_status',
      align: "center",
      ellipsis: true,
      width: 120,
      render: (text, record) => {
        return (<>
          <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <p className='text-dinh-khoan' style={customColorStatus(text)}>{text}</p>
          </div>
        </>)
      }
    },
  ];

  const typeDocument = (text) => {
    if (text === "SK") {
      return <span style={{ fontWeight: "bold", color: '#394b76' }}>Sao kê</span>
    } else if (text === "HD") {
      return <span style={{ fontWeight: "bold", color: '#394b76' }}>Hóa đơn</span>
    } else if (text === "HDDT") {
      return <span style={{ fontWeight: "bold", color: '#394b76' }}>Hóa đơn điện tử</span>
    } else if (text === "Khac") {
      return <span style={{ fontWeight: "bold", color: '#394b76' }}>Khác</span>
    } else if (text === null || text === "") {
      return <span style={{ fontWeight: "bold", color: '#394b76' }}>Chưa xác định</span>
    }
    else {
      return <span style={{ fontWeight: "bold", color: '#394b76' }}>{text}</span>
    }
  }

  const checkViewDetail = (record) => {
    if (record.name_status.toLowerCase() === "thất bại") {
      return (
        <img style={{ cursor: "pointer" }} onClick={() => openModalDetail(record)} src={DetailIcon} alt=''></img>
      )
    } else if (record.name_status.toLowerCase() === "đang xử lý") {
      return (
        <img style={{ opacity: 0.35 }} src={DetailIcon} alt=''></img>
      )
    } else if (record.name_status.toLowerCase() === "hoàn thành") {
      if (parseInt(record.total_status_handle) === 3) {
        return (
          <img style={{ cursor: "pointer" }} onClick={() => openModalDetail(record)} src={DetailIcon} alt=''></img>
        )
      } else if ((record.total_file_done === record.file_count) || (record.total_file_crowd === record.file_count && record.total_file_done !== record.file_count)) {
        return (
          <img style={{ opacity: 0.35 }} src={DetailIcon} alt=''></img>
        )
      }
      else {
        return (
          <img style={{ cursor: "pointer" }} onClick={() => openModalDetail(record)} src={DetailIcon} alt=''></img>
        )
      }
    } else {
      return (
        <img style={{ cursor: "pointer" }} onClick={() => openModalDetail(record)} src={DetailIcon} alt=''></img>
      )
    }
  }

  const downloadFile = (record) => {
    const fnFileDownload = require("js-file-download");
    const filename = "DanhSachFile_" + dayjs(new Date()).format("DDMMYYYY") + ".zip";
    DownloadDetailHistoryApi({
      id_customer: idCustomer,
      upload_id: record.id
    }).then((res) => {
      fnFileDownload(res.data, filename);
    }).catch((err) => {
    });
  }

  const fetchDataHistory = (params = {}) => {
    setLoadingTable(true)
    GetListHistoryApi(params).then(res => {
      setPager({
        current: params.page,
        pageSize: params.page_size,
        count: res.data.count,
      });

      setDataSource(res.data.results)
      setStatus(res.data.data_status)
      setLoadingTable(false)

    }).catch(err => {
      setLoadingTable(false)
    })
  }

  const loadData = (idCus) => {
    const id = setInterval(() => {
      fetchDataHistory({ id_customer: idCus, page: 1, page_size: pager.pageSize });
      clearInterval(id);
    }, 300);
    return () => clearInterval(id);
  }

  const fetchListTaxAgent = () => {
    GetListTaxAgent().then(res => {

      setDataTaxAgent(res.data)
      if (checkIdCompany) {
        const idCompany = sessionStorage.getItem("idCompany")
        const data = res.data.filter(item => item.id === parseInt(idCompany))
        setNameCompany(data[0].name_organization)
        loadData(parseInt(idCompany))
        setIdCustomer(idCompany)
      } else {
        const data = res.data.filter(item => item.id === res.data[0].id)
        setNameCompany(data[0].name_organization)
        loadData(res.data[0].id)
        setIdCustomer(res.data[0].id)
      }
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    if (listRole.includes(userInfo.role_name)) {
      fetchListTaxAgent();
    } else {
      setNameCompany(userInfo.customer_name)
      setIdCustomer(userInfo.customer_id)
      loadData(userInfo.customer_id)
    }
  }, []);

  const disabledDateAccounting = (current) => {
    if (!datesAccounting) {
      return false;
    }
    const tooLate = datesAccounting[0] && current.diff(datesAccounting[0], 'days') >= 366;
    const tooEarly = datesAccounting[1] && datesAccounting[1].diff(current, 'days') >= 366;
    return !!tooEarly || !!tooLate;
  };

  const onFinish = (values) => {
    fetchDataHistory(
      {
        id_customer: checkRoleAdminTax === false ? userInfo.customer_id : idCustomer,
        page: 1,
        page_size: pager.pageSize,
        dataset_name: values.dataset_name,
        type_upload: JSON.stringify(values.type_upload),
        from_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[0]).format("YYYY-MM-DD") : null,
        to_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[1]).format("YYYY-MM-DD") : null,
        status: JSON.stringify(values.status)
      }
    )
    setFieldFilter({
      dataset_name: values.dataset_name,
      type_upload: JSON.stringify(values.type_upload),
      from_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[0]).format("YYYY-MM-DD") : null,
      to_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[1]).format("YYYY-MM-DD") : null,
      status: JSON.stringify(values.status)
    })
  }


  const handleChange = (pagination) => {
    const currentPager = { ...pager };
    currentPager.current = pagination.current;
    currentPager.pageSize = 10;
    setPager({ ...currentPager });
    fetchDataHistory({
      id_customer: checkRoleAdminTax === false ? userInfo.customer_id : idCustomer,
      page: pagination.current,
      page_size: pagination.pageSize,
      type_upload: fieldFilter.type_upload,
      dataset_name: fieldFilter.dataset_name,
      from_create_date: fieldFilter.from_create_date,
      to_create_date: fieldFilter.to_create_date,
      status: fieldFilter.status,
      // search,
    });
  };

  const resetData = () => {
    form.resetFields();
    fetchDataHistory({
      page: 1,
      page_size: pager.pageSize,
      id_customer: checkRoleAdminTax === false ? userInfo.customer_id : idCustomer,
    })
    setFieldFilter({
      type_upload: null,
      dataset_name: null,
      from_create_date: null,
      to_create_date: null,
      status: null,
    })
  }

  const chooseIdCustomer = (value, name) => {
    setIdCustomer(value)
    if (name !== "") {
      if (typeof name === "string") {
        setNameCompany(name)
      } else if (typeof name === "object") {
        setNameCompany(name.children)
      }
    } else {
      const data = dataTaxAgent.filter(item => item.id === value)
      setNameCompany(data[0].name_organization)
    }

    loadData(value)
  };

  const openModalUploadAll = () => {
    setIsOpenModalUpload(true)
  }

  const checkRoleAdminTax = userInfo.role_id === 4 || userInfo.role_id === 3
  return (
    screenMobile === false ?
      <Container maxWidth="false" style={{ backgroundColor: "#fff", width: "97%", marginTop: "1%", height: "88vh" }}>
        {checkRoleAdminTax === true && dataTaxAgent.length > 0 ?
          <Row style={{ paddingTop: 10, display: "flex" }}>
            <span className='text-choose-company'>DOANH NGHIỆP : </span>
            <Select
              size={"middle"}
              style={{ width: "230px", float: "left", marginLeft: "12px" }}
              placeholder="Chọn doanh nghiệp"
              showSearch
              className='choose-company'
              optionFilterProp="children"
              onChange={chooseIdCustomer}
              defaultValue={checkIdCompany ? parseInt(sessionStorage.getItem("idCompany")) : dataTaxAgent[0].id}
            >
              {dataTaxAgent.map((item, index) => (
                <Option key={item.id} value={item.id}>
                  {item.name_organization}
                </Option>
              ))}
            </Select>
          </Row>
          : null}
        <div style={{ width: "100%", padding: "16px 0px" }}>
          <Form
            form={form}
            layout="vertical"
            autoComplete="off"
            onFinish={onFinish}
            initialValues={{
              remember: true,
            }}
            className='text-label-manage-docs'
          >
            <Row>
              <Col span={6}>
                <Form.Item label="Ngày tải lên" name={"create_date"}>
                  <RangePicker
                    disabledDate={disabledDateAccounting}
                    onCalendarChange={(val) => {
                      setDatesAccounting(val);
                    }}
                    locale={{
                      lang: {
                        locale: "en_US",
                        rangePlaceholder: ["Ngày bắt đầu", "Ngày kết thúc"],
                        yearFormat: "YYYY",
                        shortMonths: [
                          "Tháng 1",
                          "Tháng 2",
                          "Tháng 3",
                          "Tháng 4",
                          "Tháng 5",
                          "Tháng 6",
                          "Tháng 7",
                          "Tháng 8",
                          "Tháng 9",
                          "Tháng 10",
                          "Tháng 11",
                          "Tháng 12"
                        ],
                        shortWeekDays: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
                        ok: "OK",
                        monthBeforeYear: true,
                      }
                    }}
                    style={{ width: "100%" }}
                    format={"DD-MM-YYYY"}
                  />
                </Form.Item>
              </Col>
              <Col span={4} offset={1}>
                <Form.Item label="Nguồn dữ liệu" name={"type_upload"}>
                  <Select
                    id="code_district"
                    maxTagCount={"responsive"}
                    size={"middle"}
                    allowClear
                    mode='multiple'
                    className="SelectTTDN"
                    placeholder="Chọn nguồn dữ liệu"
                    optionFilterProp="children"
                  // disabled={canEdit}
                  >

                    {nguon_du_lieu.map((item, index) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4} offset={1}>
                <Form.Item label="Trạng thái gói" name={"status"}>
                  <Select
                    id="code_district"
                    size={"middle"}
                    allowClear
                    maxTagCount={"responsive"}
                    mode='multiple'
                    className="SelectTTDN"
                    placeholder="Chọn Trạng thái gói"
                    optionFilterProp="children"
                  >

                    {status.map((item, index) => (
                      <Option key={item.id} value={item.id}>
                        {item.name}
                      </Option>
                    ))}

                  </Select>
                </Form.Item>
              </Col>
              <Col span={4} offset={1}>
                <Form.Item label="Mã gói yêu cầu" name={"dataset_name"}>
                  <Input placeholder='Nhập mã gói yêu cầu'></Input>
                </Form.Item>
              </Col>
              <Col span={2} offset={1}>
                <Form.Item label=" ">
                  <Button htmlType='submit' className='btn-search'><img src={SearchIcon} alt=''></img></Button>
                  <Button htmlType='button' className='btn-search' onClick={resetData} style={{ marginRight: "10%" }}><img src={ResetFields} alt=''></img></Button>

                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div style={{ width: "100%", position: "relative" }}>
            <Row>
              <Col span={8}></Col>
              <Col span={8} className='title-table-invoice-all'>DANH SÁCH TẢI LÊN</Col>
              <Col span={8} style={{ display: "flex", justifyContent: "flex-end" }}>
              </Col>
            </Row>

            <Row>
              <Col span={8}></Col>
              <Col span={8}></Col>
              <Col span={8}>
                <Button onClick={openModalUploadAll} className='btn-invoice-all' style={{ background: "rgba(58, 183, 176, 0.15)", border: "unset", float: "right" }}><img alt='' src={UploadIcon} style={{ marginRight: "3%" }}></img><span style={{ color: "rgb(58,183,176)", fontWeight: "bold" }}>TẢI LÊN</span></Button>
              </Col>
            </Row>

            <Table
              size='small'
              columns={columns}
              dataSource={dataSource}
              onChange={handleChange}
              style={{ marginTop: "1%", fontSize: 14, position: "absolute", width: "100%" }}
              className='table-invoice-all'
              bordered
              loading={loadingTable}

              scroll={{
                y: "42vh",
                // x: 1400,
              }}
              pagination={{
                current: pager.current,
                pageSize: pager.pageSize,
                total: pager.count,
                showSizeChanger: false,
                showTotal: (total) => `Tổng cộng: ${total}`
              }}
            />
          </div>
          {isOpenModalDetail ?
            <ModalViewDetailHistory
              isOpenModalDetail={isOpenModalDetail}
              setIsOpenModalDetail={setIsOpenModalDetail}
              nameStatusDetail={nameStatusDetail}
              idDetail={idDetail}
              idCustomer={idCustomer}
            />
            : null}
        </div>
        {isOpenModalUpload ?
          <ModalUpload
            open={isOpenModalUpload}
            setIsOpenModalUpload={setIsOpenModalUpload}
            nameCompany={nameCompany}
            idCustomer={idCustomer}
            fetchDataHistory={fetchDataHistory}
            pager={pager}
          />
          : null}
      </Container>
      :

      <HistoryMobile
        columns={columnsMobile}
        dataSource={dataSource}
        handleChange={handleChange}
        pager={pager}
        isOpenModalDetail={isOpenModalDetail}
        setIsOpenModalDetail={setIsOpenModalDetail}
        nameStatusDetail={nameStatusDetail}
        idDetail={idDetail}
        idCustomer={idCustomer}
        disabledDateAccounting={disabledDateAccounting}
        setDatesAccounting={setDatesAccounting}
        nguon_du_lieu={nguon_du_lieu}
        status={status}
        setFieldFilter={setFieldFilter}
        fetchDataHistory={fetchDataHistory}
        loadingTable={loadingTable}
      />
  )
}

function ModalUpload({ open, setIsOpenModalUpload, nameCompany, idCustomer, fetchDataHistory, pager }) {
  const [form] = Form.useForm();
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [fileList, setFileList] = useState([])
  const onFinish = (values) => {

    let checkSize = 0;
    fileList.map(item =>
      checkSize += item.size
    )

    const size = checkSize / 1024 / 1024 > 50

    if (size === false) {
      setLoadingSubmit(true)
      let FormData = require("form-data");
      let data = new FormData();

      data.append("customer_id", idCustomer);
      data.append("type_clause", "");

      if (
        values.file_upload.fileList !== undefined &&
        values.file_upload.fileList.length !== 0
      ) {
        values.file_upload.fileList.forEach((element) => {
          data.append("file_upload", element.originFileObj);
        });
      }
      PostUploadFileDirectApi(data).then(res => {
        setLoadingSubmit(false)
        handleClose()
        fetchDataHistory({ id_customer: idCustomer, page: 1, page_size: pager.pageSize });
        openNotificationSweetAlert(SuccessImage, res.data.message)
      }).catch(err => {
        setLoadingSubmit(false)
        openNotificationSweetAlert(ErrorImage, err.data.message)
      })
    } else {
      openNotificationSweetAlert(ErrorImage, "Dung lượng file đã quá 50MB !!!")
    }
  }

  const handleClose = () => {
    if (loadingSubmit === false) {
      setIsOpenModalUpload(false);
      setFileList([])
    }
  }

  useEffect(() => {
    if (open === false) {
      form.resetFields();
    }
  }, [open]);

  const handleChange = (info) => {
    let newFileList = [...info.fileList];
    setFileList(newFileList);
  };
  return (

    <Modal open={open} footer={false} onCancel={handleClose} closable={false} width={"40%"} >
      <Row className='title-modal'>
        <span>Tải lên chứng từ</span>
      </Row>
      <div style={{ padding: "14px 0px" }}>
        <label className='label-form'>
          Tên tổ chức
          <Input readOnly value={nameCompany} />
        </label>
      </div>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        style={{ height: "auto" }}
      >
        <Form.Item
          name="file_upload"
          label=<span className='label-form'>Dữ liệu chứng từ</span>
          className='form-email'
          rules={[
            {
              required: true,
              message: "Vui lòng chọn file"
            }
          ]}
        >
          <Dragger
            accept={" .pdf, .jfif, .pjpeg, .jpeg, .pjp, .jpg, .png, .html, .shtml, .ehtml, .shtm, .htm"}
            name="file"
            openFileDialogOnClick={true}
            fileList={fileList}
            onChange={(info) => handleChange(info)}
            showUploadList={true}
            multiple
            style={{ height: "20vh", overflow: "auto" }}
            beforeUpload={(file) => {
              let checkNameFile = false;

              if (fileList.length > 0) {
                for (const element of fileList) {
                  if (file.name === element.name) {
                    checkNameFile = true
                    break;
                  }
                }
              }
              const isJpgOrPng =
                ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel.sheet.macroEnabled.12"].includes(file.type);
              return !isJpgOrPng && !checkNameFile ? false : Upload.LIST_IGNORE;
            }}
          >
            <img alt='' src={UploadDraggerIcon}></img>
            <p className="text-upload">
              Click hoặc kéo thả để chọn file
            </p>
            <p className="text-hint">
              ( Hỗ trợ các định dạng : .pdf, .jfif, .pjpeg, .jpeg, ...)
            </p>
          </Dragger>
        </Form.Item>
        <span className="text-upload">Dung lượng tải lên tối đa : 50 MB</span>
        <Form.Item>
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              // variant="contained"
              // type="success"
              htmlType="button"
              onClick={handleClose}
              loading={loadingSubmit}
              className='text-btn design-btn-cancel'
            >
              Hủy
            </Button>
            <Button
              variant="contained"
              type="success"
              htmlType="submit"
              loading={loadingSubmit}
              className='text-btn design-btn-submit'
            >
              Lưu
            </Button>
          </Col>
        </Form.Item>
      </Form>
    </Modal>

  )
}

export default History