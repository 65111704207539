import { Col, Form, Input, Modal, Row } from 'antd';

const ModalViewDetail = ({ open, setIsOpenModalDetail, dataDetail, nameCompany }) => {
  const handleCancel = () => {
    setIsOpenModalDetail(false)
  }

  const roleUser = () => {
    if (dataDetail.role_user_id === 1) {
      return "Quản lý / Doanhh nghiệp"
    } else if (dataDetail.role_user_id === 2) {
      return "Nhân viên"
    }
    else if (dataDetail.role_user_id === 3) {
      return "Quản lý vận hành"
    }
    else if (dataDetail.role_user_id === 4) {
      return "Đại lý thuế"
    }
  }

  return (
    <Modal className='modal-add-customer' width={800} open={open} onCancel={handleCancel} footer={false}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <span style={{ margin: 0, fontSize: 30, fontWeight: "bold", color: "#058DF4" }}>Chi tiết tài khoản</span>
      </div>
      <Form
        layout="vertical"
        autoComplete="off"
        initialValues={{
          remember: true,
        }}
        className='text-label-add-company'
        style={{ paddingTop: "1%" }}
      >
        <Row>
          <Col span={24}>
            <Form.Item label="Thuộc đại lý/ Công ty mẹ" name={"id_customer"}>
              <Input readOnly defaultValue={nameCompany} />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Tài khoản"
              name='username'
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tài khoản!",
                },
              ]}
            >
              <Input
                readOnly
                placeholder='Nhập tài khoản'
                defaultValue={dataDetail.username}
              >
              </Input>
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item
              label="Email đăng ký"
              name='email_registration'
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập email đăng ký!",
                },
                {
                  pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Email chưa đúng định dạng',
                },
              ]}
            >
              <Input
                placeholder='Nhập Email'
                readOnly
                defaultValue={dataDetail.email}
              >
              </Input>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Số điện thoại"
              name='numberphone'
              rules={[
                {
                  pattern: /^\d+$/,
                  message: "Số điện thoại chỉ được nhập bằng chữ số!",
                },
                {
                  max: 12,
                  message: "Chỉ được nhập tối đa 12 số",
                },
              ]}
            >
              <Input
                placeholder='Nhập số điện thoại'
                readOnly
                defaultValue={dataDetail.numberphone}
              >
              </Input>
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item
              label="Vị trí"
              name='work_position'
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập vị trí!",
                },
              ]}
            >
              <Input
                placeholder='Nhập vị trí'
                readOnly
                defaultValue={dataDetail.work_position}
              >
              </Input>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Vai trò"
              name='role_user_id'
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn vai trò!",
                },
              ]}
            >
              <Input
                readOnly
                defaultValue={roleUser}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default ModalViewDetail