import * as React from "react";

import { useState } from "react";
import { ColorModeContext, useMode } from "../theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
// import HeaderWeb from "./Header/HeaderWeb";
import "./Layout.css"

import SideBarUser from "./sidebar/SideBarUser";

const HeaderWeb = React.lazy(() => import('./Header/HeaderWeb'));


const CustomLayoutFC = ({ children }) => {
    const [isSidebar, setIsSidebar] = useState(false);
    const [theme, colorMode] = useMode();
    const [valueTabs, setValueTabs] = useState(null);    
    const [menuCollapse, setMenuCollapse] = useState(true)

    const closeSidebar = () => {
        setMenuCollapse(true)
    }

    return (
        <>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <div className="app" style={{ display: "flex" }}>
                        <SideBarUser
                            isSidebar={isSidebar}
                            valueTabs={valueTabs}
                            setIsSidebar={setIsSidebar}
                            menuCollapse = {menuCollapse}
                            setMenuCollapse = {setMenuCollapse}
                        />
                        
                        <main className="content1" onTouchStart={closeSidebar}>
                            <HeaderWeb
                                setIsSidebar={setIsSidebar}
                                setMenuCollapse = {setMenuCollapse}
                                isSidebar={isSidebar}
                                valueTabs={valueTabs}
                                setValueTabs={setValueTabs}
                            />
                            <div className="container-fluid" style={{ maxWidth: "100%" }}>
                                {children}
                            </div>
                        </main>
                    </div>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </>
    );
};

export const CustomLayout = ({ isLogged, children }) => {
    const onUnAuth = () => {
        localStorage.setItem('loginRedirect', this.props.location.pathname)
        return "Chưa đăng nhập";
    };

    return isLogged ? <CustomLayoutFC children={children} /> : onUnAuth();

    // Test
    // return isLogged ? <CustomLayoutFC children={children} /> : <CustomLayoutFC children={children} />;
};
