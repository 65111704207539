import React from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ErrorImage from "../../../../../images/ErrorNotifiIcon.svg"


function Excel002({
  isOpenModalExcel002,
  setIsOpenModalExcel002,
  nameImport,
  infoError,
}) {
  const handleCancel = () => {
    localStorage.setItem("checkError", "true");
    setIsOpenModalExcel002(false);
  };
  const MySwal = withReactContent(Swal);

  if (isOpenModalExcel002 === true) {
    handleCancel();
    MySwal.fire({
      title: <h5 style={{margin: 0}}>{nameImport}</h5>,
      html: <i style={{ color: "red" }}>{infoError}</i>,
      // imageUrl: ImageExcel002,
      imageUrl: ErrorImage,
      customClass: {
        popup: "custom-notification",
        icon: "my-custom-icon-class", // Thêm class tùy chỉnh cho biểu tượng
        title: "my-custom-title-class",
        confirmButton: "btn-category-master",
      },
      position: "top",
      confirmButtonColor: "#3ab7b0",
      confirmButtonText: "Xác nhận",
      // width: screenMobile === true ? "80%" : "40%",
    });
  }

  return <></>;
}

export default Excel002;
