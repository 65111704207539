import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { PutInforUser } from '../../../../../api/manageInforUser';
import { openNotificationSweetAlert } from '../../../../../Function';
import ErrorImage from "../../../../../images/ErrorNotifiIcon.svg"
import SuccessImage from "../../../../../images/SuccessNotiIcon.svg"

const { Option } = Select;

const ModalEdit = ({ open, setIsOpenModalEdit, dataDetail, nameCompany, fetchListUser, idTaxAgent, pager }) => {
  const [form] = Form.useForm()
  const userInfo = useSelector((state) => state.getUserInfo.userInfo);
  const [loadingBtnSubmit, setLoadingBtnSubmit] = useState(false)
  const roleAdminTax = userInfo.role_id === 4
  const checkUser = dataDetail.id === userInfo.id
  const mockRule = !roleAdminTax ?
    [
      {
        id: 1,
        name: "Quản lý / Doanh nghiệp"
      },
      {
        id: 2,
        name: "Nhân viên"
      },
      {
        id: 4,
        name: "Đại lý thuế"
      }
    ]

    :

    [
      {
        id: 1,
        name: "Quản lý / Doanh nghiệp"
      },
      {
        id: 2,
        name: "Nhân viên"
      },
    ]
  const handleCancel = () => {
    setIsOpenModalEdit(false)
  }

  const onFinish = (values) => {
    let idRole = ''
    switch (values.role_user_id) {
      case "Quản lý / Doanhh nghiệp":
        idRole = 1;
        break;
      case "Nhân viên":
        idRole = 2;
        break;
      case "Quản lý vận hành":
        idRole = 3;
        break;
      case "Đại lý thuế":
        idRole = 4;
        break;
      default:
        idRole = "";
    }

    const valueRoleId = !checkUser ? idRole : dataDetail.role_user_id

    setLoadingBtnSubmit(true)
    let FormData = require("form-data");
    let data = new FormData();
    data.append("id_user", dataDetail.id);
    data.append("is_active", dataDetail.is_active);
    data.append("taxagent_user_id", idTaxAgent);

    data.append("email_registration", values.email_registration);
    data.append("numberphone", values.numberphone);
    data.append("work_position", values.work_position);
    data.append("role_user_id", valueRoleId);
    PutInforUser(data).then(res => {
      setLoadingBtnSubmit(false)
      setIsOpenModalEdit(false)
      openNotificationSweetAlert(SuccessImage, res.data.message)
      fetchListUser(
        {
          id_customer: userInfo.customer_id,
          search: "",
          page: pager.current,
          page_size: pager.pageSize,
        }
      )
    }).catch(err => {
      setLoadingBtnSubmit(false)
      openNotificationSweetAlert(ErrorImage, err.data.message)
    })
  }

  useEffect(() => {
    let data = ''
    switch (userInfo.role_id) {
      case 1:
        data = "Quản lý / Doanhh nghiệp";
        break;
      case 2:
        data = "Nhân viên";
        break;
      case 3:
        data = "Quản lý vận hành";
        break;
      case 4:
        data = "Đại lý thuế";
        break;
      default:
        data = "";
    }
    form.setFieldsValue({
      username: dataDetail.username,
      email_registration: dataDetail.email,
      numberphone: dataDetail.numberphone,
      work_position: dataDetail.work_position,
      role_user_id: !checkUser && userInfo.role_id !== 1 ? dataDetail.role_user_id : !checkUser && userInfo.role_id === 1 ? "Nhân viên" : data
    })
  }, []);

  return (
    <Modal className='modal-add-customer' width={800} open={open} onCancel={handleCancel} footer={false}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <span style={{ margin: 0, fontSize: 30, fontWeight: "bold", color: "#058DF4" }}>Chỉnh sửa tài khoản</span>
      </div>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        initialValues={{
          remember: true,
        }}
        className='text-label-add-company'
        style={{ paddingTop: "1%" }}
      >
        <Row>
          <Col span={24}>
            <Form.Item label="Thuộc đại lý/ Công ty mẹ" name={"id_customer"}>
              <Input disabled defaultValue={nameCompany} />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Tài khoản"
              name='username'
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập tài khoản!",
                },
              ]}
            >
              <Input disabled defaultValue={dataDetail.username} />
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item
              label="Email đăng ký"
              name='email_registration'
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập email đăng ký!",
                },
                {
                  pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                  message: 'Email chưa đúng định dạng',
                },
              ]}
            >
              <Input
                placeholder='Nhập Email'
                onBlur={(e) => {
                  form.setFieldsValue({ email_registration: e.target.value.trim() });
                }}
              >
              </Input>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Số điện thoại"
              name='numberphone'
              rules={[
                {
                  pattern: /^\d+$/,
                  message: "Số điện thoại chỉ được nhập bằng chữ số!",
                },
                {
                  max: 12,
                  message: "Chỉ được nhập tối đa 12 số",
                },
              ]}
            >
              <Input
                placeholder='Nhập số điện thoại'
                onBlur={(e) => {
                  form.setFieldsValue({ numberphone: e.target.value.trim() });
                }}
              >
              </Input>
            </Form.Item>
          </Col>
          <Col span={11} offset={2}>
            <Form.Item
              label="Vị trí"
              name='work_position'
              rules={[
                {
                  required: true,
                  message: "Vui lòng nhập vị trí!",
                },
              ]}
            >
              <Input
                placeholder='Nhập vị trí'
                onBlur={(e) => {
                  form.setFieldsValue({ work_position: e.target.value.trim() });
                }}
              >
              </Input>
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Vai trò"
              name='role_user_id'
              rules={[
                {
                  required: true,
                  message: "Vui lòng chọn vai trò!",
                },
              ]}
            >
              {!checkUser && userInfo.role_id !== 1 ?
                <Select
                  id="code_district"
                  size={"middle"}
                  allowClear
                  className="SelectTTDN"
                  optionFilterProp="children"
                  placeholder="Chọn vai trò"
                >
                  {mockRule.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
                :
                <Input disabled />
              }
            </Form.Item>
          </Col>
        </Row>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button

            htmlType="button"
            className='text-btn design-btn-cancel'

            onClick={handleCancel}
          >
            Hủy
          </Button>
          <Button
            // variant="contained"
            // type="success"
            htmlType="submit"
            // disabled={loadingButtonSave}
            className='text-btn design-btn-submit'
            loading={loadingBtnSubmit}
          >
            Lưu
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default ModalEdit