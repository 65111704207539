import { Button, Col, Form, Input, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { EditExpenseAccountApi, GetExpenseAccountApi } from '../../../../../api/categoryMaster';
import EditIcon from "./../../../../../images/EditIcon.svg"
import DeleteIcon from "./../../../../../images/DeleteIcon.svg"
import ErrorImage from "../../../../../images/ErrorNotifiIcon.svg"
import SuccessImage from "../../../../../images/SuccessNotiIcon.svg"
import MinusIcon from "../../../../../images/MinusIcon.svg"

import { openNotificationSweetAlert } from '../../../../../Function';

function SheetBuy({ idCustomer }) {
    const [form] = Form.useForm()
    const [dataExpenseAccount, setDataExpenseAccount] = useState([])
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1)
    const [checkScroll, setCheckScroll] = useState(false)

    const [listIndexEdit, setListIndexEdit] = useState([])
    const [listIndexDelete, setListIndexDelete] = useState([])
    const [listIndexFormDelete, setListIndexFormDelete] = useState([])

    const [loadingBtn, setLoadingBtn] = useState(false)
    const [totalPage, setTotalPage] = useState(1)
    const pageSize = 50

    const columns = [
        {
            title: '#',
            dataIndex: '#',
            key: '#',
            align: "center",
            ellipsis: true,
            width: 40,
            render: (text, record, index) =>
                index + 1

        },
        {
            title: 'Account',
            dataIndex: 'Account',
            key: 'Account',
            align: "center",
            ellipsis: true,
            width: 50,
            render: (text, record, index) => (
                formInputEdit(index, text, 'Account', record)
            )
        },
        {
            title: 'Account Name',
            dataIndex: 'Account Name',
            key: 'Account Name',
            align: "left",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => (
                formInputEdit(index, text, 'Account Name', record)
            )
        },
        {
            title: 'KeyWord',
            dataIndex: 'KeyWord',
            key: 'KeyWord',
            align: "left",
            ellipsis: true,
            width: 200,
            render: (text, record, index) => (
                formInputEdit(index, text, 'KeyWord', record)
            )
        },
        {
            title: 'Supplier ID',
            dataIndex: 'Supplier ID',
            key: 'Supplier ID',
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => (
                formInputEdit(index, text, 'Supplier ID', record)
            )
        },
        {
            title: 'Supplier Name',
            dataIndex: 'Supplier Name',
            key: 'Supplier Name',
            align: "left",
            ellipsis: true,
            width: 150,
            render: (text, record, index) => (
                formInputEdit(index, text, 'Supplier Name', record)
            )
        },
        {
            title: 'Supplier Tax Code',
            dataIndex: 'Supplier Tax Code',
            key: 'Supplier Tax Code',
            align: "center",
            ellipsis: true,
            width: 100,
            render: (text, record, index) => (
                formInputEdit(index, text, 'Supplier Tax Code', record)
            )
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            align: "center",
            ellipsis: true,
            width: 50,
            render: (text, record, index) => (
                (!listIndexEdit.includes(record.index) && !listIndexFormDelete.includes(record.index)) ?
                    <>
                        {record.type !== "add" ?
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: "1ch" }}>
                                <img onClick={() => showFormEdit(index, record)} style={{ cursor: "pointer" }} src={EditIcon} alt=''></img>
                                <img onClick={() => deleteRecord(record['index'], record)} style={{ cursor: "pointer" }} alt='' src={DeleteIcon}></img>
                            </div>
                            :
                            <>
                                <div className='item-center'>
                                    <Button onClick={() => deleteRecordAdd(index)}><img alt='' src={MinusIcon}></img></Button>
                                </div>
                            </>
                        }
                    </>
                    : null
            )
        },
    ];

    const formInputEdit = (index, text, dataIndex, record) => {
        if (record.type === "add") {
            return <Form.Item
                name={["data_add", index, dataIndex]}
                label={""}
                rules={[{ required: true, message: "" }]}
                key={dataIndex}
            >
                <Input defaultValue={text}></Input>
            </Form.Item>
        } else
            if (listIndexEdit.includes(record["index"])) {
                return (
                    <Form.Item
                        name={["data_edit", index, dataIndex]}
                        label={""}
                        rules={[{ required: true, message: "" }]}
                        key={dataIndex}
                    >
                        <Input defaultValue={text}></Input>
                    </Form.Item>
                )
            } else {
                return text
            }
    }

    const onFinish = (values) => {
        const dataEdit = form.getFieldValue("data_edit")
        const dataAdd = form.getFieldValue("data_add")
        setLoadingBtn(true)
        let filterData = null
        let filterDataAdd = []

        if (dataEdit !== undefined) {
            filterData = dataEdit.filter(item => item !== undefined && listIndexEdit.includes(item.index))
        }
        if (dataAdd !== undefined) {
            for (let i = 0; i < dataAdd.length; i++) {
                filterDataAdd.push({
                    "#": i + 1,
                    "Account": dataAdd[i]['Account'],
                    "Account Name": dataAdd[i]['Account Name'],
                    "KeyWord": dataAdd[i]['KeyWord'],
                    "Supplier ID": dataAdd[i]['Supplier ID'],
                    "Supplier Name": dataAdd[i]['Supplier Name'],
                    "Supplier Tax Code": dataAdd[i]['Supplier Tax Code']
                })
            }
        }

        var FormData = require("form-data");
        var data = new FormData();

        data.append("id_customer", idCustomer);
        data.append("data_add", JSON.stringify(filterDataAdd));
        data.append("data_edit", JSON.stringify(filterData));
        data.append("list_index_delete", listIndexDelete);
        EditExpenseAccountApi(data).then(res => {
            openNotificationSweetAlert(SuccessImage, res.data.message)
            setLoadingBtn(false)
            reloadData()

        }).catch(err => {
            openNotificationSweetAlert(ErrorImage, err.data.message)
            setLoadingBtn(false)
        })
    }

    const reloadData = () => {
        setListIndexEdit([])
        setListIndexDelete([])
        setListIndexFormDelete([])
        form.setFieldValue("data_edit", undefined)
        form.setFieldValue("data_add", undefined)
        fetchDataExpenseAccount({ id_customer: idCustomer, page: 1, page_size: pageSize })
    }

    const showFormEdit = (index, record) => {
        form.setFieldValue(["data_edit", index, "Account"], record["Account"])
        form.setFieldValue(["data_edit", index, "Account Name"], record["Account Name"])
        form.setFieldValue(["data_edit", index, "KeyWord"], record["KeyWord"])
        form.setFieldValue(["data_edit", index, "Supplier ID"], record["Supplier ID"])
        form.setFieldValue(["data_edit", index, "Supplier Name"], record["Supplier Name"])
        form.setFieldValue(["data_edit", index, "Supplier Tax Code"], record["Supplier Tax Code"])
        form.setFieldValue(["data_edit", index, "index"], record["index"])
        const listIndex = listIndexEdit
        setListIndexEdit([...listIndex, record["index"]])
    }

    const deleteRecord = (index, record) => {
        const listIndex = listIndexDelete
        const listIndexForm = listIndexFormDelete
        setListIndexDelete([...listIndex, record['index']])
        setListIndexFormDelete([...listIndexForm, index])
    }

    const fetchDataExpenseAccount = (params = {}) => {
        if (loading) return;
        setLoading(true);
        GetExpenseAccountApi(params).then(res => {
            setDataExpenseAccount(res.data.results.data_expense_account)
            form.setFieldValue("data_edit", res.data.results.data_expense_account)
            setLoading(false)
            setTotalPage(Math.floor(res.data.count / pageSize) + 1)
        }).catch(err => {
            setLoading(false)
        })
    }

    useEffect(() => {
        var tableContent = document.querySelector('.table-buy .ant-table-body');
        tableContent.addEventListener('scroll', (event) => {
            let maxScroll = event.target.scrollHeight - event.target.clientHeight;
            let currentScroll = event.target.scrollTop;

            if (currentScroll === maxScroll - 1) {
                currentScroll = currentScroll + 1
            }

            if (currentScroll === maxScroll) {
                setCheckScroll(true)
            }
        })
        fetchDataExpenseAccount({ id_customer: idCustomer, page: 1, page_size: pageSize })
    }, []);

    useEffect(() => {
        if (checkScroll === true && page < totalPage) {
            loadData()
        }
    }, [checkScroll]);

    const loadData = () => {
        if (loading) return;
        setLoading(true);
        GetExpenseAccountApi({
            id_customer: idCustomer,
            page: page + 1,
            page_size: pageSize
        }).then(res => {
            const arrDara = dataExpenseAccount.concat(res.data.results.data_expense_account)
            setDataExpenseAccount(arrDara)
            setLoading(false)
            setPage(page + 1)
            setCheckScroll(false)
        }).catch(err => {
            setLoading(false)
        })
    }

    const addFirstItem = () => {
        form.setFieldValue(["data_add", 0, "Account"], '')
        form.setFieldValue(["data_add", 0, "Account Name"], '')
        form.setFieldValue(["data_add", 0, "KeyWord"], '')
        form.setFieldValue(["data_add", 0, "Supplier ID"], '')
        form.setFieldValue(["data_add", 0, "Supplier Name"], '')
        form.setFieldValue(["data_add", 0, "Supplier Tax Code"], '')
        form.setFieldValue(["data_add", 0, "type"], 'add')
    }

    const addRecord = () => {
        const newData = {
            "Account": '',
            "Account Name": '',
            "KeyWord": '',
            "Supplier ID": '',
            "Supplier Name": '',
            "Supplier Tax Code": '',
            type: "add",
        };
        let arrData = []
        if (form.getFieldValue("data_add") === undefined) {
            arrData = form.getFieldValue("data_add")
            addFirstItem()
        } else {
            arrData = form.getFieldValue("data_add")
            for (let i = 0; i < arrData.length + 1; i++) {
                if (i === 0) {
                    addFirstItem()
                } else {
                    form.setFieldValue(["data_add", i, "Account"], arrData[i - 1]["Account"])
                    form.setFieldValue(["data_add", i, "Account Name"], arrData[i - 1]["Account Name"])
                    form.setFieldValue(["data_add", i, "KeyWord"], arrData[i - 1]["KeyWord"])
                    form.setFieldValue(["data_add", i, "Supplier ID"], arrData[i - 1]["Supplier ID"])
                    form.setFieldValue(["data_add", i, "Supplier Name"], arrData[i - 1]["Supplier Name"])
                    form.setFieldValue(["data_add", i, "Supplier Tax Code"], arrData[i - 1]["Supplier Tax Code"])
                    form.setFieldValue(["data_add", i, "type"], 'add')
                }
            }
        }

        resizeDataEdit()
        setDataExpenseAccount([newData, ...dataExpenseAccount]);
    }

    const resizeDataEdit = () => {
        const dataEdit = form.getFieldValue("data_edit")
        if (listIndexEdit.length > 0) {
            dataEdit.unshift({
                "Account": '',
                "Account Name": '',
                "KeyWord": '',
                "Supplier ID": '',
                "Supplier Name": '',
                "Supplier Tax Code": '',
            })
        }
        form.setFieldValue('data_edit', dataEdit)
    }

    const deleteRecordAdd = async (index) => {
        const mockData = dataExpenseAccount
        const datas = form.getFieldValue("data_add")

        mockData.splice(0, datas.length)
        datas.splice(index, 1)

        setDataExpenseAccount([...datas, ...mockData])
        form.setFieldValue("data_add", datas)

        const dataEdit = form.getFieldValue("data_edit")
        dataEdit.shift()
        form.setFieldValue("data_edit", dataEdit)
    }

    return (
        <>
            <Row>
                <Col span={4} style={{ display: "flex", justifyContent: "flex-start" }}>

                </Col>
                <Col span={16} className='title-table-detail-statement'>DANH MỤC TÀI KHOẢN MUA VÀO</Col>
                <Col span={4}></Col>
            </Row>

            <div style={{ overflowY: 'auto' }}>
                <Button disabled = {loading} onClick={addRecord}>Thêm dòng</Button>
                <Form
                    onFinish={onFinish}
                    form={form}
                    className='form-edit-invoice'
                >

                    <Table className="table-setting-doc table-buy" loading={loading}
                        scroll={{
                            y: "60vh",
                        }}
                        style={{ paddingTop: "1%", height: "auto" }}
                        pagination={false}
                        bordered
                        dataSource={dataExpenseAccount}
                        columns={columns}
                        size='middle'
                        rowClassName={(record, index) => {
                            if (listIndexFormDelete.includes(record.index)) {
                                return "bg-delete";
                            } else {
                                return null;
                            }
                        }}
                    />

                    {listIndexEdit.length > 0 || listIndexDelete.length > 0 || (form.getFieldValue('data_add') !== undefined && form.getFieldValue('data_add').length > 0) ?
                        <Button
                            variant="contained"
                            type="success"
                            htmlType="submit"
                            className='text-btn design-btn-submit'
                            style={{ marginTop: 10, float: "right" }}
                            loading={loadingBtn}
                        >
                            Lưu
                        </Button>

                        : null}
                </Form>

            </div>
        </>
    )
}

export default SheetBuy