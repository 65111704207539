import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Table } from 'antd'
import React, { useState } from 'react'
import UploadIcon from "./../../../../../images/image_manage_docs/UploadInvoice.svg"
import ViewFilter from "../../../../../images_mobile/ViewModalFilter.svg"

import { Container } from '@mui/material';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { Option } = Select;


function StatementMB({
    columns,
    dataSource,
    handleChange,
    pager,
    openModalUploadAll,
    fetchDataStatement,
    setFieldFilter,
    disabledDateAccounting,
    setDatesAccounting,
    status,
    disabledDateUpload,
    setDatesUpload,
    loadingTable
}) {

    const [isOpenModalFilter, setIsOpenModalFilter] = useState(false)
    const showModalFilter = () => {
        setIsOpenModalFilter(true)
    }
    return (
        <>
            <div style={{ width: "100%", padding: "16px 0px", }}>
                <div style={{ width: "100%", position: "relative" }}>
                    <Row>
                        <Col span={24} className='title-table-invoice-all'>DANH SÁCH SAO KÊ</Col>
                    </Row>
                    <Row>
                        <Col span={8}>
                            <Button onClick={showModalFilter} className='btn-invoice-all' style={{ marginRight: "3%", background: "rgba(50, 31, 219, 0.12)", border: "unset" }}><img alt='' src={ViewFilter}></img></Button>

                        </Col>
                        <Col span={8}>

                        </Col>
                        <Col span={8} style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Button onClick={openModalUploadAll} className='btn-invoice-all' style={{ background: "rgba(58, 183, 176, 0.15)", border: "unset" }}><img alt='' src={UploadIcon}></img></Button>
                        </Col>
                    </Row>
                    <Table
                        // rowSelection = {rowSelection}
                        size='small'
                        columns={columns}
                        dataSource={dataSource}
                        onChange={handleChange}
                        style={{ marginTop: "3%", fontSize: 14, position: "absolute" }}
                        className='table-invoice-all'
                        bordered
                        loading={loadingTable}
                        scroll={{
                            x: window.screen.availWidth,
                        }}
                        pagination={{
                            current: pager.current,
                            pageSize: pager.pageSize,
                            total: pager.count,
                            showSizeChanger: false,
                            showTotal: (total, range) => `Tổng cộng: ${total}`
                        }}
                    />
                </div>
            </div>
            <ModalFilter
                open={isOpenModalFilter}
                setIsOpenModalFilter={setIsOpenModalFilter}
                fetchDataStatement={fetchDataStatement}
                setFieldFilter={setFieldFilter}
                pager={pager}
                disabledDateAccounting={disabledDateAccounting}
                setDatesAccounting={setDatesAccounting}
                status={status}
                disabledDateUpload={disabledDateUpload}
                setDatesUpload={setDatesUpload}
            />
        </>
    )
}

const ModalFilter = (
    { open,
        setIsOpenModalFilter,
        fetchDataStatement,
        pager,
        setFieldFilter,
        disabledDateAccounting,
        setDatesAccounting,
        status,
        disabledDateUpload,
        setDatesUpload,
    }) => {
    const userInfo = useSelector((state) => state.getUserInfo.userInfo);
    const [form] = Form.useForm();

    const handleCancel = () => {
        setIsOpenModalFilter(false)
    }
    const trimField = (text) => {
        if (text !== undefined && text !== null) {
            return text.trim()
        } else {
            return null
        }
    }
    const onFinish = (values) => {
        fetchDataStatement(
            {
                id_customer: userInfo.customer_id,
                page: 1,
                page_size: pager.pageSize,
                code_lookup: trimField(values.code_lookup),
                from_submit_date: values.submit_date !== undefined && values.submit_date !== null ? dayjs(values.submit_date[0]).format("YYYY-MM-DD") : null,
                to_submit_date: values.submit_date !== undefined && values.submit_date !== null ? dayjs(values.submit_date[1]).format("YYYY-MM-DD") : null,
                file_name: trimField(values.file_name),
                from_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[0]).format("YYYY-MM-DD") : null,
                to_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[1]).format("YYYY-MM-DD") : null,
                status: JSON.stringify(values.status)
            }
        )
        setFieldFilter({
            code_lookup: trimField(values.code_lookup),
            from_submit_date: values.submit_date !== undefined && values.submit_date !== null ? dayjs(values.submit_date[0]).format("YYYY-MM-DD") : null,
            to_submit_date: values.submit_date !== undefined && values.submit_date !== null ? dayjs(values.submit_date[1]).format("YYYY-MM-DD") : null,
            file_name: trimField(values.file_name),
            from_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[0]).format("YYYY-MM-DD") : null,
            to_create_date: values.create_date !== undefined && values.create_date !== null ? dayjs(values.create_date[1]).format("YYYY-MM-DD") : null,
            status: JSON.stringify(values.status)
        })
        setIsOpenModalFilter(false)

    }

    const resetData = () => {
        form.resetFields();
        fetchDataStatement({
            page: 1,
            page_size: pager.pageSize,
            id_customer: userInfo.customer_id,
        })

        setFieldFilter({
            code_lookup: null,
            from_submit_date: null,
            to_submit_date: null,
            file_name: null,
            from_create_date: null,
            to_create_date: null,
            status: null
        })
    }
    return (

        <Modal
            open={open}
            // onOk={handleOk}
            onCancel={handleCancel}
            width={'85%'}
            style={{ height: '60vh', top: 50 }}
            // closable={false}
            className="modal-detail"

            footer={false}
        // centered
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
                initialValues={{
                    remember: true,
                }}
                className='text-label-manage-docs'
                style={{ paddingTop: "8%" }}
            >
                <Row>
                    <Col span={24}>
                        <Form.Item label="Ngày hoàn thành" name={"submit_date"}>
                            <RangePicker
                                disabledDate={disabledDateAccounting}
                                onCalendarChange={(val) => {
                                    setDatesAccounting(val);
                                }}
                                inputReadOnly={true}

                                locale={{
                                    lang: {
                                        locale: "en_US",
                                        rangePlaceholder: ["Ngày bắt đầu", "Ngày kết thúc"],
                                        yearFormat: "YYYY",
                                        shortMonths: [
                                            "Tháng 1",
                                            "Tháng 2",
                                            "Tháng 3",
                                            "Tháng 4",
                                            "Tháng 5",
                                            "Tháng 6",
                                            "Tháng 7",
                                            "Tháng 8",
                                            "Tháng 9",
                                            "Tháng 10",
                                            "Tháng 11",
                                            "Tháng 12"
                                        ],
                                        shortWeekDays: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
                                        ok: "OK",
                                        monthBeforeYear: true,
                                    }
                                }}
                                style={{ width: "100%" }}
                                format={"DD-MM-YYYY"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Ngày tải lên" name={"create_date"}>
                            <RangePicker
                                disabledDate={disabledDateUpload}
                                onCalendarChange={(val) => {
                                    setDatesUpload(val);
                                }}
                                inputReadOnly={true}
                                locale={{
                                    lang: {
                                        locale: "en_US",
                                        rangePlaceholder: ["Ngày bắt đầu", "Ngày kết thúc"],
                                        yearFormat: "YYYY",
                                        shortMonths: [
                                            "Tháng 1",
                                            "Tháng 2",
                                            "Tháng 3",
                                            "Tháng 4",
                                            "Tháng 5",
                                            "Tháng 6",
                                            "Tháng 7",
                                            "Tháng 8",
                                            "Tháng 9",
                                            "Tháng 10",
                                            "Tháng 11",
                                            "Tháng 12"
                                        ],
                                        shortWeekDays: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
                                        ok: "OK",
                                        monthBeforeYear: true,
                                    }
                                }}
                                style={{ width: "100%" }}
                                format={"DD-MM-YYYY"}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Trạng thái" name={"status"}>
                            <Select
                                id="code_district"
                                size={"middle"}
                                allowClear
                                mode='multiple'
                                className="SelectTTDN"
                                placeholder={"Chọn trạng thái"}
                                maxTagCount={"responsive"}
                                showSearch={false}
                                optionFilterProp="children"
                            >

                                {status.map((item, index) => (
                                    <Option key={index} value={item.id}>
                                        {item.name}
                                    </Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Mã chứng từ" name={"code_lookup"}>
                            <Input onBlur={(e) => form.setFieldValue("code_lookup", e.target.value.trim())} placeholder='Nhập mã tra cứu'></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Tên sao kê" name={"file_name"}>
                            <Input onBlur={(e) => form.setFieldValue("file_name", e.target.value.trim())} placeholder='Nhập Tên sao kê'></Input>
                        </Form.Item>
                    </Col>


                </Row>
                <Col style={{ paddingTop: "3%" }}>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button

                            htmlType="button"
                            className='text-btn design-btn'
                            style={{ height: 45, width: "50%" }}
                            onClick={resetData}
                        >
                            Đặt lại
                        </Button>
                        <Button
                            htmlType="submit"
                            className='text-btn design-btn-submit'
                            style={{ height: 45, width: "50%" }}
                        >
                            Tìm kiếm
                        </Button>
                    </div>
                </Col>
            </Form>
        </Modal>

    )
}

export default StatementMB