import { Button, Checkbox, Col, Collapse, Empty, Modal, Row, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { TransformWrapper, TransformComponent, useControls } from 'react-zoom-pan-pinch';
import { localhost } from '../../../../../server';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import BoxIcon from "../../../../../images/image_manage_docs/BoxInforImage.svg"
import { GetDetailInvoiceApi } from '../../../../../api/InvoiceApi';
import dayjs from 'dayjs';
import IconViewDetail from "../../../../../images_mobile/ViewImage.svg"
import CollapseEditScreenMB from './CollapseEditScreenMB';
import EditIcon from "./../../../../../images/EditIcon.svg"

const { createCanvas } = require('canvas');

const pdfjsLib = window.pdfjsLib;
pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js';

function ModalViewDetailMB({ isOpenModalDetail, setIsOpenModalDetail, idUser, idInvoice }) {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [numPagesPDF, setNumPagesPDF] = React.useState();
    const [urlPDF, setUrlPDF] = React.useState();
    const [imagesURL, setImagesURL] = React.useState([]);
    const [dataDetail, setDataDetail] = useState()
    const [loadingImg, setLoadingImg] = React.useState(false);
    const [imageDiffPDF, setImageDiffPDF] = useState()
    const [isOpenModalImage, setIsOpenModalImage] = useState(false)
    const [openFormEdit, setOpenFormEdit] = useState(false)

    const fetchDataDetail = () => {
        GetDetailInvoiceApi({
            id_customer: idUser,
            id_invoice: idInvoice
        }).then(res => {
            setDataDetail(res.data)
            const imgDiffPDF = res.data.file_invoice[0].name.split(".")
            if (imgDiffPDF[1].toLowerCase() !== "pdf") {
                setLoadingImg(false);
                setImageDiffPDF(res.data.file_invoice[0].path)
            } else {
                setUrlPDF(res.data.file_invoice[0].path)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const handleNextPage = () => {
        if (currentPage < numPagesPDF) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    function dataURLtoFile(dataURL, fileName) {
        var arr = dataURL.split(',');
        var mime = arr[0].match(/:(.*?);/)[1];
        var bstr = atob(arr[1]);
        var n = bstr.length;
        var u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], fileName, { type: mime });
    }

    useEffect(() => {
        if (openFormEdit === false) {
            fetchDataDetail()
        }
    }, [openFormEdit]);


    useEffect(() => {
        setLoadingImg(true);
        if (urlPDF !== undefined) {
            const convertToImage = async () => {
                try {
                    // Tạo đối tượng PDF từ dữ liệu PDF
                    const pdf = await pdfjsLib.getDocument(localhost + '/' + urlPDF).promise;

                    // Lấy số trang của PDF
                    const numPages = pdf.numPages;

                    // Mảng chứa hình ảnh
                    const images = [];
                    const pageImagesURL = [];
                    // Duyệt qua từng trang PDF
                    for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
                        // Lấy trang PDF
                        const page = await pdf.getPage(pageNumber);

                        // Lấy kích thước của trang
                        const viewport = page.getViewport({ scale: 2 });

                        // Tạo canvas để vẽ trang PDF
                        const canvas = createCanvas(viewport.width, viewport.height);
                        const context = canvas.getContext('2d');
                        // Render trang PDF lên canvas
                        await page.render({
                            canvasContext: context,
                            viewport: viewport
                        }).promise;

                        // Convert canvas thành hình ảnh dưới dạng URL
                        const imageDataURL = canvas.toDataURL('image/png');
                        const convert_base64tofileimg = dataURLtoFile(imageDataURL, `page_${pageNumber}__.png`);
                        // Thêm hình ảnh vào mảng
                        images.push(convert_base64tofileimg);
                        pageImagesURL.push(URL.createObjectURL(convert_base64tofileimg));
                    }
                    setImagesURL(pageImagesURL);
                    setLoadingImg(false);
                    // Trả về mảng hình ảnh
                    setNumPagesPDF(numPages);
                    return images;
                } catch (error) {
                    setLoadingImg(false);
                    return [];
                }
            };
            convertToImage();
        }

    }, [urlPDF]);

    const handleCancel = () => {
        setIsOpenModalDetail(false)
    }

    const genExtra = () => {
        let count = 0;
        if (dataDetail !== undefined && parseInt(dataDetail.type_upload) !== 2) {
            dataDetail.data_criteria.map(item => {
                if (dataDetail[item.name_abbreviation] === true) {
                    count++
                }
            })
            return <span className={count === dataDetail.data_criteria.length ? 'text-box-criteria-success' : 'text-box-criteria-error'}>{count}/{dataDetail.data_criteria.length}</span>
        }
    };

    const labelCollapse = (text) => {
        return (
            <>
                <span style={{ columnGap: 8 }}><img alt='' src={BoxIcon}></img>{text}</span>
            </>
        )
    }

    const typeDocument = () => {
        if (dataDetail !== undefined && dataDetail !== null) {
            if (dataDetail.type_document === "HDMV") {
                return "(Mua vào)"
            } else if (dataDetail.type_document === "HDBR") {
                return "(Bán ra)"
            }
        } else {
            return null
        }
    }

    const items = [
        {
            key: '1',
            label: labelCollapse(`Thông tin hóa đơn ${typeDocument()}`),
            children: <BoxInforInvoice dataDetail={dataDetail} />,
        },
        {
            key: '2',
            label: labelCollapse("Thông tin nhà cung cấp"),
            children: <BoxInforSupplier dataDetail={dataDetail} />,
        },
        {
            key: '3',
            label: labelCollapse("Thông tin khách hàng"),
            children: <BoxInforCustomer dataDetail={dataDetail} />,
        },
        {
            key: '4',
            label: labelCollapse("Kết quả kiểm tra"),
            children: <ResultCheck dataDetail={dataDetail} />,
            extra: genExtra(),
        },
        {
            key: '5',
            label: labelCollapse("Chi tiết sản phẩm"),
            children: <DetailProduct dataDetail={dataDetail} />,
        },
    ];

    const Controls = () => {
        const { resetTransform } = useControls();
        return (
            <>
                <Row
                    style={{
                        alignItems: "center",
                        display: "flex",
                        paddingBottom: "0.5%"
                    }}
                >
                    <Col span={5} ><span className='text-pagi'>{currentPage}/{numPagesPDF}</span></Col>

                    <Col span={14} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {numPagesPDF && (
                            <span style={{ float: "unset" }}>
                                {imagesURL.length > 0 ? (
                                    <>
                                        <Button
                                            // type="primary"
                                            shape="round"
                                            size="small"
                                            // icon={<DoubleLeftOutlined style={{ color: "#516583" }} />}
                                            icon={<IConPrePage />}
                                            onClick={() => { handlePreviousPage(); resetTransform() }}
                                            disabled={currentPage === 1}
                                            className='btn-pagination'
                                        >
                                            {/* <img src={ArrowPre} alt=''></img> */}
                                        </Button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button
                                            // type="primary"
                                            shape="round"
                                            size="small"
                                            icon={<IConNextPage />}
                                            // icon={<DoubleRightOutlined style={{ color: "#516583" }} />}

                                            onClick={() => { handleNextPage(); resetTransform() }}
                                            disabled={currentPage === numPagesPDF}
                                            className='btn-pagination'
                                        >
                                            {/* <img src={ArrowNext} alt=''></img> */}
                                        </Button>
                                    </>

                                ) : null}
                            </span>
                        )}</Col>
                </Row>
            </>
        );
    };

    const viewImage = () => {
        setIsOpenModalImage(true)
    }

    const showFormEdit = () => {
        setOpenFormEdit(true)
    }

    const antIcon = <Loading3QuartersOutlined style={{ fontSize: 50 }} spin />;
    return (
        <>
            <Modal
                open={isOpenModalDetail}
                // onOk={handleOk}
                onCancel={handleCancel}
                width={'96%'}
                style={{ height: '60vh', top: 10 }}
                // closable={false}
                className="modal-detail"
                footer={false}
            // centered
            >
                <Row style={{ paddingTop: "2%" }}>
                    <Col span={24} style={{ display: "flex" }}>
                        <Button onClick={viewImage} className='btn-invoice-all'><img alt='' src={IconViewDetail}></img></Button>
                        {dataDetail !== undefined && (dataDetail.status === "3" || dataDetail.status === "4") ?
                            <Button onClick={showFormEdit} className='btn-invoice-all' style={{ background: "rgba(5, 141, 244, 0.18)", border: "unset", height: 30, padding: 4, marginLeft: 10 }}>
                                <img alt='' src={EditIcon}></img>
                            </Button>
                            :
                            <>
                                &nbsp;
                            </>
                        }
                    </Col>
                    <Col span={24} style={{ paddingTop: "2%" }}>
                        {openFormEdit === false && dataDetail !== undefined ?
                            <>
                                <Collapse className='collapse-mobile' expandIconPosition="end" size='small' items={items} defaultActiveKey={['1']} />
                            </>
                            : <CollapseEditScreenMB
                                dataDetail={dataDetail}
                                setOpenFormEdit={setOpenFormEdit}
                                openFormEdit={openFormEdit}
                            />}
                    </Col>
                </Row>
            </Modal>

            {isOpenModalImage === true ?
                <ModalViewImage
                    open={isOpenModalImage}
                    setIsOpenModalImage={setIsOpenModalImage}
                    ImageDetail={Controls}
                    loadingImg={loadingImg}
                    antIcon={antIcon}
                    dataDetail={dataDetail}
                    imageDiffPDF={imageDiffPDF}
                    imagesURL={imagesURL}
                    currentPage={currentPage}
                />
                : null}
        </>
    )
}

const BoxInforInvoice = ({ dataDetail }) => {
    const formatDate = (text) => {
        if (text !== undefined && text !== null) {
            return dayjs(text).format("DD-MM-YYYY")
        } else {
            return null;
        }
    }
    return (
        <>
            {dataDetail &&
                <Row className='box-infor-invoice' style={{ rowGap: 8 }}>
                    <Col span={24}>
                        <Row>
                            <Col span={10}>Mẫu số:</Col>
                            <Col span={14}>{dataDetail.form}</Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            {/* invoice_signed_date DATE NULL, --Ngày ký HDDT */}
                            <Col span={10}>Ngày ký:</Col>
                            <Col span={14}>{formatDate(dataDetail.invoice_signed_date)}</Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={10}>Ký hiệu:</Col>
                            <Col span={14}>{dataDetail.symbol}</Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            {/* invoice_date DATE NULL, --Ngay_HD */}
                            <Col span={10}>Ngày phát hành:</Col>
                            <Col span={14}>{formatDate(dataDetail.invoice_date)}</Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={10}>Số hóa đơn:</Col>
                            <Col span={14}>{dataDetail.invoice_number}</Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>

                            {/* created_date */}
                            <Col span={10}>Ngày tải lên:</Col>
                            <Col span={14}>{formatDate(dataDetail.created_date)}</Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={10}>Tổ chức sở hữu hóa đơn:</Col>
                            <Col span={14}>{dataDetail.trader_organization.name}</Col>
                        </Row>

                    </Col>
                </Row>
            }
        </>
    )
}
const BoxInforSupplier = ({ dataDetail }) => {
    return (
        <>

            {dataDetail &&
                <Row className='box-infor-supplier' style={{ rowGap: 8 }}>
                    <Col span={24}>
                        <Row>
                            <Col span={8}>Tên:</Col>
                            <Col span={16}>{dataDetail.invoice_seller.name_seller}</Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={8}>Mã số thuế:</Col>
                            <Col span={16}>{dataDetail.invoice_seller.tax_code_seller}</Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={8}>Địa chỉ:</Col>
                            <Col span={16}>{dataDetail.invoice_seller.address_seller}</Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={8}>Số điện thoại:</Col>
                            <Col span={16}>{dataDetail.invoice_seller.phone_seller}</Col>
                        </Row>
                    </Col>
                </Row>
            }
        </>
    )
}

const BoxInforCustomer = ({ dataDetail }) => {
    return (
        <>
            {dataDetail &&
                <Row className='box-infor-supplier' style={{ rowGap: 8 }}>
                    <Col span={24}>
                        <Row>
                            <Col span={8}>Tên:</Col>
                            <Col span={16}>{dataDetail.invoice_buyer.name_buyer}</Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={8}>Mã số thuế:</Col>
                            <Col span={16}>{dataDetail.invoice_buyer.tax_code_buyer}</Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={8}>Địa chỉ:</Col>
                            <Col span={16}>{dataDetail.invoice_buyer.address_buyer}</Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row>
                            <Col span={8}>Số điện thoại:</Col>
                            <Col span={16}>{dataDetail.invoice_buyer.phone_buyer}</Col>
                        </Row>
                    </Col>
                </Row>
            }
        </>
    )
}

const ResultCheck = ({ dataDetail }) => {
    return (
        <>
            {dataDetail !== undefined && parseInt(dataDetail.type_upload) !== 2 ?
                <Row className='box-result-criteria'>

                    {dataDetail.data_criteria.map(item => {
                        return (
                            <>
                                <Col span={24}><Checkbox checked={dataDetail[item.name_abbreviation]}></Checkbox> <span style={{ color: dataDetail[item.name_abbreviation] !== true ? "rgb(229, 83, 83)" : "black" }}>{item.name_display}</span></Col>
                            </>)
                    })}
                </Row>
                : null
            }
        </>
    )
}

const DetailProduct = ({ dataDetail }) => {
    const ConvertLocaleString = (text) => {
        if (text !== undefined && text !== null) {
            return text.toLocaleString()
        } else {
            return text
        }

    }

    const columns = [
        {
            title: 'STT',
            dataIndex: 'key',
            key: 'key',
            width: 50,
            align: "center",
            render: (text, record, index) =>
                text + 1,
        },
        {
            title: 'Mặt hàng',
            dataIndex: 'item_name',
            key: 'item_name',
            width: 500,
            align: "center",

        },
        {
            title: 'Đơn vị',
            dataIndex: 'unit_name',
            key: 'unit_name',
            width: 120,
            align: "center",
        },
        {
            title: 'Số lượng',
            dataIndex: 'quantity',
            key: 'quantity',
            width: 120,
            align: "center",
            render: (text, record) => ConvertLocaleString(text)
        },
        {
            title: 'Đơn giá',
            dataIndex: 'unit_price',
            key: 'unit_price',
            width: 120,
            align: "center",
            render: (text, record) => ConvertLocaleString(text)
        },
        {
            title: 'Tiền hàng',
            dataIndex: 'amount_without_vat',
            key: 'amount_without_vat',
            width: 120,
            align: "center",
            render: (text, record) => ConvertLocaleString(text)
        },
        {
            title: 'Tiền chiết khấu',
            dataIndex: 'discount_amount',
            key: 'discount_amount',
            align: "center",
            width: 200,
            render: (text, record) => ConvertLocaleString(text)
        },
        {
            title: 'Thuế suất',
            dataIndex: 'vat_percentage',
            key: 'vat_percentage',
            width: 120,
            align: "center",
            render: (text, record) => text + "%"
        },
        {
            title: 'Tiền thuế',
            dataIndex: 'amount_vat',
            key: 'amount_vat',
            width: 120,
            align: "center",
            render: (text, record) => ConvertLocaleString(text)
        },
    ];

    const showFooter = () => {
        return (
            <>
                <Row className='box-detail-product'>
                    <Col span={14}>Tổng tiền hàng:</Col>
                    <Col span={10}>{ConvertLocaleString(dataDetail.total_money_without_vat)}</Col>
                    <Col span={14}>Tổng tiền chiết khấu:</Col>
                    <Col span={10}>{ConvertLocaleString(dataDetail.total_discount_amount)}</Col>
                    <Col span={14}>Tổng thuế GTGT:</Col>
                    <Col span={10}>{ConvertLocaleString(dataDetail.total_vat_money)}</Col>
                    <Col span={14}>Tổng tiền thanh toán:</Col>
                    <Col span={10}>{ConvertLocaleString(dataDetail.total_vat_amount)}</Col>
                </Row>
            </>
        )
    }

    return (
        <>
            {dataDetail &&
                <Table
                    scroll={{
                        x: 1000
                    }}
                    size='small'
                    dataSource={dataDetail.list_products}
                    columns={columns}
                    pagination={false}
                    footer={() => showFooter()}
                    className='table-detail-product'
                />
            }
        </>
    )
}

const IConPrePage = () => {
    return (
        <>
            <span className='icon-next'>
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.25 7L6.25 4L9.25 1" stroke="#061C3D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M4 7L1 4L4 1" stroke="#061C3D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </span>
        </>
    )
}
const IConNextPage = () => {
    return (
        <>
            <span className='icon-next'>
                {/* chỉ sử được sử dụng thẻ svg */}
                <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 7L4 4L1 1" stroke="#061C3D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M6.25 7L9.25 4L6.25 1" stroke="#061C3D" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </span>
        </>
    )
}

const ModalViewImage = ({
    open,
    setIsOpenModalImage,
    ImageDetail,
    loadingImg,
    antIcon,
    dataDetail,
    imageDiffPDF,
    imagesURL,
    currentPage
}) => {
    const handleCancel = () => {
        setIsOpenModalImage(false)
    }
    return (
        <>
            <Modal
                open={open}
                // onOk={handleOk}
                onCancel={handleCancel}
                width={'100%'}
                style={{ height: '60vh', top: 10 }}
                // closable={false}
                className="modal-detail"

                footer={false}
            // centered
            >
                <Row>
                    <Col
                        span={24}
                        style={{
                            height: '100%',
                            width: '100%',
                            paddingTop: "3%"
                            // justifyContent: 'center',
                            // alignItems: 'center'
                        }}
                    >
                        {loadingImg === true ?
                            <>
                                <div className="loading-detail">
                                    <Spin indicator={antIcon} spinning={loadingImg} size="large"></Spin>
                                    {/* <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description = "Ảnh trống"/> */}
                                </div>
                            </>
                            :
                            <div style={{ position: "relative" }}>
                                {/* dataDetail.type_upload === 2 là up bằng excel */}

                                {dataDetail !== undefined && parseInt(dataDetail.type_upload) !== 2 ?
                                    <>
                                        <TransformWrapper>
                                            {({ zoomIn, zoomOut, resetTransform }) => (
                                                <>
                                                    <ImageDetail />
                                                    <TransformComponent contentStyle={{ cursor: 'zoom-in', width: "100%", display: "flow", padding: "2% 1% 2%" }}>
                                                        {/* Your content to be zoomed goes here */}
                                                        <img
                                                            src={imageDiffPDF !== undefined ? localhost + "/" + imageDiffPDF : imagesURL[currentPage - 1]}
                                                            // src={dataDetail !== undefined ? `${localhost + "/" + dataDetail.file_invoice[0].path}` : null}
                                                            style={{ width: "100%", height: "75vh", filter: "drop-shadow(2px 4px 6px black)", imageRendering: "unset" }}
                                                            alt="Hình ảnh"
                                                        />
                                                    </TransformComponent>
                                                </>
                                            )}
                                        </TransformWrapper>
                                    </>
                                    :
                                    <div>
                                        &nbsp;
                                        <div className='none-image'>
                                            <Empty />
                                        </div>
                                    </div>}
                            </div>
                        }
                    </Col>
                </Row>
            </Modal>
        </>
    )
}

export default ModalViewDetailMB